import React, { useRef } from "react";
import { Button, Space, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import i18n from '../../Shared/Translation/i18n';

export function GetColumnSearchProps({dataIndex, placeholder, onFilterData}) {
    const searchInput = useRef(null);
    const searchButton = useRef(null);

    const handleSearch = confirm => {
        confirm();
    };
    
    const handleReset = clearFilters => {
        clearFilters();
      };

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={i18n(placeholder)}
                    value={selectedKeys[0]}
                    onChange={e => { // NOSONAR
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }}
                    onPressEnter={() => handleSearch(confirm)} // NOSONAR
                    style={{ width: 188, marginBottom: 8, display: 'block' }} />
                <Space>
                <Button
                    type="primary"
                    ref={searchButton}
                    onClick={() => handleSearch(confirm)} // NOSONAR
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}>

                        {i18n('text.search')}
                </Button>
                <Button 
                        onClick={() => handleReset(clearFilters)} // NOSONAR
                        size="small" style={{ width: 90 }}>
                        {i18n('text.reset')}
                </Button>
                </Space>
            </div>
        ),

        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#fa5b0b' : undefined }} />,
        onFilter: (value, record) => {
            return onFilterData(value, record, dataIndex);
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible && searchInput) {
                setTimeout(() => {
                    searchInput.current.select()
                }, 100);
            }
        },
    };
}
