import React, { useCallback } from 'react';
import i18n from '../../Shared/Translation/i18n';
import InputCont from '../../Shared/Form/InputCont';
import useDetail from '../../Shared/useDetail';
import { ApiUrl } from '../../project/Defines.js';
import { EVENT_NAME } from '../../project/utilities';


export default function Systems(props) {
    const reloadEvent = EVENT_NAME.SALES_NETWORK_APPLICATION_LIST_UPDATE;

    const { dataItem, setDataItem, updateData, /*closePopup, detailRef, onChangeData,*/ validation } = useDetail({
        data: {},
        onLoadData: (response, _setDataItem) => {
            const data = [];
            for (const element of response.data) {
                if (!data.filter((item) => item.productionModeId === element.productionModeId).length) {
                    data.push({
                        productionModeId: element.productionModeId,
                        modeLabel: element.modeLabel,
                        isModeSelected: element.isModeSelected,
                        systems: response.data.filter((j) => j.productionModeId === element.productionModeId)
                    });
                }
            }
            _setDataItem(data);
            return true;
        },
        updateUrl: ApiUrl.SalesNetworkSystemsUpdate,
        dataLoadUrl: ApiUrl.SalesNetworkSystemsLoad,
        reloadEvent
    });

    const updateSuccessCallback = useCallback((response) => {
        return true;
    }, [/*setDataItem, closePopup, detailRef, reloadEvent*/]);

    const dataChange = (e) => {
        const newData = [...dataItem];

        for (const element of newData) {
            if (element.modeLabel === e.target.name) {
                element.isModeSelected = e.target.checked;

                if (!e.target.checked) {
                    for (let system of element.systems)
                        system.isSystemSelected = false;
                } else {
                    for (let system of element.systems) {
                        system.isSystemSelected = true;
                    }
                }
                break;
            }

        }
        updateData(updateSuccessCallback, null, { overrideData: { id: +e.target.value, isChecked: e.target.checked, changeAll: true } });

        setDataItem(newData);
    }
    const subDataChange = (e, parentID) => {// NOSONAR
        const newData = [...dataItem];

        for (const element of newData) {
            if (element.productionModeId === parentID) {

                if (!element.isModeSelected && e.target.checked) {
                    element.isModeSelected = true;
                    updateData(updateSuccessCallback, null, { overrideData: { id: element.productionModeId, isChecked: e.target.checked, changeAll: false } });
                }

                if (!e.target.checked && element.systems.filter(subItem => subItem.isSystemSelected).length === 1) {
                    element.isModeSelected = false;
                    updateData(updateSuccessCallback, null, { overrideData: { id: element.productionModeId, isChecked: e.target.checked, changeAll: false } });
                    for (let system of element.systems) {
                        if (system.systemLabel === e.target.name) {
                            system.isSystemSelected = e.target.checked;
                        }
                    }
                    setDataItem(newData);
                    return;
                }

                for (let system of element.systems) {
                    if (system.systemLabel === e.target.name) {
                        system.isSystemSelected = e.target.checked;
                    }
                }
                break;
            }
        }

        updateData(updateSuccessCallback, null, { overrideData: { id: +e.target.value, isSystem: true, isChecked: e.target.checked } });

        setDataItem(newData);
    };

    return <div className="section_group">
        <div className="sections extra">
            <div className="form_fields"><div className="section_title"><text>{i18n("text.systems")}</text></div><div className="form_separator"></div></div>
            {
                dataItem && dataItem.map(item => (<div className="form_fields" key={item.modeLabel}>
                    <cont>
                        <div style={{'minWidth': '300px'}}><InputCont label={item.modeLabel} inputProps={{ validation, name: item.modeLabel, type: 'checkbox', value: item.productionModeId, onChange: dataChange, checked: item.isModeSelected }} /></div>
                        {item.systems && item.systems.map(subItem => (<InputCont key={subItem.systemLabel} label={subItem.systemLabel} inputProps={{ validation, name: subItem.systemLabel, type: 'checkbox', value: subItem.systemId, onChange: (e) => { subDataChange(e, item.productionModeId) }, checked: subItem.isSystemSelected}} />))}
                    </cont>
                </div>))
            }
        </div>
    </div>
}