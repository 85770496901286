import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';

import { Ajax } from '../../components/Ajax';
import { ApiUrl } from '../../project/Defines.js';
import { useCustomEvent } from '../../project/utilities';
import { /*ValidationInput,*/ validate } from '../Form/validation';

import { showLoader, hideLoader } from '~/Shared/Loader';
import InputCont from '../Form/InputCont';

const loaderName = 'TRANSLATION_LOADER';

/**
 *  represents the translation tab content for each detail page
 * the component used to add translations for each item
 * @param props contains:ID, referenceTable, fields, showField, updateEvent, updateCallback
 * @exports  Translation
 */
function Translation(props) {// NOSONAR
    const { role /*, network*/ } = props;
    const { ID, networkId, referenceTable, fields, showField, /*translations, setTranslations, test,*/ updateEvent, updateCallback, refreshEvent, validation, setValidation, validationProps, onTranslationChanged } = props;
    const [isLoading, setIsLoading] = useState(false);
    const transRef = useRef(null);
    const [translations, setTranslations] = useState([]);
    const [validationFields, setValidationFields] = useState([]);
    const [cultures, setCultures] = useState([]);
    const salesnetworkId = networkId || null;

    function getLanguageText(culture) {
        let lang = cultures.find(c => c.value === culture);
        return (lang && (lang.label || lang.code)) || culture;
    }

    useEffect(() => {
        validationProps && setValidationFields(validationProps.map(vp => vp.rules ? ({ name: vp.languageId + (vp.field || fields[0]), rules: vp.rules }) : (vp.languageId + (vp.field || fields[0]))));
    }, [validationProps, fields]);

    const loading = <div style={{ "margin": "15% auto" }}><Spin size="large" /></div>;

    const onLoadData = useCallback((data) => {
        const newData = [];
        
        for (const element of fields) {
            for (let _culture of cultures) {
                let index = data.findIndex(p => p.languageId === _culture.value && p.field === element);
                if (index >= 0) {
                    newData.push(data[index]);
                } else {
                    newData.push({
                        referenceTable, languageId: _culture.value, value: "", referenceId: ID, field: element, salesnetworkId
                    });
                }
            }
        }
        setTranslations(newData);
        setIsLoading(false);
        onTranslationChanged && onTranslationChanged(false);
    }, [ID, fields, referenceTable, cultures, salesnetworkId, onTranslationChanged]);

    const updateData = useCallback((e) => {
        if (!validation || validation.isValid) {
            if (e.detail.ID) {
                if (!isLoading) {
                    setIsLoading(true)
                }


                Ajax.post({
                    url: ApiUrl.UpdateTranslations,
                    data: {
                        tdm: translations,
                        referenceId: e.detail.ID,
                        referenceTable,
                        salesnetworkId: salesnetworkId
                    },
                    success: function (response) {
                        if (!response.HasError && transRef.current) {
                            onLoadData(response.Data);
                            updateCallback && updateCallback(response.Data);
                        }
                        if (transRef.current)
                            setIsLoading(false);
                    },
                    error: function () {
                        if (transRef.current)
                            setIsLoading(false);
                    }
                })
            }
        }
    }, [salesnetworkId, translations, onLoadData, isLoading, validation, updateCallback, referenceTable]);
    useCustomEvent(updateEvent, updateData);

    const loadData = useCallback(() => {
        if (!isLoading) {
            showLoader(loaderName);
        }

        if (ID) {
            Ajax.post({
                url: ApiUrl.TranslationList,
                data: {
                    filterData: JSON.stringify({ referenceId: ID, referenceTable, salesnetworkId })
                },
                success: function (response) {
                    if (response && transRef.current) {
                        onLoadData(response.Data);
                    }
                    hideLoader(loaderName);
                },
                error: function () {
                    hideLoader(loaderName);
                }
            })
        } else {
            onLoadData([]);
            hideLoader(loaderName);
        }
    }, [ID, referenceTable, salesnetworkId, onLoadData, isLoading]);

    useCustomEvent(refreshEvent, loadData);

    useEffect(() => {
        Ajax.get({
            url: role === 'superadmin' ? ApiUrl.GetLanguages : ApiUrl.SalesNetworkLanguagesLoad,
            data: {},
            success: function (response) {
                if (role === 'superadmin'){
                    setCultures(response)
                } else {
                    let langs = [];
                    response.data.map((item)=>{
                        if (item.isSelected){
                            langs.push({
                                value: item.ID,
                                code: item.Code,
                                label: item.label
                            }) 
                        }
                        return item;
                    })
                    setCultures(langs);
                }
                
            }
        })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        ID && cultures && cultures.length && loadData();
    }, [ID, cultures]);// eslint-disable-line react-hooks/exhaustive-deps

    const onChangeTranslation = useCallback((event) => {
        let newTranslations = [...translations];
        let index = newTranslations.findIndex(p => p.languageId + p.field === event.target.name);
        if (index >= 0) {
            onTranslationChanged && onTranslationChanged(newTranslations[index].value !== event.target.value);
            newTranslations[index].value = event.target.value;
        }
        setTranslations(newTranslations);
        if (setValidation && validationFields.length) {
            let validatingData = {};
            newTranslations.forEach(t => { validatingData[t.languageId + t.field] = t.value })
            setValidation(validate({ data: validatingData, validationFields }));
        }

        
    }, [translations, validationFields, setValidation, onTranslationChanged]);

    return cultures && cultures.length ? (
        <div className="form_container" ref={transRef} >
            <div className="section_group">
                {(isLoading && loading) || fields.map((field, i) => {
                    return <div className="sections"
                        key={field + i} // NOSONAR
                    >
                        {showField && <div className="form_fields"><div className="section_title"><text>{field}</text></div><div className="form_separator"></div></div>}
                        {
                            translations.filter(t => t.field === field).map((trans, i) => {
                                return <InputCont
                                    key={field + trans.languageId + i} // NOSONAR
                                    label={getLanguageText(trans.languageId)} inputProps={{ validation: validation || { isValid: true }, name: trans.languageId + trans.field, maxLength: 150, type: 'text', value: trans.value, onChange: onChangeTranslation, autoComplete: 'off', placeholder: trans.defaultValue }} />
                            }
                            )
                        }
                    </div>
                })}

            </div>
        </div>
    ) : <></>;
}

export default connect(state => ({
    role: state.role,
    network: state.network
}))(Translation);
