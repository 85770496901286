import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EVENT_NAME, dispatchCustomEvent } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { Project } from '../../project/Project';

const listUpdateEvent = EVENT_NAME.PRODUCT_LIST_UPDATE;

export default function ImportDetail(props) {
    const { fileData } = props;
    const { type } = useParams();

    const columns = [
        {
            text: 'SalesNetwork',
            field: 'salesNetwork',
            type: COLUMN_TYPE.TEXT,
            width: 120,
            fixed: 'left'
        },
        {
            text: 'Code',
            field: 'code',
            type: COLUMN_TYPE.TEXT,
            width: 100,
            fixed: 'left'
        },
        {
            text: 'brand',
            field: 'brand',
            type: COLUMN_TYPE.TEXT,
            width: 120,
            fixed: 'left'
        },
        {
            text: 'language',
            field: 'language',
            type: COLUMN_TYPE.TEXT,
            width: 80,
            fixed: 'left'
        },
        {
            text: 'text.url_doc1',
            field: 'urlDoc1',
            type: COLUMN_TYPE.TEXT,
            width: 550,
        },
        {
            text: 'text.type_doc1',
            field: 'type1',
            type: COLUMN_TYPE.TEXT,
            width: 80
        },
        {
            text: 'text.name_doc1',
            field: 'nameDoc1',
            type: COLUMN_TYPE.TEXT,
            width: 150,
        },
        {
            text: 'text.url_doc2',
            field: 'urlDoc2',
            type: COLUMN_TYPE.TEXT,
            width: 550,
        },
        {
            text: 'text.type_doc2',
            field: 'type2',
            type: COLUMN_TYPE.TEXT,
            width: 80
        },
        {
            text: 'text.name_doc2',
            field: 'nameDoc2',
            type: COLUMN_TYPE.TEXT,
            width: 150,
        },
        {
            text: 'text.url_doc3',
            field: 'urlDoc3',
            type: COLUMN_TYPE.TEXT,
            width: 550,
        },
        {
            text: 'text.type_doc3',
            field: 'type3',
            type: COLUMN_TYPE.TEXT,
            width: 80
        },
        {
            text: 'text.name_doc3',
            field: 'nameDoc3',
            type: COLUMN_TYPE.TEXT,
            width: 150,
        },
        {
            text: 'text.url_doc4',
            field: 'urlDoc4',
            type: COLUMN_TYPE.TEXT,
            width: 550,
        },
        {
            text: 'text.type_doc4',
            field: 'type4',
            type: COLUMN_TYPE.TEXT,
            width: 80
        },
        {
            text: 'text.name_doc4',
            field: 'nameDoc4',
            type: COLUMN_TYPE.TEXT,
            width: 150,
        },
        {
            text: 'text.url_doc5',
            field: 'urlDoc5',
            type: COLUMN_TYPE.TEXT,
            width: 550,
        },
        {
            text: 'text.type_doc5',
            field: 'type5',
            type: COLUMN_TYPE.TEXT,
            width: 80
        },
        {
            text: 'text.name_doc5',
            field: 'nameDoc5',
            type: COLUMN_TYPE.TEXT,
            width: 150,
        },
        {
            text: 'text.url_doc6',
            field: 'urlDoc6',
            type: COLUMN_TYPE.TEXT,
            width: 550,
        },
        {
            text: 'text.type_doc6',
            field: 'type6',
            type: COLUMN_TYPE.TEXT,
            width: 80
        },
        {
            text: 'text.name_doc6',
            field: 'nameDoc6',
            type: COLUMN_TYPE.TEXT,
            width: 150,
        },
        Project.getImportActionColumn(COLUMN_TYPE.TEXT, "right"),
        Project.getImportMessageColumn(COLUMN_TYPE.TEXT, "right")
        // {
        //     text: 'action',
        //     field: 'Action',
        //     render: (text, record) => {
        //         return (
        //             <wrap>
        //                 <group compact="">
        //                     {Project.getColorAction(text)}
        //                 </group>
        //             </wrap>
        //         );
        //     },
        //     type: COLUMN_TYPE.TEXT,
        //     width: 100
        //     // fixed: 'right',
        // },
        // //...getColumns("pump", t),
        // {
        //     text: 'text.message',
        //     field: 'Message',
        //     type: COLUMN_TYPE.TEXT,
        //     fixed: 'right'
        // },
        // {
        //     type: COLUMN_TYPE.ACTION,
        //     fixed: "right"
        // },
    ]
    useEffect(() => {
        dispatchCustomEvent(listUpdateEvent);
    }, [type]);

    return (
        <ListView
            dataSource={fileData || []}
            columnConfig={columns}
            rowClassName={Project.getRecordActionClass}
            nameField='sku'
            skipNewButton
            className='scroll_fix'
            skipEdit
        />
    );
}