import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EVENT_NAME, dispatchCustomEvent } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { Project } from '../../project/Project';

const listUpdateEvent = EVENT_NAME.TARIF_LIST_UPDATE;

export default function ImportDetail(props) {
    const { fileData } = props;
    const { type } = useParams();

    const columns = [
        {
            text: 'SalesNetwork',
            field: 'salesNetwork',
            type: COLUMN_TYPE.TEXT,
            width: 120
        },
        {
            text: 'Code',
            field: 'code',
            type: COLUMN_TYPE.TEXT,
            width: 100
        },
        {
            text: 'text.tarif_1',
            field: 'priceCurrent',
            // render: (text, record) => {
            //     return <wrap>{text.toFixed(2)}</wrap>
            // },
            type: COLUMN_TYPE.TEXT,
            width: 400
        },
        {
            text: 'text.tarif_2',
            field: 'priceFuture',
            // render: (text, record) => {
            //     return <wrap>{text.toFixed(2)}</wrap>
            // },
            type: COLUMN_TYPE.TEXT,
            width: 400
        },
        Project.getImportActionColumn(COLUMN_TYPE.TEXT),
        Project.getImportMessageColumn(COLUMN_TYPE.TEXT),
    ]
    useEffect(() => {
        dispatchCustomEvent(listUpdateEvent);
    }, [type]);

    return (
        <>
            <div class="alert_contianer">
                <alert warning="warning">
                    <icon>info</icon>
                    <text marquee="marquee"><span>Remember to check after the date of switching from rate 1 to 2</span></text>
                </alert>
            </div>
        <ListView
            dataSource={fileData || []}
            columnConfig={columns}
            rowClassName={Project.getRecordActionClass}
            nameField='sku'
            skipNewButton
            skipEdit
            />
        </>
    );
}