import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import { Table, Typography } from 'antd';
import { Column } from '@ant-design/plots';

import { Ajax } from '../../components/Ajax';
import { Defines } from '../../project/Defines';

import ListView from '../../Shared/ListView';
import InputCont from '../../Shared/Form/InputCont';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';

import { StatisticsExport } from './action/statisticsAction';
import { changeNetwork } from '../../store/actions';

const { Text } = Typography;

function Statistics(props) {
    const {role, network /*, networks, changeNetwork*/} = props;

    const [weekRange, setWeekRange] = useState({From: null, To: null});
    const [switchChartData, setSwitchChartData] = useState(false);
    const [data, setData] = useState([]);
    const [filterRangeColumnData, setFilterRangeColumnData] = useState([]);
    const [currentNetwork, setCurrentNetwork] = useState([])

    console.log(weekRange, currentNetwork);
    useEffect(() => {
        if (role !== 'superadmin') {
            setCurrentNetwork(network);
        }
    }, [network]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        doAction();
    }, [weekRange, currentNetwork]);// eslint-disable-line react-hooks/exhaustive-deps

    const doAction = useCallback(() => {
        if (weekRange.From && weekRange.To) {
            let fromDate = moment(weekRange?.From?.toDate(), Defines.Format.MomentDate).format(Defines.Format.MomentDate);
            let toDate = moment(weekRange?.To?.toDate(), Defines.Format.MomentDate).format(Defines.Format.MomentDate);

            Ajax.get({
                url: "/api/Statistics/Charts",
                data: {
                    from: fromDate,
                    to: toDate,
                    network: currentNetwork
                },
                success: function (response) {
                    if (response) {
                        if (response.DataColumn) {
                            setFilterRangeColumnData(response.DataColumn);
                        }
                    }
                }
            })
        }
    }, [weekRange, currentNetwork])

    useEffect(() => {
        let fromDate = moment(weekRange?.From?.toDate(), Defines.Format.MomentDate).format(Defines.Format.MomentDate);
        let toDate = moment(weekRange?.To?.toDate(), Defines.Format.MomentDate).format(Defines.Format.MomentDate);

        Ajax.post({
            url: "/api/Statistics/GetListData",
            data: { filterData: (weekRange.From && weekRange.To) ? JSON.stringify({ from: fromDate, to: toDate, network: currentNetwork }) : '' },
            success: function (response) {
                if (response) {
                    setData(response.Data);
                }
            }
        })
    }, [weekRange, currentNetwork])

    const columns = [
        {
            text: 'text.user',
            render: (text, record) => {
                if (record.ID === 0) {
                    return <wrap><text>Anonyme</text></wrap>
                } else {
                    if (record.userName === ' ') {
                        return <wrap><text>{record.email}</text></wrap>
                    } else {
                        return <wrap><text>{record.userName}</text></wrap>
                    }
                }
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.connections',
            render: (text, record) => {
                return <wrap><text>{JSON.parse(record.json).find(e=> e.category === 'connections')?.count}</text></wrap>
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.initiated_studies',
            render: (text, record) => {
                return <wrap><text>{JSON.parse(record.json).find(e=> e.category === 'initiated_studies')?.count}</text></wrap>
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.studies_saved',
            render: (text, record) => {
                return <wrap><text>{JSON.parse(record.json).find(e=> e.category === 'studies_saved')?.count}</text></wrap>
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.uploaded_studies',
            render: (text, record) => {
                return <wrap><text>{JSON.parse(record.json).find(e=> e.category === 'uploaded_studies')?.count}</text></wrap>
            },
            type: COLUMN_TYPE.TEXT,
        }
    ]  

    const configColumn = {
        data: filterRangeColumnData,
        isGroup: true,
        xField: 'week',
        yField: 'value',
        seriesField: 'category',
        dodgePadding: 2,
        label: {
            position: 'middle',
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' },
            ],
        },
    };

    const switchStatisticsView = (e) => {
        setSwitchChartData(!switchChartData);
    };

    const onChangeWeek = useCallback((values) => {
        if (values) {
            setWeekRange({ From: values[0], To: values[1] })
        } else {
            setWeekRange({});
        }
    }, [setWeekRange]);


    return (<>
        <view>
            <toolbar>
                <InputCont 
                    label={switchChartData ? 'text.synthesis': 'text.details'}
                    inputProps={{ 
                        name: "list", 
                        type: 'checkbox', 
                        value: switchChartData,
                        onChange: switchStatisticsView
                    }} 
                />
                { role === 'superadmin' && <InputCont
                    label='text.networks'
                    inputProps={{ 
                        name: 'network', 
                        type: 'select', 
                        valuelist: 'salesnetworks',
                        style: { width: '330px' }, 
                        value: currentNetwork, 
                        onChange: (e) => {setCurrentNetwork(e)},
                        disableScroll: true
                    }}
                />}
                <InputCont
                    label='text.time_period_selection'
                    inputProps={{ 
                        name: 'dateRange', 
                        type: 'daterange',
                        picker: "week",
                        format: `${Defines.Format.Week}`,
                        placeholder: "",
                        dataItem: weekRange,
                        setDataItem: setWeekRange,
                        onChange: onChangeWeek
                    }}
                />
                { !switchChartData &&
                    <action right="">
                        <div
                            className='button primary'
                            title="Export"
                            onClick={() => {
                                let fromDate = moment(weekRange?.From?.toDate(), Defines.Format.MomentDate).format(Defines.Format.MomentDate);
                                let toDate = moment(weekRange?.To?.toDate(), Defines.Format.MomentDate).format(Defines.Format.MomentDate);

                                return StatisticsExport({filterData: (weekRange.From && weekRange.To) ? JSON.stringify({ from: fromDate, to: toDate, network: currentNetwork }) : ''})}
                            }>
                            <icon>download</icon><text>Export</text>
                        </div>
                    </action>
                }
            </toolbar>
            {switchChartData ? <view vertical="vertical">
                <view>
                    {data && <Column {...configColumn} />}
                </view>
            </view>
            :
            <ListView
                isSimple
                columnConfig={columns}
                dataSource={data || []}
                skipEdit
                skipNewButton
                summary={pageData => { //NOSONAR
                    let totalConnections = 0;
                    let totalIniteatedStudies = 0;
                    let totalSavedStudies = 0;
                    let totalUploadStudies = 0;

                    pageData.forEach(( data ) => {
                        totalConnections += JSON.parse(data.json).find(e=> e.category === 'connections') ? JSON.parse(data.json).find(e=> e.category === 'connections').count : 0;
                        totalIniteatedStudies += JSON.parse(data.json).find(e=> e.category === 'initiated_studies') ? JSON.parse(data.json).find(e=> e.category === 'initiated_studies').count : 0;
                        totalSavedStudies += JSON.parse(data.json).find(e=> e.category === 'studies_saved') ? JSON.parse(data.json).find(e=> e.category === 'studies_saved').count : 0;
                        totalUploadStudies += JSON.parse(data.json).find(e=> e.category === 'uploaded_studies') ? JSON.parse(data.json).find(e=> e.category === 'uploaded_studies').count : 0;
                    });
            
                    return (
                      <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                                <Text type="danger">Total</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                <Text type="danger">{totalConnections}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                                <Text type="danger">{totalIniteatedStudies}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                <Text type="danger">{totalSavedStudies}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                                <Text type="danger">{totalUploadStudies}</Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
            />}
        </view>
    </>);
}

export default connect(state => ({
    role: state.role,
    networks: state.networks,
    network: state.network
}),
    dispatch => ({
        changeNetwork: (network) => dispatch(changeNetwork(network))
    }))(Statistics);
