import { Upload } from 'antd';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { PopupTypes } from '../../components/Popup';

import { ApiUrl } from '../../project/Defines';
import { EVENT_NAME } from '../../project/utilities';

import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { openPopup } from '../../store/actions';
import i18n from '../../Shared/Translation/i18n';

import ImportTranslation from './importDetail';
import UITranslationDetails from './detail';
import { TranslationImport, TranslationExport } from '../UITranslations/actions/translationAction';

const listUpdateEvent = EVENT_NAME.UI_TRANSLATIONS_LIST_UPDATE;

function UITranslations(props) {
    const dispatch = useDispatch();

    const columns = [
        {
            text: 'text.code',
            field: 'code',
            type: COLUMN_TYPE.TEXT,
            readOnly: true,
        },
        {
            text: 'text.description',
            field: 'description',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION
        }
    ];

    const importData = (file, onSuccess) => {
        const windowKey = 'importWnd';

        TranslationImport(file, listUpdateEvent, (response) => {
            if (response && response.Data) {
                dispatch(openPopup({
                    component: ImportTranslation,
                    windowKey,
                    title: "Import",
                    type: PopupTypes.Popup,
                    //className: popupClassName,
                    fullScreen: false,
                    bodyProps: { windowKey, fileData: response.Data }
                }));
            }
        })
    }

    const onNewTranslation = () => {
        const windowKey = 'transWnd';
        dispatch(openPopup({
            component: UITranslationDetails,
            windowKey,
            title: i18n('text.translations'),
            type: PopupTypes.Popup,
            fullScreen: false,
            bodyProps: { windowKey }
        }));
    }

    return (
        <>
            {
                1 === 2 &&
                <toolbar>
                    <action right="">
                        <div
                            className='button primary'
                            title="Export"
                                onClick={TranslationExport}
                        >
                            <icon>download</icon><text>Export</text>
                        </div>

                        <Upload id="uploader"
                            customRequest={importData} //NOSONAR
                            showUploadList={false}
                        >
                            <div className='button primary' title="Import"><icon>upload</icon><text>Import</text></div>
                        </Upload>
                    </action>
                </toolbar>
            }
            <ListView
                isSimple
                search={{ field: 'search' }}
                columnConfig={columns}
                filters={[
                    { name: 'export', type: 'button', text: 'Export', onClick: TranslationExport, icon: 'download', align: 'right' },
                    { name: 'import', type: 'uploader', text: 'Import', customRequest: importData, icon: 'upload', align: 'right' }
                ]}
                apiUrl={ApiUrl.UITranslationsList}
                inlineUpdateUrl={ApiUrl.UITranslationsUpdate}
                editable
                // deleteUrl={ApiUrl.}
                //detailUrl={Project.getPageUrl(AppPages.UITranslationDetails)}
                listUpdateEvent={EVENT_NAME.UI_TRANSLATIONS_LIST_UPDATE}
                onNewClick={onNewTranslation} //NOSONAR
            // noPaging
            />

        </>
    );
}

export default connect(state => ({}))(UITranslations);