import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input } from '../../components/Translations';

/**
 * the component generating Searching in Listview
 * @param {any} props contains: field, placeHolder, appliedFilter, setAppliedFilter
 * @exports Search
 */
export default function Search(props) {
    const {
        field,
        placeHolder,
        appliedFilter,
        setAppliedFilter,
        children } = props;

    const timeOut = useRef(null);
    const [value, setValue] = useState("");

    useEffect(() => {
        return () => {
            clearTimeout(timeOut.current);
        }
    }, [])

    const onChange = useCallback((e) => {
        if (timeOut.current) {
            clearTimeout(timeOut.current);
        }

        let val = e.target.value;
        setValue(val);
        timeOut.current = setTimeout(() => {
            let newFilter = { ...appliedFilter };
            newFilter[field] = val;
            setAppliedFilter(newFilter);
        }, 500);
    }, [appliedFilter, setAppliedFilter, field]);

    const onClear = useCallback(() => {
        let newFilter = { ...appliedFilter };
        newFilter[field] = '';
        setAppliedFilter(newFilter);
    }, [appliedFilter, setAppliedFilter, field]);

    return <toolbar className="srch" effect="material">
                <icon>search</icon>
                <Input 
                    className="icon_search" 
                    type="text"  
            {...(value ? { value: value } : { defaultValue: appliedFilter && appliedFilter[field] })}
                    placeholder={placeHolder || "text.search_here"} 
                    onChange={onChange} 
                />
                {children}
                <div className="button micro" onClick={onClear}>
                    <icon>close</icon>
                </div>
            </toolbar>
}