import React, { useState, useCallback } from 'react';
import { Spin } from 'antd';
import { EVENT_NAME, useCustomEvent, dispatchCustomEvent } from '../../project/utilities';

export default function Loader(props) {
    const [innerLoaders, setInnerLoaders] = useState([]);

    const showLoader = useCallback((e) => {
        setInnerLoaders((oldLoaders) => {
            return [...oldLoaders.filter(item => item !== e.detail.loaderName), e.detail.loaderName]
        });
    }, []);

    const hideLoader = useCallback((e) => {
        setInnerLoaders((oldLoaders) => {
            return oldLoaders.filter(item => item !== e.detail.loaderName)
        })
    }, []);

    useCustomEvent(EVENT_NAME.SHOW_LOADER, showLoader);
    useCustomEvent(EVENT_NAME.HIDE_LOADER, hideLoader);
//    useEffect(() => { setLoaders(innnerLoaders) },[ innerLoaders])
    return (
        innerLoaders.length > 0 || props.force?
            <div style={{
                    "position": "absolute",
                    "zIndex": "99999",
                    "width": "100%",
                    "height": "100%",
                    //"background": "#fa5b0b44",
                    "background": "#2f2f2f77",
                    "display": "flex"
                }}>

                <div style={{ "margin": "auto" }}>
                    <Spin size="large" />
                </div >
            </div>
            :
            <></>
    );
}

export function showLoader(loaderName) {
    dispatchCustomEvent(EVENT_NAME.SHOW_LOADER, { loaderName });
}

export function hideLoader(loaderName) {
    dispatchCustomEvent(EVENT_NAME.HIDE_LOADER, { loaderName });
}

