import { showLoader, hideLoader } from '../../../Shared/Loader';
import { Ajax } from '../../../components/Ajax';
import { ApiUrl } from '../../../project/Defines';
import { message } from 'antd';
import { dispatchCustomEvent } from '../../../project/utilities';
import FileSaver from 'file-saver';

const loaderName = 'TARIF_FILE_UPLOAD_LOADER';
const ExportLoaderName = 'EXPORT_LOADER';

export function TarifImport(data, listUpdateEvent, successCallback) {
	showLoader(loaderName);

	Ajax.post({
		url: ApiUrl.TarifImport,
		data: {
			file: data && data.file
		},
		success: (response) => {
			if (!response.HasError) {
				successCallback && successCallback(response);
				dispatchCustomEvent(listUpdateEvent);
				hideLoader(loaderName);
			} else {
				message.error(response.message);
				hideLoader(loaderName);
			}
		}
	});
}

export function TarifExport() {
	showLoader(ExportLoaderName);

	Ajax.download({
		url: ApiUrl.TarifExport,
		data: {},
		//autoDownload: false,
		success: (blob) => {
			FileSaver.saveAs(blob, "Tarif.xlsx");
			hideLoader(ExportLoaderName);
		},
		error: () => {
			hideLoader(ExportLoaderName);
		}
	});
}