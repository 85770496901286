import { Dropdown, /*, Tooltip*/ } from 'antd';
import React /*, { useEffect, useRef }*/ from 'react';
import { connect /*, useDispatch*/ } from "react-redux";
//import { withRouter } from 'react-router-dom';
import { withRouter } from '../../hook/withRouter';
import i18n from '../../Shared/Translation/i18n';
import { UserRoles } from '../../project/Defines';
import { Project } from '../../project/Project';
import { AppPages /*, ApiUrl, StoreKeys*/ } from '../../project/Defines';
import InputCont from '../../Shared/Form/InputCont';
import { /*changeCulture,*/ changeNetwork, changeRole, closeAllPopups } from '../../store/actions';

/**
 * The layout component specifies the site layout, top menu based on login state and active states of top menu items
 * @param {object} props  { isLoggedIn, popups, culture, role, etc.}
 */
function TopMenu(props) {
    const { userData, role, roles, changeRole, changeNetwork, network, networks } = props;

    let logoutUrl = props.userData && props.userData.logoutUrl ? props.userData.logoutUrl : Project.getPageUrl(AppPages.Logout);

    let userMenu =
        <div className="list_menu ant-dropdown-menu">
            <ul>
                {
                    userData && userData.portalName && userData.portalUrl &&
                    <>
                        <li><a href={userData.portalUrl}><icon></icon><text>{i18n('userData.portalName')}</text></a></li>
                        <li className="separator"></li>
                    </>
                }
                
                <li command="easter" effect="material" className="disabled" bottom=""><icon>info</icon><text>{i18n('text.version')}</text><separator vertical=""></separator><text><b>{process.env.REACT_APP_VERSION}</b></text><text><count data-count=""></count></text></li>
                <li className="separator"></li>
                <li><a href={logoutUrl}><icon></icon><text>{i18n('text.sign_out')}</text></a></li>
            </ul>
        </div>;


    let location = window.location.pathname.toLowerCase();

    return <><toolbar id="mainHeader" className="main_header">
        {
            props.isLoggedIn && !Project.skipHeader(location) && userData && 
            <> <nav launcher="" id="launcher">
                {roles && roles.length > 1 ? <InputCont
                    inputProps={{ name: 'role', type: 'select', valuelist: roles.map(r => ({ text: r, value: r })), style: { width: '230px' }, value: role, onChange: changeRole, disableScroll: true }} /> :
                    <text style={{ width: '230px', paddingLeft: '30px' }}>{role}</text>
                }
                {(role === UserRoles.ADMIN) && networks && networks.length > 0 && (networks.length > 1 ? < InputCont
                    inputProps={{ name: 'network', type: 'select', valuelist: networks, style: { width: '330px' }, value: network, onChange: changeNetwork, disableScroll: true }} /> : <text>{networks[0].text}</text>)}


            </nav>
                <separator vertical=""></separator>

                <Dropdown
                    className=""
                    trigger={['click']}
                    overlay={userMenu} placement="topRight">
                    <div command="userMenu" effect="material" className="button adaptive">
                        <icon data-tip={userData.userName}>user</icon>
                        <text>{userData.userName.trim() !== '' ? userData.userName : userData.email}</text>
                    </div>
                </Dropdown>
            </>
        }

    </toolbar>
    </>
}
export default connect(state => ({
    role: state.role,
    roles: state.roles,
    culture: state.culture,
    userData: state.userData,
    isLoggedIn: state.isLoggedIn,
    networks: state.networks,
    network: state.network
}),
    dispatch => ({
        closeAllPopups: () => dispatch(closeAllPopups()),
        changeRole: role => dispatch(changeRole(role)),
        changeNetwork: (network) => dispatch(changeNetwork(network))
    }))(withRouter(TopMenu));