import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import i18n from '../../Shared/Translation/i18n';
import { hideLoader, showLoader } from '../Loader';

const maxcount = 100;

/**
 * the component used in forms for image upload
 * @param {any} props contains: imageUrl, imageField, dataItem, setDataItem, type, alt, showUploadList, listType, className, actionUrl
 * @exports CustomUpload
 */
export default function CustomUpload(props) {// NOSONAR
    const url = (process.env.REACT_APP_API_URL || "");
    const [isLoading, setIsLoading] = useState(false);
    const { imageField, dataItem, setDataItem, type, alt, showUploadList, listType, fileNameField, className, actionUrl, multiple, skipDeleteIcon, keepName, showList, anyFile, hideLink } = props;
    const [fileList, setFileList] = useState([]);
    const duplicateNames = useRef([]);
    let uploadingCount = useRef(0);
    const uploadRef = useRef(null);

    useEffect(() => {
        return () => hideLoader("upload");
    }, [])
    useEffect(() => {
        if (fileList.length) {
            let newData = { ...dataItem };

            if (multiple) {
                newData[imageField] = [...newData[imageField], ...fileList.map(f => {
                    let image_url = url + "/images/GetFile?type=Temp&filename=" + f.response.FileName + "&fileDownloadName=" + (f.name || '')
                    return { image_guid: f.response.FileName, name: f.name, image_url }
                })];

            } else {
                let image_url = url + "/images/GetFile?type=Temp&filename=" + fileList[0].response.FileName + "&fileDownloadName=" + (fileList[0].name || '');
                newData[imageField] = [{ image_guid: fileList[0].response.FileName, name: fileList[0].name, image_url }];
            }
            setDataItem(newData);
            setFileList([]);
        }
    }, [fileList, keepName]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = useCallback((info) => {
        if (uploadRef.current) {
            if (info.file.status === 'uploading') {
                return;
            }
            if (info.file.status === 'error') {
                const responseFileInfo = info.file.response?.File;
                message.error((responseFileInfo?.length && responseFileInfo[0])|| `${i18n('text.unsupported_media_type')}: "${info.file.name}"`)
            }

            if (info.fileList.every(f => f.status === 'done' || f.status === 'error' || !f.status)) {
                setFileList(info.fileList.filter(f => f.status === 'done'));
                setIsLoading(false);
                hideLoader("upload");
                if (duplicateNames.current.length) {
                    message.warning(i18n('message.duplicate_file_name', [duplicateNames.current.length]));
                    duplicateNames.current = [];
                }
                uploadingCount.current = 0
            }
        }
    }, [duplicateNames]);

    const removeImage = useCallback(image => {
        let newData = { ...dataItem };
        if (multiple) {
            newData[imageField] = newData[imageField].filter(im => im.image_guid !== image);

        } else {
            newData[imageField] = [];

        }
        setDataItem(newData);
        //   }
    }, [dataItem, setDataItem, imageField, multiple]);

    const beforeUpload = useCallback((file) => {
        showLoader("upload");
        setIsLoading(true);
        if (keepName && dataItem[imageField].some(im => im.name === file.name)) {
            duplicateNames.current = [...duplicateNames.current, file.name];
            return false;
        }
        uploadingCount.current += 1;
        if (uploadingCount.current > maxcount) {
            if (uploadingCount.current === maxcount + 1) {
                message.warning(i18n('message.only_max_count_file_will_be_processed', [maxcount]), 2)
            }
            return false;
        }

        return !keepName;
    }, [dataItem, imageField, keepName]);

    const uploadButton = (
        <div>
            {
                //<Icon type={isLoading ? 'loading' : 'plus'} />
                isLoading ? <LoadingOutlined /> : <PlusOutlined />
            }
            <div className="ant-upload-text">{i18n('text.upload')}</div>
        </div>
    );

    const innerContent = dataItem[imageField] && dataItem[imageField][0] && <div className={className || "avatar-uploader"}><span> {/* NOSONAR */ }
        {(anyFile ? (hideLink ? <></> : <a target='_blank' href={dataItem[imageField][0].image_url} >{fileNameField ? dataItem[fileNameField] : (dataItem[imageField][0].name || dataItem[imageField][0].image_guid)}</a>) : /*eslint-disable-line react/jsx-no-target-blank*/ /* NOSONAR */
            <img src={dataItem[imageField][0].image_url} alt={alt || dataItem[imageField].image_url} style={{ width: '100%', objectFit: 'contain' }} />)}</span>
        {!skipDeleteIcon ? <button className="button mini" type='button' onClick={
            (e) => {
                e.stopPropagation();
                removeImage(dataItem[imageField]);
            }
        }>< icon>delete</icon></button > : <></>}</div>
    return <>
        {!fileList.length && ((!multiple && innerContent) || <Upload ref={uploadRef}
            accept={anyFile ? '.png, .jpg, .gif, .jpeg, .pdf, .mp4, .mov, .avi, .doc, .docx, .xls, .xlsx' : '.png, .jpg, .gif, .jpeg'}
            listType={listType || "picture-card"}
            multiple={multiple}
            //  fileList={fileList}
            // className={className || "avatar-uploader"}
            className={className}
            showUploadList={showUploadList || false}
            action={url + (actionUrl || "/images/uploadfile?&type=" + type)}
            beforeUpload={beforeUpload}// { console.log(data, "beforeupload"); return data[0].name !='download (1) - Copy.jpeg'; }}
            //onSuccess={(...test) => { console.log("onsuccess", test); }}
            onChange={handleChange}
        >
            {uploadButton}
        </Upload >)}
        {multiple && showList ? dataItem[imageField].map((m, i) => {
            const imageKey = m.image_guid;
            const deleteButton = !skipDeleteIcon && <button className="button mini" type='button' onClick={() => { removeImage(imageKey); }}><icon>delete</icon></button >;
            return <div key={imageKey} className={className || "avatar-uploader"} >
                <span>{anyFile ? (hideLink ? <></> : <a target='_blank' href={m.image_url} >( m.name || m.image_guid )</a>) : <img src={m.image_url} style={{ width: '100%', objectFit: 'contain' }} alt="" />}</span>{/*eslint-disable-line react/jsx-no-target-blank*/ /* NOSONAR */}
                {deleteButton}
            </div >
        }) : <></>}</>
}