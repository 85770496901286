import React from 'react';
import { ApiUrl, AppPages } from '../../project/Defines';
import { EVENT_NAME } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { Project } from '../../project/Project';

export default function SalesNetwork(props) {

    const columns = [
        {
            text: 'text.name',
            field: 'name',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.code',
            field: 'code',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.sso',
            field: 'ssoName',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.cookiebot',
            field: 'cookiebot',
            type: COLUMN_TYPE.TEXT
        },
       
        {
            type: COLUMN_TYPE.ACTION
        }
    ];

    return (
        <ListView
            listName={'sales_network'}
            columnConfig={columns}
            apiUrl={ApiUrl.SalesNetworkList}
            deleteUrl={ApiUrl.SalesNetworkDelete}
            detailUrl={Project.getPageUrl(AppPages.SalesNetworkDetails)}
            listUpdateEvent={EVENT_NAME.SALES_NETWORK_LIST_UPDATE}
            nameField="name"
        />
    );
}