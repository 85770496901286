import React, { useEffect, useRef, useState } from 'react';
import { Link, /*Prompt,*/ /*useHistory useNavigate*/ } from 'react-router-dom';
// import { useDispatch } from 'react-redux';

import i18n from '../../Shared/Translation/i18n';
// import { PopupTypes } from '../../components/Popup';
// import { openPopup } from '../../store/actions';

import { useCallbackPrompt } from '../../hook/useCallbackPrompt';

import { Modal } from 'antd';

/**
 * a wrapper to detail form representink a page wih url
 * @param {any} props contains: backUrl, title, showPopup
 * @exports DetailView
 */
export default function DetailView(props) {
    // const dispatch = useDispatch();
    //const history = useHistory();
    // const navigate = useNavigate();
    // const [allowNavigate, setAllowNavigate] = useState(false);
    const { backUrl, title, showPopup, hasChanges } = props;
    const detailRef = useRef(null);

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

    
    // const confirmToClose = useCallback((location) => {
    //     dispatch(openPopup({
    //         windowKey: 'wndConfirmToClose',
    //         type: PopupTypes.Confirm,
    //         disableOutsideClick: true,
    //         title: i18n('message.close_confirm_title'), //'You have unsaved changes.',
    //         text: i18n('message.close_confirm'), //'Do you want to continue? All data will be lost.',
    //         buttonYesText: i18n('text.yes'),
    //         buttonNoText: i18n('text.no'),
    //         yesCallback: function () {
    //             setAllowNavigate(true);
    //             setTimeout(() => { navigate(location); }, 500);
    //         }
    //     }));
    // }, [dispatch, navigate]);

    // const onMessage = useCallback((location, action) => {
    //     console.log("Backing up...")

    //     if (allowNavigate) return true;

    //     if (hasChanges) {
            
    //         //confirmToClose(location);
    //     }
    //     return !hasChanges;
    // }, [allowNavigate, hasChanges, confirmToClose])

    useEffect(() => {
        if (hasChanges) {
            setShowDialog(true);
        } else {
            setShowDialog(false);
        }
    }, [hasChanges])

    //const promptContent = <Prompt message={onMessage} />;
    const promptContent = <>
            { hasChanges && <Modal 
                title={i18n('message.close_confirm_title')} 
                open={showPrompt} 
                onOk={confirmNavigation} 
                onCancel={cancelNavigation}
            >
                <p>{i18n('message.close_confirm')}</p>
            </Modal>}
        </>;

    return showPopup ?
        <div id='windowContent' className='window_cont' ref={detailRef}>
            {promptContent}
            {props.children}
        </div>
        :
        
        <view className='window_cont' ref={detailRef} >
            {promptContent}
            {backUrl && <toolbar>
                <div effect='material' className='button white mini back'>
                    <Link to={backUrl}><icon>left</icon></Link>
                </div>
                <separator vertical=""></separator>
                {title || ""}
                {/* <div>
                    <strong><text>{title || ""}</text></strong>
                </div> */}
            </toolbar>}
            {props.children}
        </view>
        ;
}