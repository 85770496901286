import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { EVENT_NAME, dispatchCustomEvent } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { Project } from '../../project/Project';


const listUpdateEvent = EVENT_NAME.TRANSLATIONS_LIST_UPDATE;

function ImportDetail(props) {
    const { fileData, salesNetworkLanguage } = props;
    const { type } = useParams();

    const columns = salesNetworkLanguage ? [
        {
            text: 'text.sales_networks',
            field: 'salesNetwork',
            type: COLUMN_TYPE.TEXT,
            width: 120,
            fixed: 'left',
        },
        {
            text: 'text.code',
            field: 'code',
            type: COLUMN_TYPE.TEXT,
            width: 300,
            fixed: 'left',
        },
        ...(salesNetworkLanguage.map(l => ({
            text: l.label,
            key: l.Code,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                return (
                    <wrap>
                        <text>{record[l.Code] || "" }</text>
                    </wrap>
                );
            },
        }))),
        Project.getImportActionColumn(COLUMN_TYPE.TEXT),
        Project.getImportMessageColumn(COLUMN_TYPE.TEXT),
    ] : null;

    useEffect(() => {
        dispatchCustomEvent(listUpdateEvent);
    }, [type]);

    if (salesNetworkLanguage && columns) {
        return (
            <ListView
                dataSource={fileData || []}
                columnConfig={columns}
                rowClassName={Project.getRecordActionClass}
                nameField='code'
                skipNewButton
                className='scroll_fix'
                skipEdit
            />
        );
    }
    return <></>;
}

export default connect(state => ({ network: state.network }))(ImportDetail);