import React from 'react';
import { useParams } from 'react-router-dom';
import { ApiUrl } from '../../project/Defines';
import { EVENT_NAME } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { PopupTypes, PopupClasses } from '../../components/Popup';

export default function ProductSalesNetworkDocument(props) {
    const { id } = useParams();

    const columns = [
        {
            text: 'text.language',
            field: 'language',
            type: COLUMN_TYPE.TEXT,
            width: 70,
         
        },
        {
            text: 'text.url',
            field: 'url',
            type: COLUMN_TYPE.TEXT,
            width: 70,
         
        },
        {
            text: 'text.type_doc',
            field: 'typeDoc',
            type: COLUMN_TYPE.TEXT,
            width: 80
        },
        {
            text: 'text.name_doc',
            field: 'nameDoc',
            type: COLUMN_TYPE.TEXT,
            width: 70,
        },
        {
            type: COLUMN_TYPE.ACTION,
            fixed: "right"
        },
    ];

    return (
        <ListView
            columnConfig={columns}
            apiUrl={ApiUrl.ProductSalesNetworkDocumentList}
            listUpdateEvent={EVENT_NAME.UPDATE_PRODUCT_DOCUMENT_LIST}
            className='scroll_fix'
            skipNewButton={true}
            staticFilter={{ productSalesnetworkId: id }}
            detailProps={{ fullScreen: false, popupType: PopupTypes.Document, className: PopupClasses.Medium, windowKey: 'wndDocumnetDetail' }}
        />
    );
}