import React, { useCallback } from 'react';
import i18n from '../../Shared/Translation/i18n';

import InputCont from '../../Shared/Form/InputCont';
import DetailView from '../../Shared/DetailView';
import useDetail from '../../Shared/useDetail';
import { ApiUrl } from '../../project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import Langauges from '../SalesNetworkConfig/Languages';
import Applications from './Applications';
import Systems from './Systems';
import { Ajax } from '../../components/Ajax';
import { message } from 'antd';

import { hideLoader, showLoader } from '../../Shared/Loader';

const loaderName = 'CONTACT_LOADER';

export default function SalesNetworkConfig(props) {
    const listUpdateEvent = EVENT_NAME.SALES_NETWORK_LIST_UPDATE;

    const { dataItem, setDataItem, updateData, hasChanges, closePopup, detailRef, onChangeData, validation } = useDetail({
        data: {},
        updateUrl: ApiUrl.SalesNetworkCurrentUpdate,
        dataLoadUrl: ApiUrl.SalesNetworkGetData,
        listUpdateEvent
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    const dataChange =(e) => {
        updateData(updateSuccessCallback, null, { overrideData: { [e.target.name]: e.target.checked } });
    };

    const updateBrand = (e) => {
        Ajax.post({
            url: ApiUrl.SalesNetworkBrandUpdate,
            data: { brandId: e },
            success: function (response) {
                setDataItem({...dataItem, brandId: e })
            },
        })
    }

    const updateContact = (e) => {
        if (dataItem) {
            showLoader(loaderName);
            Ajax.post({
                url: ApiUrl.SalesNetworkUpdate,
                data: dataItem,
                success: function (response) {
                    if(response && !response.HasError) {
                        hideLoader(loaderName);
                        message.success('Data has been updated!');
                        setDataItem(response);
                    } else {
                        hideLoader(loaderName);
                        message.error();
                    }
                },
            })
        }
    }

    return <>
        {dataItem && <DetailView
            hasChanges={hasChanges}
        >
            <form>
                <div className="form_container">
                    <div className="section_group">
                        <div className="sections ">
                            <div className="form_fields"><div className="section_title"><text>{i18n("text.global_information")}</text></div><div className="form_separator"></div></div>
                            {/*<InputCont label='text.no_connection_required' inputProps={{ validation, name: "requiredConnexion", type: 'checkbox', value: !dataItem.requiredConnexion, onChange: dataReverseChange }} />*/}
                            <InputCont label='text.price_display' inputProps={{ validation, name: "showPrice", type: 'checkbox', value: dataItem.showPrice, onChange: dataChange }} />
                            <InputCont label="text.brand"
                                inputProps={{
                                    name: 'brandId',
                                    type: 'select',
                                    value: dataItem.brandId,
                                    valuelist: 'brands',
                                    onChange: updateBrand,
                                    disabled: true
                                }} />
                            <InputCont 
                                label='text.contact'
                                inputProps={{ name: "contact", type: 'textarea', maxLength: "75", value: dataItem.contact, onChange: onChangeData }} 
                            />
                        </div>
                    </div>
                        
                    <Langauges />
                    <Applications />
                    <Systems />
                </div>
                <toolbar>
                        <wrap>
                            <action right=''>
                                <div 
                                    onClick={updateContact}
                                    className={hasChanges ? ' button primary' : 'button'}>
                                <icon>save</icon>{i18n('text.save')}
                                 </div>
                            </action>
                        </wrap>
                    </toolbar>
            </form>
        </DetailView>
        }
    </>
}