import store from '../../store';
//import config from './config.js';

import en from './i18n-en.json';

let currentStore = null;
let lang = null;
let translations = [];


export default function (key, data = []) {

    currentStore = store.getState();
    lang = currentStore.culture || 'en';
    let base = getBase(lang);
    if (base) {
        return translate(base, key, data);
    } else {
        // eslint-disable-next-line no-throw-literal
        throw "Langue '" + lang + "' non prise en charge"; //NOSONAR
    }
}


function getBase(langue) {
    let base = translations[langue]; //null;
     switch (langue) {
       case 'fr':
         //base = fr;
         break;
       case 'en':
         base = en;
         break;
       case 'es':
         //base = es;
         break;
       case 'it':
         //base = it;
         break;
       case 'de':
         //base = de;
         break;
       case 'ru':
         //base = ru;
         break;
       default:
         //base = fr;
         break;
     }
    return base;
}

//function getCurrentLang() {
//    let currentlang = config.langs.filter(l => l.value === lang);
//    return currentlang.length > 0 ? currentlang[0] : {};
//}

//function getNextLangue() {
//    let currentPoid = getCurrentLang().poids,
//        newLang = null;
//    while (currentPoid <= config.poidsMax && !newLang) {
//        currentPoid++;
//        // eslint-disable-next-line no-loop-func
//        newLang = config.langs.filter(l => l.poids === currentPoid)[0];
//        newLang = newLang ? newLang.value : null;
//    }
//    return newLang;
//}

/**
 * Fonction de traduction
 * @param base fichier de base
 * @param key clef à traduire
 * @param data donnée complémentaire pour la traduction
 */

function translate(base, key, data) { //NOSONAR
    let keySplit = key && typeof key === 'string' ? key.split('.') : [];

    //récupération de la valeur de la clef
    let translation = base;
    // eslint-disable-next-line array-callback-return
    keySplit.map((k) => { // NOSONAR
        if (translation) translation = translation[k];
    });
    if (typeof translation === 'string' && !(!translation || 0 === translation.length)) {

        if (typeof data === 'object') {
            //remplacement des champs dynamique
            for (let key in data) {
                translation = translation.replace(`\${args[${key}]}`, data[key]);
            }
        } else {
            // eslint-disable-next-line no-throw-literal
            throw "data doit être un tableau associatif"; //NOSONAR
        }
    } else {
        //let newlang = getNextLangue();

        //if (newlang && newlang !== lang) {
        //    lang = newlang;
        //    translation = translate(getBase(lang), key, data);
        //} else {
            translation = key;
        //}
    }
    return translation;
}
