import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EVENT_NAME, dispatchCustomEvent } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { Project } from '../../project/Project';

const listUpdateEvent = EVENT_NAME.UI_TRANSLATIONS_LIST_UPDATE;

export default function ImportDetail(props) {
    const { fileData } = props;
    const { type } = useParams();

    const columns = [
        {
            text: 'text.code',
            field: 'code',
            type: COLUMN_TYPE.TEXT,
            width: 300,
            fixed: 'left',
        },
        {
            text: 'text.description',
            field: 'description',
            type: COLUMN_TYPE.TEXT
        },
        Project.getImportActionColumn(COLUMN_TYPE.TEXT),
        Project.getImportMessageColumn(COLUMN_TYPE.TEXT),
    ]
    useEffect(() => {
        dispatchCustomEvent(listUpdateEvent);
    }, [type]);

    return (
        <ListView
            dataSource={fileData || []}
            columnConfig={columns}
            rowClassName={Project.getRecordActionClass}
            nameField='code'
            skipNewButton
            className='scroll_fix'
            skipEdit
        />
    );
}