import { DatePicker } from "../DatePicker";
import React from "react";
import i18n from '../../Shared/Translation/i18n';
import { DropDownValueList, MultiSelectValueList, MultiColumnDropDown} from "../DropDown";
import { Input, Textarea } from "../Input";
import { ValidationDropDownValueList, ValidationInput, ValidationInputNumber, ValidationUpload, ValidationMultiColumnDropDown } from "./validation";
import CustomUpload from "../CustomUpload";
import { InputNumber } from "antd";
import { DateRangePicker } from "../DatePicker/RangePicker";

/**
 * template to put in forms
 * @param {any} props contains: label, labelIcon, icon, inputProps
 * @exports FormField
 */
export default function InputCont(props) {// NOSONAR
    const { label, skipTranslate, labelIcon, icon, iconClick, inputProps: { validation, /*allowClear,*/ type, placeholder, showTime, ...restInputProps } = {} } = props;
    let inputElement;
    if (placeholder) {
        restInputProps.placeholder = i18n(placeholder);
    }
    if (!restInputProps.onChange) {
        restInputProps.onChange = () => { }
    }
    let isSimple = false;
    switch (type) {
        case 'textarea':
            inputElement = validation ? <ValidationInput validation={validation} inputType='textarea' {...restInputProps} /> : <Textarea type={'textarea'}{...restInputProps} />;
            break;
        case 'select':
            inputElement = validation ? <ValidationDropDownValueList validation={validation}  {...restInputProps} /> : <DropDownValueList {...restInputProps} />;
            break;
        case 'selectCol':
            inputElement = validation ? <ValidationMultiColumnDropDown validation={validation}  {...restInputProps} /> : <MultiColumnDropDown validation={validation} {...restInputProps} />;
            break;
        case 'multiselect':
            inputElement = validation ? <ValidationDropDownValueList validation={validation} mode={'multiple'} {...restInputProps} /> : <MultiSelectValueList {...restInputProps} />;
            break;
        case 'date':
            inputElement = validation ? <ValidationInput validation={validation} inputType={'date'}{...restInputProps} /> : <DatePicker {...restInputProps} />;
            break;
        case 'daterange':
            inputElement = validation ? <ValidationInput validation={validation} inputType={'date'}{...restInputProps} /> : <DateRangePicker {...restInputProps} />;
            break;
        case 'datetime':
            inputElement = validation ? <ValidationInput validation={validation} name={restInputProps.name || restInputProps.dataField} showTime={showTime || true} inputType={'date'}{...restInputProps} /> : <DatePicker showTime={showTime || true} {...restInputProps} />;
            break
        case 'upload':
            inputElement = validation ? <ValidationUpload validation={validation} name={restInputProps.name} {...restInputProps} /> : <CustomUpload {...restInputProps} />;
            break;
        case 'checkbox':
            isSimple = true;
            inputElement = <label checkbox="">{<input type={type} checked={(restInputProps && restInputProps.value) || false} {...restInputProps} />} <box><check></check></box><text>{i18n(label)}</text></label>;
            break;
        case 'number':
            inputElement = validation ? <ValidationInputNumber validation={validation} name={restInputProps.name} {...restInputProps} /> : <InputNumber {...restInputProps} />
            break;

        default:
            inputElement = validation ? <ValidationInput validation={validation} inputType={type || 'text'} type={type || 'text'} {...restInputProps} /> : <Input type={type || 'text'}{...restInputProps} />;
            break;
    }

    return  !isSimple ? <cont>
        { inputElement }
          <decor>
            <label>
                <text>
                    <line></line>
                    <wrap>{labelIcon && <icon>{labelIcon}</icon>}{label && <span>{skipTranslate ? label : i18n(label)}</span>}</wrap>
                </text>
                <line></line>
            </label>
            <border></border>
        </decor>
    { icon && (typeof icon === 'string' ? <div effect="material" className="button mini " onClick={iconClick}><icon>{icon}</icon></div> : icon) }
    </cont > : inputElement
}