import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../Shared/Translation/i18n';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { Project } from '~/project/Project';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import DetailView from '../../Shared/DetailView';
import useDetail from '../../Shared/useDetail';
import InputCont from '../../Shared/Form/InputCont';

/**
 * ...
 * @exports SalesNetworkDetails
 */
export default function SalesNetworkDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.SALES_NETWORK_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, hasChanges, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : 0 },
        windowKey,
        updateUrl: ApiUrl.SalesNetworkUpdate,
        dataLoadUrl: ApiUrl.SalesNetworkLoad,
        deleteUrl: ApiUrl.SalesNetworkDelete,
        nameField: 'name',
        backPage: AppPages.SalesNetwork,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.SalesNetworkDetails),
        // changingValidation: true,
        validationFields: ['name', 'code', 'brandId', 'ssoId']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            hasChanges={hasChanges}
            backUrl={Project.getPageUrl(AppPages.SalesNetwork)}
            title={isNew ? i18n('text.sales_networks') : dataItem.name}
        >
            <view >
                <form>
                    <div className='form_container'>
                        <div className='section_group'>
                            <div className='sections '>
                                <InputCont label="text.name"
                                    inputProps={{ validation, name: 'name', value: dataItem.name, onChange: onChangeData, maxLength: 255 }} />

                                <InputCont label="text.code"
                                    inputProps={{ validation, name: 'code', value: dataItem.code, onChange: onChangeData, disabled: !isNew, maxLength: 50 }} />

                                <InputCont label="text.brand"
                                    inputProps={{
                                        validation,
                                        name: 'brandId',
                                        type: 'select',
                                        value: dataItem.brandId === 0 ? '' : dataItem.brandId,
                                        valuelist: 'brands',
                                        onChange: e => onChangeData({ target: { name: 'brandId', value: e } }),
                                        disableCach: true,
                                        autoSelectFirst: true
                                    }} /> 

                                <InputCont label="text.sso"
                                    inputProps={{
                                        validation,
                                        name: 'ssoId',
                                        type: 'select',
                                        value: dataItem.brandId === 0 ? '' : dataItem.ssoId,
                                        valuelist: 'sso',
                                        onChange: e => onChangeData({ target: { name: 'ssoId', value: e } })
                                    }} /> 

                                <InputCont label="text.cookiebot"
                                    inputProps={{ validation, name: 'cookiebot', value: dataItem.cookiebot, onChange: onChangeData, maxLength: 36 }} />

                            </div>
                        </div>
                    </div>
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className={hasChanges ? ' button primary' : 'button'}><icon>save</icon>{i18n('text.save')}</div>
                                {dataItem.ID > 0 && !dataItem.IsCurrentUser &&
                                    <div className='button' onClick={(e) => {
                                        onDetailDelete(dataItem)
                                    }}>
                                        <icon>delete</icon>{i18n('text.delete')}
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>
                </form>
            </view>
        </DetailView>}
    </>);
}
