import React, { useCallback } from 'react';
import { DatePicker as ANTDDatePicker } from 'antd';
import moment from 'moment';
import { Defines } from '../../project/Defines';

/**
 * custom DataPicker outputing date with format YYYY/MM/DD
 * @param {object} props  dataItem, setDataItem, dataField
 */
export function DatePicker(props) {
    const { dataItem, setDataItem, dataField, min, max, showTime, ...rest } = props;
    const date_moment = showTime ? Defines.Format.MomentDateTime : Defines.Format.MomentDate;
    const date_format = showTime ? Defines.Format.PrintDateTime : Defines.Format.PrintDate;

    const onChange = useCallback((value) => {
        let newData = { ...dataItem };
        newData[dataField] = value !== '' ? moment(value, date_format).format(date_moment) : null;
        setDataItem(newData);
    }, [dataField, dataItem, setDataItem, date_format, date_moment]);

    const disableDate = useCallback((d) => {
        return (min && d.isBefore(min)) || (max && d.isAfter(max));
    }, [min, max]);

    return <ANTDDatePicker
        {...rest}
        showTime={showTime}
        name={dataField}
        value={dataItem && dataItem[dataField] ? moment(dataItem[dataField], date_moment) : null}
        format={date_format}
        onChange={(e, value) => onChange(value)} // NOSONAR
        disabledDate={disableDate} />
}