import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { message } from 'antd';

import { PopupTypes } from '../../components/Popup';

import { ApiUrl /*, AppPages*/ } from '../../project/Defines';
import { EVENT_NAME } from '../../project/utilities';

import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { openPopup } from '../../store/actions';

import ImportTranslation from './importDetail';
import { TranslationImport, TranslationExport } from '../Translations/actions/translationAction';
import { Ajax } from '../../components/Ajax';
import { Input } from '../../Shared/Input';

const listUpdateEvent = EVENT_NAME.TRANSLATIONS_LIST_UPDATE;

function Translations(props) {
    const dispatch = useDispatch();
    const [salesNetworkLanguage, setSalesNetworkLanguage] = useState(null);

    useEffect(() => {
        if (props.network) {
            Ajax.get({
                url: ApiUrl.SalesNetworkLanguagesLoad,
                data: { networkID: props.network },
                success: function (response) {
                    if (response && !response.HasError) {
                        setSalesNetworkLanguage(response.data.filter((el) => el.isSelected));
                    } else {
                        message.error();
                    }
                },
            })
        }
    }, [props.network]);// eslint-disable-line react-hooks/exhaustive-deps

    const columns = salesNetworkLanguage ? [
        {
            text: 'text.code',
            field: 'code',
            type: COLUMN_TYPE.TEXT,
            readOnly: true,
        },
        {
            text: 'text.description',
            field: 'description',
            type: COLUMN_TYPE.TEXT,
            readOnly: true,
        },
        ...(salesNetworkLanguage.map(l => ({
            text: l.label,
            key: l.Code,
            dataIndex: 'translations',
            type: COLUMN_TYPE.TEXT,
            render: (item, index) => {
                let value = '';
                if (item && item.translations) {
                    if (item.translations[l.Code]) {
                        value = item.translations[l.Code];
                    }
                }
                return <wrap>{value}</wrap>
            },
            editor: {
                customRenderer: (props) => { //NOSONAR
                    const { editingRecord, setEditingRecord } = props;
                    const { translations } = editingRecord;
                    return (
                        <Input type='text' value={translations[l.Code]} 
                            onChange={(e) => { //NOSONAR
                                let _translations = { ...translations };
                                _translations[l.Code] = e.target.value;
                                setEditingRecord({
                                    ...editingRecord, translations: _translations
                                });
                            }}
                            className={'ant-input'} />
                    )
                }
            }
        }))),
        //{
        //    text: 'text.translation',
        //    field: 'translation',
        //    type: COLUMN_TYPE.TEXT,
        //},
        {
            type: COLUMN_TYPE.ACTION,
            width: 80,
        }
    ] : null;

    const importData = (file, onSuccess) => {
        const windowKey = 'importWnd';

        TranslationImport(file, listUpdateEvent, (response) => {
            if (response && response.Data) {
                dispatch(openPopup({
                    component: ImportTranslation,
                    windowKey,
                    title: "Import",
                    type: PopupTypes.Popup,
                    //className: popupClassName,
                    fullScreen: false,
                    bodyProps: { windowKey, fileData: response.Data, salesNetworkLanguage }
                }));
            }
        })
    }

    if (salesNetworkLanguage && columns) {
        return (
            <>
                
                <ListView
                    isSimple
                    search={{ field: 'search' }}
                    listName={'translations_' + props.network}
                    columnConfig={columns}
                    filters={[
                        { name: 'show_empty', type: 'checkbox', text: 'text.keys_with_missing_translations', align: 'left' },
                        { type: 'separator', align: 'left' },
                        { name: 'export', type: 'button', text: 'Export', onClick: TranslationExport, icon: 'download', align: 'right' },
                        { name: 'import', type: 'uploader', text: 'Import', customRequest: importData, icon: 'upload', align: 'right' }
                    ]}
                    apiUrl={ApiUrl.SalesNetworkUITranslationsList}
                    inlineUpdateUrl={ApiUrl.SalesNetworkUITranslationsUpdate}
                    editable
                    //deleteUrl={ApiUrl.SalesNetworkUITranslationsDelete}
                    //detailUrl={Project.getPageUrl(AppPages.TranslationsDetails)}
                    listUpdateEvent={EVENT_NAME.SALES_NETWORK_UI_TRANSLATIONS_LIST_UPDATE}
                    nameField="code"
                    skipNewButton
                    noPaging
                /> 
            </>
        );
    }
    return <></>;
}

export default connect(state => ({ network: state.network }))(Translations);