import { Spin } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, useNavigate, Routes } from 'react-router-dom';
import { Ajax } from './components/Ajax';
import Layout from './components/Layout';
import { menuItems } from './components/Navigation/NavigationMenu';
import { Administrator } from './pages/Administrator';
import AdministratorDetails from './pages/Administrator/detail';
import Login from './pages/Login';
import LoginRedirect from './pages/LoginRedirect';
import Logout from './pages/Logout';
import Product from './pages/Products';
import SalesNetwork from './pages/SalesNetworks';
import SalesNetworkConfig from './pages/SalesNetworkConfig';
import SalesNetworkDetails from './pages/SalesNetworks/detail';
import Statistics from './pages/Statistics';
import ProductDetails from './pages/Products/detail';
import ProductSalesNetwork from './pages/ProductSalesNetworks';
import ProductSalesNetworkDetails from './pages/ProductSalesNetworks/detail';
import Tarif from './pages/Tarif';
import TarifDetails from './pages/Tarif/detail';
import Translations from './pages/Translations';
import UITranslations from './pages/UITranslations';
import UITranslationDetails from './pages/UITranslations/detail';
import Faq from './pages/Faq';
import FaqDetails from './pages/Faq/detail';
import VersionDetails from './pages/Version/detail';
import Version from './pages/Version';

import { Template, Template2 } from './pages/Template';
import { ApiUrl, AppPages, StoreKeys } from './project/Defines';
import { Project } from './project/Project';
import { changeNetwork, setNetworks, storageChange } from './store/actions';
import LogoutRedirect from './pages/LogoutRedirect';

const routings = [
    { page: AppPages.Login, component: Login },
    { page: AppPages.Logout, component: Logout },
    { page: AppPages.Template, component: Template },
    { page: AppPages.Administrator, component: Administrator },
    { page: AppPages.AdministratorDetails, component: AdministratorDetails, routeParams: ':id' },
    { page: AppPages.ProductDetails, component: ProductDetails, routeParams: [':type', ':id'] },
    { page: AppPages.Product, component: Product, routeParams: ':type' },
    { page: AppPages.ProductSalesNetworkDetails, component: ProductSalesNetworkDetails, routeParams: [':type', ':id'] },
    { page: AppPages.ProductSalesNetwork, component: ProductSalesNetwork, routeParams: ':type' },

    { page: AppPages.SalesNetworkDetails, component: SalesNetworkDetails, routeParams: [':id'] },
    { page: AppPages.SalesNetwork, component: SalesNetwork },
    { page: AppPages.Statistics, component: Statistics },

    { page: AppPages.SalesNetworkConfig, component: SalesNetworkConfig },

    { page: AppPages.TarifDetails, component: TarifDetails, routeParams: [':id'] },
    { page: AppPages.Tarif, component: Tarif},

    { page: AppPages.Translations, component: Translations },

    { page: AppPages.UITranslationDetails, component: UITranslationDetails, routeParams: [':id'] },
    { page: AppPages.UITranslations, component: UITranslations },

    { page: AppPages.FaqDetails, component: FaqDetails, routeParams: [':id'] },
    { page: AppPages.Faq, component: Faq },

    { page: AppPages.VersionDetails, component: VersionDetails, routeParams: [':id'] },
    { page: AppPages.Version, component: Version }
];

/**
*  the Base component that also manages routing
 * @module App
 */
function App(props) {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    //const history = useHistory();
    const navigate = useNavigate ();
    const { role, isLoggedIn, storageChange, /*setNetworks,*/ networks, changeNetwork, network } = props;// NOSONAR
    const loading = <div style={{ "margin": "25% auto" }}><Spin size="large" /></div>;
    let subItem;
    const homeRedirectItem = menuItems.find(i => (i.to && Project.hasRoleAccess(role, i.to))
        || (i.subMenu && i.subMenu.some(sub_item => {
            const hasAccess = Project.hasRoleAccess(role, sub_item.to);
            if (hasAccess) {
                subItem = sub_item;
            }
            return hasAccess;
        }))
    );
    
    const homeRedirect = subItem ? Project.getPageUrl(subItem.to, subItem.routeParams) : Project.getPageUrl(homeRedirectItem ? homeRedirectItem.to : '');// NOSONAR
    const [showPages, setShowPages] = useState(false);
    useEffect(() => {
        setIsFirstLoad(false);
        const handler = e => {
            const trackingKeys = [StoreKeys.UserToken, StoreKeys.UserName, StoreKeys.LoggedIn, StoreKeys.ListStates, StoreKeys.Culture, StoreKeys.Role, StoreKeys.Network, StoreKeys.ExpireTime];
            if (trackingKeys.includes(e.key)) {
                storageChange();
            }
        };

        setTimeout(() => {
            if (isLoggedIn) {
                Ajax.post({
                    url: ApiUrl.Check,
                    data: {},
                    success: function (response) {
                        if (response) {
                            setShowPages(true);
                        }
                    }
                })
            } else {
                setShowPages(true);
            }
        }, 100);

        window.addEventListener('storage', handler);
        return () => { window.removeEventListener('storage', handler) }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isFirstLoad && role) {
            //history.push(Project.getPageUrl(AppPages.Home));
            navigate(`${Project.getPageUrl(AppPages.Home)}`);
        }
    }, [role]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isFirstLoad) {
            if (networks && networks.length) {
                if (!networks.find(n => n.value === network)) {
                    changeNetwork(networks[0].value);
                }
            } else if (network) {
                changeNetwork(null);
            }
        }
    }, [networks]);// eslint-disable-line react-hooks/exhaustive-deps

    if (!isLoggedIn) {
        return <Suspense fallback={loading}>
            <Layout>
                <Routes>
                    <Route
                        path="/loginredirect/:code"
                        element={<LoginRedirect />}
                    />
                    <Route
                        path="/login"
                        element={<Login />}
                    />
                    <Route
                        path="/logout"
                        element={<Logout />}
                    />
                    <Route 
                        path='/template' 
                        element={<Template />} 
                    />
                    <Route 
                        path='/templatedetails' 
                        element={<Template2 />}
                    />
                    <Route 
                        path="*"
                        element={<Navigate replace={true} to={Project.getPageUrl(AppPages.Login, "", { redirectUrl: window.location.pathname })} />}>
                    </Route>
                </Routes>
            </Layout>
        </Suspense>
    }

    if (!showPages)
        return <>{loading}</>;

    return <Suspense fallback={loading}>
        <Layout>
            <Routes>
                <Route 
                    exact 
                    path='/' 
                    element={(homeRedirect && <Navigate to={homeRedirect} />) || (<Navigate to={Project.getPageUrl(AppPages.Login)} />)} 
                >
                </Route>
                <Route
                        path="/logoutredirect"
                        element={<LogoutRedirect />}
                    />

                <Route 
                    path="/login" 
                    element={(homeRedirect && <Navigate to={homeRedirect} />) || (<Navigate to={Project.getPageUrl(AppPages.Login)} />)}
                >
                </Route>
                {routings.filter(r => {
                    return r.pages ? r.pages.some(p => Project.hasRoleAccess(role, p)) : Project.hasRoleAccess(role, r.page)
                })
                    .map(r => {
                        let El = r.component;
                        return <Route 
                            key={r.pages ? r.pages.join() : r.page} 
                            path={r.pages ? r.pages.map(p => Project.getPageUrl(p.page || p, p.routeParams)) : Project.getPageUrl(r.page, r.routeParams)} 
                            //component={r.component}
                            element={<El />}
                        />
                    }
                    )}
                <Route path="*"
                    //component={NotFound}
                    element={<Navigate to='/' />}
                    >
                </Route>

            </Routes>
        </Layout>
    </Suspense>;
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, role: state.role, network: state.network, networks: state.networks }),
    dispatch => ({
        storageChange: (key) => dispatch(storageChange()),
        setNetworks: (networks) => dispatch(setNetworks(networks)),
        changeNetwork: (network) => dispatch(changeNetwork(network))
    }))(App);
