import React, { useCallback, /*useState,*/ useEffect, useRef } from 'react';
import { /*message,*/ notification } from 'antd';
import { AppPages, ApiUrl } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { Ajax } from '../components/Ajax';
import { connect } from 'react-redux';
import i18n from '../Shared/Translation/i18n';
import { useNavigate } from "react-router-dom";


function getParams() {
    return new URLSearchParams(window.location.search);
}

/**
 * the Login Component where user authorization occurs
 * @exports Login
 */
function Login(props) {
    const { isLoggedIn } = props;
    const query = getParams();
    const redirectUrl = query.get("redirectUrl") || "";
    const ref = useRef(false);

    let navigate = useNavigate();

    useEffect(() => {
        ref.current = true;
        return () => {
            ref.current = false;
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoggedIn) {
            if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
                navigate(`${redirectUrl}`);
            }
            else {
                navigate(`${Project.getPageUrl(AppPages.Home)}`);
            }

        }
        return () => { }
    }, [isLoggedIn, navigate, redirectUrl]);

    const doAction = useCallback((url, isInternal = true) => {
        if (ref.current) {
            Ajax.post({
                url: url,
                data: { isInternal: isInternal },
                success: function (response) {
                    if (response && response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    } else {
                        notification.warning({
                            description: 'Please check sso configuration',
                            placement: 'top',
                            duration: 3
                        });
                    }
                }
            })
        }
    }, [])

    const doConnectAzureAd = useCallback(() => {
        doAction(ApiUrl.Connect, true);
    }, [doAction])

    const doConnectGroup = useCallback(() => {
        doAction(ApiUrl.Connect, false);
    }, [doAction])


    const doAutologin = useCallback(() => {
        navigate('/loginredirect/test');
    }, [navigate]);

    if (!props.isLoggedIn) {
        return <>
            <view intro="">
                <login>
                    <cont style={{width: 600}}>
                        <wrap id="container" multiple="multiple">
                            <info>
                                <text className="info"><b>{i18n('text.sign_in')}</b></text>
                                <text className="info"><b>{i18n('text.use_your_account')}</b></text>
                            </info>
                            <form defaultbutton="[command='login']" autoComplete="off">
                                <toolbar none="">
                                <button className="button large right extra accent white" type="button" command="login" onClick={doConnectGroup}><text>{i18n('Connect to Atlantic')}</text></button>
                                <button className="button large right extra accent white" type="button" command="login" onClick={doConnectAzureAd}><text>{i18n('Connect to Azure AD')}</text></button>
                                    {process.env.REACT_APP_ENABLE_AUTO_LOGIN && 1 > 2 && <button className="button large right extra accent white" type="button" command="login" onClick={doAutologin}><text>{i18n('Superadmin')}</text></button>}
                                </toolbar>
                            </form>
                        </wrap>
                    </cont>
                </login>
            </view>
        </>;
    } else {
        return <></>;
    }
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn }), null)(Login);