import React from 'react';

import { Project } from '../../project/Project';
import { ApiUrl, AppPages } from '../../project/Defines';

import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { EVENT_NAME } from '../../project/utilities';

export default function Version(props) {
    const columns = [
        {
            text: 'text.version',
            field: 'number',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.comments',
            field: 'comment',
            render: (text, record) => {
                const _text = text.indexOf('\n') < 0 ? text : text.slice(0, text.indexOf('\n'));
                return <wrap>
                    <text>{_text}</text>
                </wrap>
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION
        }
        
    ];

    return (
            <ListView
                columnConfig={columns}
                // dataSource={[]}
                apiUrl={ApiUrl.VersionList}
                deleteUrl={ApiUrl.VersionDelete}
                detailUrl={Project.getPageUrl(AppPages.VersionDetails)}
                listUpdateEvent={EVENT_NAME.VERSION_LIST_UPDATE}
                nameField='number'
               
            />
    );
}
