import React from 'react';
import { ApiUrl, AppPages } from '../../project/Defines';
import { EVENT_NAME } from '~/project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { Project } from '~/project/Project';

export function Administrator(props) {

    const columns = [
        {
            text: 'text.email',
            field: 'email',
            //width: 70,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.first_name',
            field: 'firstName',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.last_name',
            field: 'lastName',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.roles',
            field: 'role_names',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                if (record.role_names) {
                    return (
                        <wrap><group >
                            {
                                record.role_names.map((role, index) => {
                                    return (
                                        <badge filled="" accent="" button="" key={index} // NOSONAR
                                        >
                                            <text>{role}</text>
                                        </badge>
                                    )
                                })
                            }
                        </group></wrap>
                    )
                }
            },
        },
        {
            text: 'text.networks',
            field: 'network_names',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                if (record.network_names) {
                    return (
                        <wrap><group>
                            {
                                record.network_names.map((network, index) => {
                                    return (network &&
                                        <badge filled="" accent="" button="" key={index} // NOSONAR
                                        >
                                            <text>{network}</text>
                                        </badge>
                                    )
                                })
                            }
                        </group></wrap>
                    )
                }
            },
        },
        {
            type: COLUMN_TYPE.ACTION
        }
    ];

    return (
        <ListView
            listName={'admimnistrator'}
            columnConfig={columns}
            nameField='email'
            apiUrl={ApiUrl.AdministratorList}
            deleteUrl={ApiUrl.AdministratorDelete}
            detailUrl={Project.getPageUrl(AppPages.AdministratorDetails)}
            listUpdateEvent={EVENT_NAME.ADMINISTRATORS_LIST_UPDATE}
        />
    );
}