import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EVENT_NAME, dispatchCustomEvent } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { Project } from '../../project/Project';

const listUpdateEvent = EVENT_NAME.PRODUCT_LIST_UPDATE;

export default function ImportExtendedInfoDetail(props) {// NOSONAR
    const { fileData, fileType } = props;
    const { type } = useParams();

    const columns = [
        {
            text: 'brand',
            field: 'marque',
            type: COLUMN_TYPE.TEXT,
            width: 110,
            fixed: 'left',
        },
        {
            text: 'text.sales_reference',
            field: 'salesreference',
            type: COLUMN_TYPE.TEXT,
            width: 150,
            fixed: 'left',
        },
        {
            text: 'text.serie',
            field: 'serie',
            type: COLUMN_TYPE.TEXT,
            width: 150,
            fixed: 'left',
        },
        (fileType === "exchange") && {
            text: 'TEnSec',
            field: 'tEnSec',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType === "exchange") && {
            text: 'TEnPrim',
            field: 'tEnPrim',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType === "exchange") && {
            text: 'TSorSec',
            field: 'tSorSec',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType === "exchange") && {
            text: 'debitPrimaire',
            field: 'debitPrimaire',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType === "exchange") && {
            text: 'TSorPrim',
            field: 'tSorPrim',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType === "exchange") && {
            text: 'puissancePrimaire',
            field: 'puissancePrimaire',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType === "exchange") && {
            text: 'PressionServicePrimaire',
            field: 'PressionServicePrimaire',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType === "exchange") && {
            text: 'HMTPrimaire',
            field: 'HMTPrimaire',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType === "exchange") && {
            text: 'debitSecondaire',
            field: 'debitSecondaire',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType === "exchange") && {
            text: 'HMTSecondaire',
            field: 'HMTSecondaire',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType === "exchange") && {
            text: 'PressionServiceSecondaire',
            field: 'PressionServiceSecondaire',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType !== "exchange") && {
            text: 'TEFS',
            field: 'TEFS',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType !== "exchange") && {
            text: 'TECS',
            field: 'TECS',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType !== "exchange") && (fileType === "stockprep" || fileType === "stocktint") && {
            text: 'TPRIM',
            field: 'TPRIM',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType !== "exchange") && (fileType === "stockprep" || fileType === "stocktint") && {
            text: 'debit',
            field: 'debit',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType !== "exchange") && {
            text: 'puissance',
            field: 'puissance',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        (fileType !== "exchange") && (fileType === "stockprep" || fileType === "stocktint") && {
            text: 'pdc',
            field: 'pdc',
            type: COLUMN_TYPE.TEXT,
            width: 150
        },
        Project.getImportActionColumn(COLUMN_TYPE.TEXT, (fileType === "exchange") && "right"),
        Project.getImportMessageColumn(COLUMN_TYPE.TEXT, (fileType === "exchange") && "right")
    ]

    useEffect(() => {
        dispatchCustomEvent(listUpdateEvent);
    }, [type]);

    return (
        <ListView
            dataSource={fileData || []}
            columnConfig={columns}
            rowClassName={Project.getRecordActionClass}
            skipEdit
            nameField='sku'
            skipNewButton
            className='scroll_fix'
        />
    );
}