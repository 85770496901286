import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { message, Upload } from 'antd';

import { Ajax } from '../../components/Ajax';
import { PopupTypes } from '../../components/Popup';

import { Project } from '../../project/Project';
import { useCustomEvent, EVENT_NAME } from '../../project/utilities';
import { ApiUrl, AppPages } from '../../project/Defines';

import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { hideLoader, showLoader } from '../../Shared/Loader';

import ImportFaq from './importDetail';
import { FaqImport, FaqExport } from '../Faq/actions/faqAction'

import { openPopup } from '../../store/actions';

import { connect, useDispatch } from 'react-redux';

const faqLoader = 'FAQ_LOADER';

function Faq(props) {
    const { type } = useParams();
    const dispatch = useDispatch();
    const [rowIndex, setRowIndex] = useState();
    const [disabledButtonUp, setDisabledButtonUp] = useState(false);
    const [disabledButtonDown, setDisabledButtonDown] = useState(false);
    const [data, setData] = useState([]);
    const [curentRecord, setCurentRecord] = useState();

    const listUpdateEvent = EVENT_NAME.FAQ_LIST_UPDATE;

    const columns = [
        {
            text: 'text.question',
            field: 'question',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.answer',
            field: 'answer',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION
        }
    ];

    const importData = (file, onSuccess) => {
        const windowKey = 'importWnd';

        FaqImport(file, listUpdateEvent, (response) => {
            if (response && response.Data) {
                dispatch(openPopup({
                    component: ImportFaq,
                    windowKey,
                    title: "Import",
                    type: PopupTypes.Popup,
                    //className: popupClassName,
                    fullScreen: false,
                    bodyProps: { windowKey, fileData: response.Data }
                }));
            }
        })
    }

    const loadData = useCallback(() => {
        showLoader(faqLoader);

        Ajax.post({
            url: ApiUrl.FaqList, //GetListData
            data: {},
            success: function (response) {
                if (response) {
                    let _data = [...response.Data];
                    let newData = [];

                    for (const element of _data) {
                        const toFindDuplicates = newData.filter((item) => item.ID === element.ID);
                        if (toFindDuplicates.length === 0) {
                            newData.push(element);
                        }
                    }
                    setData(newData);
                }
                hideLoader(faqLoader);
            },
            error: function (response) {
                if (response.message) {
                    message.error(response.message);
                }
                hideLoader(faqLoader);
            }
        })
    }, [setData]);

    useEffect(() => {
        loadData();
    }, [listUpdateEvent, loadData/*, isDeletedRecord*/]);

    useCustomEvent(listUpdateEvent, loadData);

    useEffect(() => {
        switch (rowIndex) {
            case 0:
                setDisabledButtonDown(false);
                setDisabledButtonUp(true);
                break;
            case data && data.length - 1:
                setDisabledButtonUp(false);
                setDisabledButtonDown(true);
                break;

            default:
                setDisabledButtonDown(false);
                setDisabledButtonUp(false);
                break;
        }
    }, [rowIndex, data]) //react-hooks/exhaustive-deps

    const getRecordID = useCallback((rowIndex) => {
        return data[rowIndex].ID;
    }, [data]);

    const onMoveRow = useCallback((action) => {
        if (rowIndex === undefined || rowIndex < 0) {
            return;
        }

        let prevRowIndex;
        let dataRow = {};

        switch (action) {
            case 'up':
                if (rowIndex === 0) {
                    return;
                }

                prevRowIndex = rowIndex - 1;
                dataRow = { id1: getRecordID(rowIndex), id2: getRecordID(prevRowIndex) };
                break;
            case 'down':
                if (rowIndex === data.length - 1) {
                    return;
                }

                prevRowIndex = rowIndex + 1;
                dataRow = { id1: getRecordID(rowIndex), id2: getRecordID(prevRowIndex) };
                break;
            default:
                return;
        }

        Ajax.post({
            url: ApiUrl.UpdateFaqItemPosition,
            data: dataRow,
            success: function (response) {
                if (response && response.HasError) {
                    message.error(response.Message);
                }
                else {
                    const _data = [...data];

                    let data1 = _data.find(i => i.ID === dataRow.id1);
                    let data2 = _data.find(i => i.ID === dataRow.id2);

                    _data[rowIndex] = data2;
                    _data[prevRowIndex] = data1;

                    setData([..._data]);
                    setRowIndex(prevRowIndex);

                }
            },
            error: function (response) {
                message.error(response.Message);
            }
        });
    }, [rowIndex, data, getRecordID]);

    return (
        <>
            <toolbar>
                {(data && data.length !== 0) &&
                    <action left="">
                        <div
                            className='button mini'
                            onClick={() => onMoveRow('up')}
                            disabled={!curentRecord || disabledButtonUp}
                        >
                            <icon>up</icon>
                        </div>
                        <div
                            className='button mini'
                            onClick={() => onMoveRow('down')}
                            disabled={!curentRecord || disabledButtonDown}
                        >
                            <icon>down</icon>
                        </div>
                    </action>
                }
                <action right="">
                    <div
                        className='button primary'
                        title="Export"
                        onClick={FaqExport}
                    >
                        <icon>download</icon><text>Export</text>
                    </div>

                    <Upload id="uploader"
                        customRequest={importData} // NOSONAR
                        showUploadList={false}
                    >
                        <div className='button primary' title="Import"><icon>upload</icon><text>Import</text></div>
                    </Upload>
                </action>
            </toolbar>
            <ListView
                // key={type}
                columnConfig={columns}
                // apiUrl={ApiUrl.FaqList}
                dataSource={data}
                listUpdateEvent={listUpdateEvent}
                deleteUrl={ApiUrl.FaqDelete}
                // onCustomDelete={isNewCall && (rec => setData(d => d.filter(at => at.ID !== rec.ID)))}
                detailUrl={Project.getPageUrl(AppPages.FaqDetails, type)}
                onClick={(record, rowIndex, event) => { // NOSONAR
                    setCurentRecord(record);
                    setRowIndex(rowIndex);
                }}
                rowClassName={(record) => { // NOSONAR
                    return curentRecord && (curentRecord.ID === record.ID) && "highlighted";
                }}
                nameField='order'
                listName={'faq_' + type + '_' + props.network}
            />
        </>
    );
}

export default connect(state => ({ network: state.network }))(Faq);