import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from '../../Shared/Translation/i18n';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { Project } from '~/project/Project';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import DetailView from '../../Shared/DetailView';
import useDetail from '../../Shared/useDetail';
import InputCont from '../../Shared/Form/InputCont';
import { message } from 'antd';


/**
 * ...
 * @exports VersionDetails
 */
export default function VersionDetails(props) {
    const { windowKey, data } = props;
    const [ linesCount, setLinesCount ] = useState();
    const { id } = useParams();
    const navigate = useNavigate();
    
    const listUpdateEvent = EVENT_NAME.VERSION_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, hasChanges, onDetailDelete, /*closePopup,*/ detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : 0 },
        windowKey,
        updateUrl: ApiUrl.VersionUpdate,
        dataLoadUrl: ApiUrl.VersionLoad,
        deleteUrl: ApiUrl.VersionDelete,
        nameField: 'number',
        backPage: AppPages.Version,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.VersionDetails),
        // changingValidation: true,
        validationFields: ["number", "comment"]
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
            dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        }
    }, [setDataItem, detailRef, listUpdateEvent]);

    useEffect(() => {
        if (dataItem) {
            const text = dataItem.comment;
            const lines = text.split("\n");
            const count = lines.length;
            setLinesCount(count);
        }
    }, [dataItem && dataItem.comment]);// eslint-disable-line react-hooks/exhaustive-deps

    const onMessage = useCallback(() => {
        if (linesCount > 29) {
            message.error('Comment can be long up to 30 lines.')
        }
    }, [linesCount]);

    return (<>
        {dataItem && <DetailView
            hasChanges={hasChanges}
            backUrl={Project.getPageUrl(AppPages.Version)}
            title={isNew ? i18n('text.version') : dataItem.version}
        >
            <view >
                    <div className="form_container">
                        <div className="section_group">
                            <div className="sections">
                            <InputCont 
                                label='text.number' 
                                inputProps={{ validation, name: 'number', maxLength: "20", value: dataItem.number, onChange: onChangeData }} 
                            />
                            </div>
                            <div className="sections wide">
                                <InputCont 
                                    label='text.comments' 
                                    inputProps={{ validation, type: 'textarea', name: 'comment', value: dataItem.comment, onChange: onChangeData }} 
                                />
                            </div>
                        </div>
                    </div>
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div 
                                    onClick={(e) => linesCount > 29 ? onMessage() : updateData(updateSuccessCallback)}
                                    className={hasChanges ? ' button primary' : 'button'}
                                    disabled={validation?.isValid ? false : true}>
                                <icon>save</icon>{i18n('text.save')}
                                </div>
                            {dataItem.ID > 0 &&
                                    <div className='button' onClick={(e) => {
                                        onDetailDelete(dataItem, () => { navigate(Project.getPageUrl(AppPages.Version)) })
                                    }}>
                                    <icon>delete</icon>{i18n('text.delete')}
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>
            </view>
        </DetailView>}
    </>);
}





