import { showLoader, hideLoader } from '../../../Shared/Loader';
import { Ajax } from '../../../components/Ajax';
import { ApiUrl } from '../../../project/Defines';
import { message } from 'antd';
import FileSaver from 'file-saver';

const ExportLoaderName = 'EXPORT_LOADER';

export function StatisticsExport(data) {
	showLoader(ExportLoaderName);

	Ajax.download({
		url: ApiUrl.StatisticsExport,
		data: data,
		//autoDownload: false,
		success: (blob) => {
			FileSaver.saveAs(blob, "Statistics.xlsx");
			hideLoader(ExportLoaderName);
		},
		error: () => {
			hideLoader(ExportLoaderName);
            message.error("Error API.");
		}
	});
}