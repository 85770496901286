import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EVENT_NAME, dispatchCustomEvent } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { Project } from '../../project/Project';

const listUpdateEvent = EVENT_NAME.TRANSLATIONS_LIST_UPDATE;

export default function ImportDetail(props) {
    const {fileData} = props;
    const { type } = useParams();

    const columns = [
        {
            text: 'text.sales_networks',
            field: 'salesNetwork',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.order',
            field: 'order',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.language',
            field: 'language',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.question',
            field: 'question',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.answer',
            field: 'answer',
            type: COLUMN_TYPE.TEXT
        },
        Project.getImportActionColumn(COLUMN_TYPE.TEXT),
        Project.getImportMessageColumn(COLUMN_TYPE.TEXT),
    ]
    useEffect(() => {
        dispatchCustomEvent(listUpdateEvent);
    }, [type]);

    return (
        <ListView
            dataSource={fileData || []}
            columnConfig={columns}
            rowClassName={Project.getRecordActionClass}
            //nameField='code'
            skipNewButton
            className='scroll_fix'
            skipEdit
        />
    );
}