import React, { useCallback, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { message, Spin } from 'antd';
import { AppPages, ApiUrl } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { Ajax } from '../components/Ajax';
import { connect } from 'react-redux';
import { login, /*logout,*/ clearListStates } from '../store/actions/index.js';
import i18n from '../Shared/Translation/i18n';

function getParams() {
    return new URLSearchParams(window.location.search);
}

/**
 * the LoginRedirect Component where user authorization occurs
 * @exports LoginRedirect
 */
function LoginRedirect(props) {
    const redirectUrl = "";
    const { code } = useParams();
    const query = getParams();
    const isInternal = query.get("isInternal") || false;
    // const { history } = props;
    const ref = useRef(false);

    let navigate = useNavigate();


    useEffect(() => {
        ref.current = true;
        doLogin();
        return () => {
            ref.current = false;
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const doLogin = useCallback(() => { //NOSONAR
        Ajax.post({
            url: ApiUrl.LoginByCode,
            data: {
                code: code,
                salesnetworkId: 0,
                isInternal: isInternal
                //ReturnUrl: redirectUrl || ""
            },
            success: function (response) {
                if (response && ((response.status && response.status === 200) || response.redirectUrl)) {
                    const { userToken, userName, culture, roles, networks, email, accessToken, logoutUrl } = response;
                    props.dispatch(clearListStates());
                    props.dispatch(login({
                        userToken,
                        userName,
                        culture,
                        roles,
                        role: roles && roles.length > 0 ? roles[0] : '',
                        networks,
                        network: networks && networks.length > 0 ? networks[0] : '',
                        userData: { userName, email, networks, roles, accessToken, logoutUrl }
                    }));
                    if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
                        //history.push(redirectUrl);
                        navigate(redirectUrl);
                    }
                    else {
                        //history.push(Project.getPageUrl(AppPages.Home));
                        navigate(Project.getPageUrl(AppPages.Home));
                    }
                } else {
                    if (response) {
                        message.error(response.title || i18n('message.error_api'))
                    } else {
                        message.error(i18n('message.error_api'))
                    }

                }
            },
            error: function (response) {
                if (response) {
                    message.error(response.message || i18n('message.error_api'))
                } else {
                    message.error(i18n('message.error_api'))
                }

                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                } else {
                    navigate(Project.getPageUrl(AppPages.Home));
                }

            }
        })

    }, [props, /*history*/ navigate, code, isInternal, redirectUrl]);




    return <><div preloader="" style={{ "margin": "25% auto" }}><Spin size="large" /></div></>;
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, networks: state.networks, network: state.network, culture: state.culture }), null)(LoginRedirect);