import React from "react";
import { InputNumber, Tooltip } from 'antd';

import i18n from '../../Shared/Translation/i18n';
import DropDownValueList from "../../Shared/DropDown";
import { Input, InputTypes } from "../../Shared/Input";
import CustomUpload from "../CustomUpload";
import { DatePicker } from "./../DatePicker";

/**
 * function implementing a logic for validation based on given parameters and data
 * @param {any} props contains: data, validationFields
 */
export function validate(props) {// NOSONAR
    const { data, validationFields } = props;

    const validation = { isValid: true };
    for (const element of validationFields) {

        const field = element;
        let fieldName = field.name || field;
        let deepData = data;
        const regex = /\.|\[|\]/;//NOSONAR
        if (fieldName && fieldName.search(regex) !== -1) {
            let deepProps = fieldName.split(regex).filter(p => p !== '');
            for (let i = 0; i < deepProps.length - 1; i++) {
                deepData = deepData[deepProps[i]];
            }
            fieldName = deepProps[deepProps.length - 1];
        }
        let isCurrentValid = true;
        if (field.rules) {
            validation[field.name] = field.rules.map(r => {// NOSONAR
                switch (r.type) {
                    case "custom":
                        if (!r.validator(data)) {
                            isCurrentValid = false;
                            validation.isValid = false;
                        }
                        return !isCurrentValid && { text: (r.message || 'text.required') };
                    case "required":
                        if (!deepData[fieldName] || (!r.skipCheckWhiteSpace && deepData[fieldName] && deepData[fieldName].trim && !deepData[fieldName].trim()) || (deepData[fieldName] && deepData[fieldName].length === 0)) {
                            isCurrentValid = false;
                            validation.isValid = false;
                        }
                        return !isCurrentValid && { text: (r.message || 'text.required') };
                    case "minValue":
                        if (deepData[fieldName] < r.minValue) {
                            isCurrentValid = false;
                            validation.isValid = false;
                        }
                        return !isCurrentValid && { args: [r.minValue], text: (r.message || 'message.must_be_minimum') };
                    case "maxValue":
                        if (deepData[fieldName] > r.maxValue) {
                            isCurrentValid = false;
                            validation.isValid = false;
                        }
                        return !isCurrentValid && { args: [r.maxValue], text: (r.message || 'message.must_be_maximum') };
                    case "email":
                    case "regexp":
                        const regexp = r.regexp || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /*eslint-disable-line no-useless-escape*/ /*NOSONAR*/
                        if (deepData[fieldName] && !regexp.test(deepData[fieldName].toLowerCase())) {
                            isCurrentValid = false;
                            validation.isValid = false;
                        }
                        return !isCurrentValid && { text: (r.message || 'message.should_be_an_email') };
                    case "pass":
                        const reg = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d](?=.*[!\"#$%&'()*+,\-.\/:;<=>?@[\\\]^_`{|}~])[\x21-\x7F]{11,50})$/; /*eslint-disable-line no-useless-escape*/ /*NOSONAR*/
                        if (deepData[fieldName] && !reg.test(deepData[fieldName])) {
                            isCurrentValid = false;
                            validation.isValid = false;
                        }
                        return !isCurrentValid && { text: (r.message || 'message.should_be_an_pass') };
                    case "number":
                        if (isNaN(deepData[fieldName])) {
                            isCurrentValid = false;
                            validation.isValid = false;
                        }
                        return !isCurrentValid && { text: (r.message || 'message.should_be_a_number') };
                    default:
                        return true;
                }
            }).filter(m => m);
        } else {
            if (!deepData[fieldName] || (deepData[fieldName] && deepData[fieldName].trim && !deepData[fieldName].trim()) || (deepData[fieldName] && deepData[fieldName].length === 0)) {
                isCurrentValid = false;
                validation.isValid = false;
            }
            validation[field] = !isCurrentValid && "text.required";
        }
    }
    return validation;
}

/**
 * icon to put next to invalid fields, rendered if there are any messages to show
 * @param {any} props contains: messages, validate
 */
export function InputValidationIcon({ messages, validate }) {
    const message = validate && messages && messages.length &&
        ((messages.map && <>{messages.map((m, i) => <div key={i + m}>{i18n(m.text || m, m.args || [])}</div>)}</>) || i18n(messages)); // NOSONAR
    return (message && <Tooltip title={message}><div className='button micro' ><icon style={{ color: 'tomato' }}>error</icon></div></Tooltip>) || <></>;
}

/**
 * wrapper for elements that have to be validated
 * @param {any} props contains: messages, validate
 */
export function ValidationWrapper({ validate, messages, children, append }) {
    return <div className='input_cont' >
        {!append && children}
        <InputValidationIcon messages={messages} validate={validate} />
        {append && children}
        <i />
    </div>;
}

/**
 * input that has to be validated gets the same  props as input
 * @param {any} props contains:  name,  validation, ...inputProps
 */
export function ValidationInput({ name, validation, append, inputType, className, skipClassName, ...inputProps }) {
    const messages = validation[name];
    const message = (!validation.isValid && messages && messages.length &&
        ((messages.map && <>{messages.map((m, i) => <div key={i + m}>{i18n(m.text || m, m.args || [])}</div>)}</>) || (i18n(messages)))) || (''); // NOSONAR
    let checkedClassName = !skipClassName && message ? 'input-validation-error' : '';
    let passedClassName = className || ''

    let input;
    switch (inputType) {
        case 'dropdown':
            input = <DropDownValueList className={`${passedClassName} ${checkedClassName}`} name={name} {...inputProps} />;
            break;
        case 'textarea':
            input = <Input inputType={InputTypes.TEXTAREA} className={`${passedClassName} ${checkedClassName}`} name={name} {...inputProps} />;
            break;
        case 'upload':
            input = <CustomUpload className={`${passedClassName} ${checkedClassName}`} name={name} {...inputProps} />;
            break;
        case 'number':
            input = <InputNumber className={`${passedClassName} ${checkedClassName}`} name={name} {...inputProps} />;
            break;
        case 'date':
            input = <DatePicker className={`${passedClassName} ${checkedClassName}`} name={name} {...inputProps} />;
            break;
        default:
            input = <Input className={`${passedClassName} ${checkedClassName}`} name={name} {...inputProps} />;
            break;
    }
    return <Tooltip title={message}>{input}</Tooltip>;
}

export function ValidationMultiSelectValueList(props) {
    return <ValidationDropDownValueList {...props} mode='multiple' />;
}

/**
 * DropDownValueList that has to be validated gets the same  props as DropDownValueList
 * @param {any} props contains:  name,  validation, ...inputProps
 */
export function ValidationDropDownValueList(props) {
    return <ValidationInput inputType='dropdown' {...props} />;
}

export function ValidationMultiColumnDropDown(props) {
    return <ValidationInput inputType='dropdown' {...props} />;
}
/**
 * Validation Upload that has to be validated gets the same  props as CustomUpload
 * @param {any} props contains:  name,  validation, ...inputProps
 */
 export function ValidationUpload(props) {
    return <ValidationInput inputType='upload' name={props.imageField} {...props} />;
}

export function ValidationInputNumber(props) {
    return <ValidationInput inputType='number' {...props} />;
}
