import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Dropdown, Upload } from 'antd';

import { Project } from '../../project/Project';
import { EVENT_NAME } from '../../project/utilities';
import { ApiUrl, AppPages } from '../../project/Defines';
import i18n from '../../Shared/Translation/i18n';

import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';

import { PopupTypes } from '../../components/Popup';

import { getColumns } from './CommonMethods';
import { openPopup } from '../../store/actions';

import ImportProduct from '../../pages/Products/importDetail';
import ImportExtendedInfoDetail from './importExtendedInfoDetail';
import { ProductImport, ProductExtendedImport, ProductExport, ProductExtendedExport, ProductMoteurExport, ProductDownloadMoteurJSON } from './actions/productAction'

const listUpdateEvent = EVENT_NAME.PRODUCT_LIST_UPDATE;

export default function Product(props) {
    const [ visibleExportMenu, setVisibleExportMenu ] = useState(false);
    const [ visibleImportMenu, setVisibleImportMenu ] = useState(false);
    const { type } = useParams();
    const dispatch = useDispatch();

    const columns = [
        {
            text: 'text.sales_reference',
            field: 'sku',
            type: COLUMN_TYPE.TEXT,
            width: 140,
            fixed: 'left',
        },
        {
            text: 'text.label',
            field: 'label',
            type: COLUMN_TYPE.TEXT,
            width: 400,
            fixed: 'left',
        },
        {
            text: 'text.master_code',
            field: 'masterCode',
            type: COLUMN_TYPE.TEXT,
            width: 200,
            //fixed: 'left',
            render: (text, record) => {
                return record.masterCode && <wrap>
                    <text>
                        {record.masterCode}/{record.masterBrand}/{record.masterProductType}
                    </text>
                </wrap>
            },
        },
        {
            text: 'text.product_type',
            field: 'type',
            type: COLUMN_TYPE.TEXT,
            width: 110,
            //fixed: 'left',
        },
        {
            text: 'text.brand',
            field: 'brand',
            type: COLUMN_TYPE.TEXT,
            width: 120,
            //fixed: 'left',
        },
        {
            text: 'text.is_accessory',
            field: 'isAccessory',
            render: (text, record) => {
                return record.isAccessory && <wrap>
                    <item>
                        <icon>done</icon>
                    </item>
                </wrap>
            },
            type: COLUMN_TYPE.TEXT,
            width: 100,
            //fixed: 'left',
        },
        {
            text: 'text.preview_url',
            field: 'previewUrl',
            type: COLUMN_TYPE.TEXT,
            width: 400
        },

        // {
        //     text: 'text.description',
        //     field: 'description',
        //     type: COLUMN_TYPE.TEXT,
        //     width: 150,
        //     fixed: 'left',
        // },
        // {
        //     text: 'text.obsolete',
        //     field: 'obsolete',
        //     render: (text, record) => {
        //         return record.obsolete && <wrap>
        //                                         <item>
        //                                             <icon>done</icon>
        //                                         </item>
        //                                     </wrap>
        //     },
        //     type: COLUMN_TYPE.TEXT,
        //     width: 80,
        //     fixed: 'left',
        // },
        ...getColumns(type, i18n),
        {
            type: COLUMN_TYPE.ACTION,
            fixed: "right"
        },
    ]

    const importData = (file, onSuccess) => {
        const windowKey = 'importWnd';

        ProductImport(file, listUpdateEvent, (response) => {
            if (response && response.Data) {
                dispatch(openPopup({
                    component: ImportProduct,
                    windowKey,
                    title: "Import",
                    type: PopupTypes.Popup,
                    fullScreen: false,
                    bodyProps: { windowKey, fileData: response.Data }
                }));
            }
        });
    }

    const importExtendedData = (file, onSuccess) => {
        const windowKey = 'importExtendedWnd';

        ProductExtendedImport(file, listUpdateEvent, (response) => {
            if (response && response.Data) {
                dispatch(openPopup({
                    component: ImportExtendedInfoDetail,
                    windowKey,
                    title: "Import",
                    type: PopupTypes.Popup,
                    fullScreen: false,
                    bodyProps: { windowKey, fileData: response.Data, fileType: response.ProductType }
                }));
            }
        });
    }

    const handleVisibleChange = (isExport) => {
        isExport ? setVisibleExportMenu(!visibleExportMenu) : setVisibleImportMenu(!visibleImportMenu);
        // setVisible(!visible);
    };

    const handleMenuClick = (isExport) => {
        isExport ? setVisibleExportMenu(false) : setVisibleImportMenu(false);
        // setVisible(false);
    };


    const overlayExport = <div
        onClick={() => handleMenuClick(true)}
        className="list_menu ant-dropdown-menu">
        <ul>
            <li onClick={ProductExport}><a><icon></icon><div title="Export Product"><text>Product</text></div></a></li> {/*eslint-disable-line jsx-a11y/anchor-is-valid*/}
            <li className="separator"></li>
            <li onClick={ProductExtendedExport}><a><icon></icon><div title="Export Extended Info"><text>Extended Info</text></div></a></li> {/*eslint-disable-line jsx-a11y/anchor-is-valid*/}
            <li className="separator"></li>
            <li onClick={ProductMoteurExport}><a><icon></icon><div title="Update Moteur DB"><text>Update Moteur DB</text></div></a></li> {/*eslint-disable-line jsx-a11y/anchor-is-valid*/}
            <li className="separator"></li>
            <li onClick={ProductDownloadMoteurJSON}><a><icon></icon><div title="Download Moteur JSON"><text>Download Moteur JSON</text></div></a></li> {/*eslint-disable-line jsx-a11y/anchor-is-valid*/}
        </ul>
    </div>;

    const overlayImport = <div
            onClick={() => handleMenuClick()}
            className="list_menu ant-dropdown-menu">
        <ul>
            <li>
                <label
                    htmlFor="_empty"
                // onClick={(e) => { e.preventDefault() }}
                >
                    <icon></icon>
                    <Upload id="uploaderProduct" customRequest={importData} // NOSONAR
                        showUploadList={false}>
                        <div title="Import Product"><text>Product</text></div>
                    </Upload>
                </label>
            </li>
            <li className="separator"></li>
            <li>
                <label
                    htmlFor="_empty"
                //onClick={(e) => { e.preventDefault() }}
                >
                    <icon></icon>
                    <Upload id="uploaderExtendedInfo"
                        customRequest={importExtendedData} // NOSONAR
                        showUploadList={false}>
                        <div title="Import Extended Info"><text>Extended Info</text></div>
                    </Upload>
                </label>
            </li>
        </ul>
    </div>;

    return (
        <>
            {1 === 2 &&
                <toolbar>
                    <action right="">
                        <Dropdown
                            className="ant-dropdown-hidden"
                            trigger={['click']}
                            overlay={overlayExport}
                            placement="topRight">
                            <div className="button primary">
                                <icon data-tip={''}>download</icon>
                                <text>{'Export All'}</text>
                            </div>
                        </Dropdown>
                        <Dropdown
                            // onClick={() => { setVisible(true)}}
                            className=""
                            trigger={['click']}
                            overlay={overlayImport}
                            placement="topRight"
                            // onVisibleChange={() => handleVisibleChange}
                            // visible={visible}
                        >
                            <div className="button primary">
                                <icon data-tip={''}>upload</icon>
                                <text>{'Import All'}</text>
                            </div>
                        </Dropdown>
                    </action>
                </toolbar>

            }
            <ListView
                isSimple
                key={type}
                columnConfig={columns}
                filters={[
                    { name: 'search', type: 'search', align: 'left' },
                    { type: 'separator', align: 'left' },
                    {
                        name: 'export', type: 'control', component: <Dropdown
                            onClick={() => setVisibleExportMenu(true)} //NOSONAR
                            className=""
                            trigger={['click']}
                            overlay={overlayExport}
                            placement="topRight"
                            onVisibleChange={() => handleVisibleChange(true)} //NOSONAR
                            visible={visibleExportMenu}
                        >
                            <div className="button primary">
                                <icon data-tip={''}>download</icon>
                                <text>{'Export All'}</text>
                            </div>
                        </Dropdown>, align: 'right'
                    },
                    {
                        name: 'import', type: 'control', component: <Dropdown
                            onClick={() => setVisibleImportMenu(true)} //NOSONAR
                            className=""
                            trigger={['click']}
                            overlay={overlayImport}
                            placement="topRight"
                            onVisibleChange={() => handleVisibleChange} //NOSONAR
                            visible={visibleImportMenu}
                        >
                            <div className="button primary">
                                <icon data-tip={''}>upload</icon>
                                <text>{'Import All'}</text>
                            </div>
                        </Dropdown>, align: 'right'
                    }
                ]}
                apiUrl={ApiUrl.ProductList}
                deleteUrl={ApiUrl.ProductDelete}
                detailUrl={Project.getPageUrl(AppPages.ProductDetails, type)}
                listUpdateEvent={EVENT_NAME.PRODUCT_LIST_UPDATE}
                className='scroll_fix'
                staticFilter={{ type }}
                nameField='sku'
                //search={{ field: 'search' }}
                listName={'product_' + type}
            />
        </>
    );
}
