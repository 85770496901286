import React, { useCallback } from 'react';
import i18n from '../../Shared/Translation/i18n';
import InputCont from '../../Shared/Form/InputCont';
import useDetail from '../../Shared/useDetail';
import { ApiUrl } from '../../project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import { Ajax } from '../../components/Ajax';


export default function Languages(props) {
    const reloadEvent = EVENT_NAME.SALES_NETWORK_LANGUAGE_LIST_UPDATE;

    const { dataItem, /*setDataItem,*/ updateData, /*closePopup,*/ detailRef, /*onChangeData,*/ validation } = useDetail({
        data: {},
        updateUrl: ApiUrl.SalesNetworkCurrentLangugesUpdate,
        dataLoadUrl: ApiUrl.SalesNetworkLanguagesLoad,
        reloadEvent
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            dispatchCustomEvent(reloadEvent);
        }
        return true;
    }, [detailRef, reloadEvent]);

    const updateDefaultLanguage = (e) => {
        Ajax.post({
            url: ApiUrl.SalesNetworkDefaultLangugeUpdate,
            data: { languageId: e },
            success: function (response) {
                updateSuccessCallback();
            },
        })
    }

    const dataChange = (e) => {

        if (e.target.name !== dataItem.data.filter(i => i.isDefault).map(i => i.label)[0]) {
            let item = dataItem.data.filter(i => i.label === e.target.name).map(i => { return { ID: i.ID }; });
            updateData(updateSuccessCallback, null, { overrideData: { languageId: item[0].ID, isNew: e.target.checked } });
        }
    };

    return <div className="section_group">
                {/* <div className="sections extra"> */}
                    <div className="sections extra">
                        <div className="form_fields">
                            <div className="section_title">
                                <text>{i18n("text.language")}</text>
                            </div>
                            <div className="form_separator"></div>
                        </div>
                    </div>
                    {dataItem && dataItem.data && <>
                        <div className="sections">
                            <InputCont label="text.default_language"
                                inputProps={{
                                    validation,
                                    name: 'chengeDefault',
                                    type: 'select',
                                    valuelist: dataItem.data.filter(i => i.isSelected).map((item) => { return { value: item.ID, text: item.label } }),
                                    onChange: updateDefaultLanguage,
                                    defaultValue: dataItem.data.filter(i => i.isDefault).map(i => i.label)[0]
                                }} 
                            />
                        </div>
                        <div className="sections extra">
                            <div className="form_fields">
                                <cont>
                                    {
                                        dataItem.data.map(item => (
                                            <InputCont key={item.label} label={item.label} inputProps={{ validation, name: item.label, type: 'checkbox', value: item.ID, onChange: dataChange, checked: item.isSelected }} />
                                        ))
                                    }
                                </cont>
                            </div>
                        </div>
                    </>}
                {/* </div> */}
    </div>
}