import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { message, Upload } from 'antd';
import moment from 'moment';

import { Ajax } from '../../components/Ajax';
import { PopupTypes } from '../../components/Popup';

import { Project } from '../../project/Project';
import { ApiUrl, AppPages } from '../../project/Defines';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';

import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { showLoader, hideLoader } from '../../Shared/Loader';

import { openPopup } from '../../store/actions';
import ImportSalesNetworkProduct from '../../pages/ProductSalesNetworks/importDetail';
import { ProductImport, ProductExport } from '../ProductSalesNetworks/actions/productSalesNetworkAction';

const listUpdateEvent = EVENT_NAME.PRODUCT_SALES_NETWORK_LIST_UPDATE;
const loaderName = 'UPDATE_SALES_NATEWORK_LOADER';

function ProductSalesNetwork(props) {
    const { type } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();

    const onSelect = (record) => event => {
        showLoader(loaderName);
        Ajax.get({
            url: ApiUrl.ProductSalesNetworkActiveUpdate,
            data: {id: record.ID, product_id: record.product_id, salesnetwork_id: record.salesnetwork_id, active: event.target.checked },
            success: (response) => {
                if (response.HasError) {
                    message.error(response.Message);
                    hideLoader(loaderName);
                }
                else {
                    dispatchCustomEvent(listUpdateEvent);
                    hideLoader(loaderName);
                }
            }
        });
    };

    const _onSelect = (record) => {
        showLoader(loaderName);
        Ajax.get({
            url: ApiUrl.ProductSalesNetworkActiveUpdate,
            data: {id: record.ID, product_id: record.product_id, salesnetwork_id: record.salesnetwork_id, active: true },
            success: (response) => {
                if (response.HasError) {
                    message.error(response.Message);
                    hideLoader(loaderName);
                }
                else {
                    dispatchCustomEvent(listUpdateEvent, response );
                    hideLoader(loaderName);
                    console.log(response.ID);
                    if (response.ID) {
                        location.pathname = '';
                    }
                }
            }
        });
    };

    let curentDate = moment().format();

    const columns = [
        {
            text: 'text.active',
            type: COLUMN_TYPE.CUSTOM,
            width: 70,
            render: (record) => {// NOSONAR
                return (
                    record.ID ? <wrap>
                        <label checkbox=""><input
                            checked={record.ID !== "" && record.product_id > 0 && record.salesnetwork_id > 0 && record.active ? true : false}
                            onChange={onSelect(record)}
                            type="checkbox"
                            readOnly
                        /><box><check></check></box></label>
                    </wrap> : ""
                )
            }
        },
        {
            text: 'text.tarif',
            // field: 'isTarif',
            width: 70,
            render: (text, record) => {
                let isTarif = (record.priceCurrent || (record.priceFuture && (curentDate >= record.datePriceFuture))) ? true : false;
                return <>{isTarif && <wrap>
                    <item>
                        <icon>done</icon>
                    </item>
                </wrap>
                    }
                
                </>
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.sales_reference',
            field: 'sku',
            render: (text, record) => {
                return <wrap>
                        {/* <text light="">{record.sku}</text> */}
                        <text>{record.sku}</text>
                    </wrap>
            },
            type: COLUMN_TYPE.TEXT,
            width: 120
        },
        {
            text: 'text.label',
            field: 'default_label',
            // field: 'label',
            type: COLUMN_TYPE.TEXT,
            width: 400
        },
        {
            text: 'text.url_doc',
            field: 'url',
            render: (text, record) => {
                return <wrap><text>{text ? '...' : '-'}</text></wrap>
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
            commands: [
                { command: 'edit', skipCommandForRecord: record => (record.ID <= 0) },
                { command: 'delete', skipCommandForRecord: record => (record.ID <= 0) },
                { command: 'plus', skipCommandForRecord: record => (record.ID > 0), onClick: (record) => _onSelect(record) }
            ],
        },
    ]

    const importData = (file, onSuccess) => {
        const windowKey = 'importWnd';

        ProductImport(file, listUpdateEvent, (response) => {
            if (response && response.Data) {
                dispatch(openPopup({
                    component: ImportSalesNetworkProduct,
                    windowKey,
                    title: "Import",
                    type: PopupTypes.Popup,
                    //className: popupClassName,
                    fullScreen: false,
                    bodyProps: { windowKey, fileData: response.Data }
                }));
            }
        })
    }

    return (
        <>
            { 1===2 &&
            <toolbar>
                <action right="">
                    <div 
                        className='button primary' 
                        title="Export"
                        onClick={ProductExport}
                    >
                        <icon>download</icon><text>Export</text>
                    </div>
        
                    <Upload id="uploader" 
                        customRequest={importData}  // NOSONAR
                        showUploadList={false}
                    >
                        <div className='button primary' title="Import"><icon>upload</icon><text>Import</text></div>
                    </Upload>
                </action>
                </toolbar>}
            <ListView
                isSimple
                key={type}
                listName={'product_sales_network_' + type + '_' + props.network}
                columnConfig={columns}
                apiUrl={ApiUrl.ProductSalesNetworkList}
                listUpdateEvent={EVENT_NAME.PRODUCT_SALES_NETWORK_LIST_UPDATE}
                detailUrl={Project.getPageUrl(AppPages.ProductSalesNetworkDetails, type)}
                deleteUrl={ApiUrl.ProductSalesNetworkDelete}
                nameField='sku'
                className='scroll_fix'
                skipNewButton={true}
                staticFilter={{ type }}
                onBeforeEdit={(record, isNewRecord) => { // NOSONAR
                    return isNewRecord || record.ID > 0;
                }}
                rowClassName={(record) => { // NOSONAR
                    return !(record.priceCurrent || (record.priceFuture && (curentDate >= record.datePriceFuture))) && "highlighted";
                }}
                //search={{ field: 'search' }}
                filters={[
                    { name: 'search', type: 'search', align: 'left' },
                    { type: 'separator', align: 'left' },
                    { name: 'export', type: 'button', text: 'Export', onClick: ProductExport, icon: 'download', align: 'right' },
                    { name: 'import', type: 'uploader', text: 'Import', customRequest: importData, icon: 'upload', align: 'right' }
                ]}
            />
        </>
    );
}

export default connect(state => ({ network: state.network }))(ProductSalesNetwork)