import { showLoader, hideLoader } from '../../../Shared/Loader';
import { Ajax } from '../../../components/Ajax';
import { ApiUrl } from '../../../project/Defines';
import { Project } from '../../../project/Project';
import { message, notification } from 'antd';
import { dispatchCustomEvent } from '../../../project/utilities';
import FileSaver from 'file-saver';

const loaderName = 'PRODUCT_FILE_UPLOAD_LOADER';
const exportLoaderName = 'PRODUCT_FILE_EXPORT_LOADER';

const showNotification = (text, key) => {
    notification.info({
        //message: `Notification`,
        description: text,
        placement: 'top',
        duration: 0,
        key: key
    });
}

export function ProductImport(data, listUpdateEvent, successCallback) {
    showLoader(loaderName);

    Ajax.post({
        url: ApiUrl.ProductImport,
        data: {
            file: data && data.file
        },
        success: (response) => {
            if (!response.HasError) {
                successCallback && successCallback(response);
                dispatchCustomEvent(listUpdateEvent);
                hideLoader(loaderName);
            } else {
                message.error(response.message);
                hideLoader(loaderName);
            }
        }
    });
}

export function ProductExtendedImport(data, listUpdateEvent, successCallback) {
    showLoader(loaderName);

    Ajax.post({
        url: ApiUrl.ProductsExtendedInfo,
        data: {
            file: data && data.file
        },
        success: (response) => {
            if (!response.HasError) {
                successCallback && successCallback(response);
                dispatchCustomEvent(listUpdateEvent);
                hideLoader(loaderName);
            } else {
                message.error((response && response.message) || "API Error");
                hideLoader(loaderName);
            }
        }
    });
}

export function ProductExport() {
    showLoader(exportLoaderName);

    Ajax.download({
        url: ApiUrl.ProductExport,
        data: {},
        //autoDownload: false,
        success: (blob) => {
            FileSaver.saveAs(blob, "Product.xlsx");
            hideLoader(exportLoaderName);
        },
        error: () => {
            hideLoader(exportLoaderName);
        }
    });
}

export function ProductExtendedExport() {
    showLoader(exportLoaderName);

    Ajax.download({
        url: ApiUrl.ProductsExtendedInfoExport,
        data: {},
        //autoDownload: false,
        success: (blob) => {
            FileSaver.saveAs(blob, "ProductExtendedInfos.zip");
            hideLoader(exportLoaderName);
        },
        error: () => {
            hideLoader(exportLoaderName);
        }
    });
}

export function ProductMoteurExport() {
    showLoader(exportLoaderName);

    Ajax.post({ //download({
        url: ApiUrl.MoteurExport,
        data: {},
        //autoDownload: false,
        success: (blob) => {
            //const data = JSON.parse(blob.message)
            if (blob.message && blob.message.indexOf("{") !== -1 && blob.message.indexOf("Ok") !== -1){
                const data = JSON.parse(blob.message);
                showNotification(data["Ok"]);
            } else {
                showNotification(blob.message); //data["Ok"]
            }
            hideLoader(exportLoaderName);
        },
        error: (data) => {
            showNotification(data.message);
            hideLoader(exportLoaderName);
        }
    });
}

export function ProductDownloadMoteurJSON() {
    showLoader(exportLoaderName);

    Ajax.download({
        url: ApiUrl.DownloadMoteurJSON,
        data: {},
        //autoDownload: false,
        success: (blob) => {
            FileSaver.saveAs(blob, "MoteurExport_" + Project.formatFullDateTimeString(new Date()).replace(' ', '_') + ".json");
            hideLoader(exportLoaderName);
        },
        error: (data) => {
            message.error(data.message);
            hideLoader(exportLoaderName);
        }
    });
}