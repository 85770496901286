import { useCallback } from 'react';

const CLONLOGOUT = "CLONLOGOUT"
const CLONTIME = "CLONTIME"

const useCacheData = ({key, loadData, isClearOnLogout, clearTime }) => {
    const setData = useCallback((data) => {
        localStorage.setItem(key, JSON.stringify(data));

        if(isClearOnLogout) {
            let clOnLogoutItems = JSON.parse(localStorage.getItem(CLONLOGOUT));
            if(!clOnLogoutItems) {
                clOnLogoutItems = [];
            }

            clOnLogoutItems.push(key)
            localStorage.setItem(CLONLOGOUT, JSON.stringify(clOnLogoutItems));
        }

        if(clearTime) {
            let clOnTimeItems = JSON.parse(localStorage.getItem(CLONTIME));

            if(!clOnTimeItems) {
                clOnTimeItems = [];
            }

            let date = new Date();
            date.setMinutes(date.getMinutes() + clearTime);

            clOnTimeItems.push({key: key, time: date})
            localStorage.setItem(CLONTIME, JSON.stringify(clOnTimeItems));

            setTimeout(() => {
                const _clOnTimeItems = JSON.parse(localStorage.getItem(CLONTIME));

                if(_clOnTimeItems) {
                    _clOnTimeItems.forEach((obj, index) => {
                        if(obj.key === key) {
                            _clOnTimeItems.splice(index, 1);
                            localStorage.removeItem(obj.key)
                            localStorage.setItem(CLONTIME, JSON.stringify(_clOnTimeItems));
                        }
                    });
                }
            }, clearTime * 1000 * 60);
        }
    }, [clearTime, isClearOnLogout, key])

    const getData = useCallback(() => {
        let data = JSON.parse(localStorage.getItem(key));
        if(clearTime) {
            let _clOnTimeItems = JSON.parse(localStorage.getItem(CLONTIME));
            if(_clOnTimeItems) {
                _clOnTimeItems.forEach((obj, index) => {
                    if(obj.key === key && new Date(obj.time) < new Date()) {
                        _clOnTimeItems.splice(index, 1);
                        localStorage.removeItem(obj.key)
                        localStorage.setItem(CLONTIME, JSON.stringify(_clOnTimeItems));
                        data = null;
                    }
                });
            }
        }

        if(!data) {
            loadData && loadData((data) => {// NOSONAR
                setData(data)
            });
        }

        return data
    }, [loadData, clearTime, key, setData]);

    return { getData, setData }
}

useCacheData.clearOnLogout = () => {
    const clOnLogoutItems = JSON.parse(localStorage.getItem(CLONLOGOUT));
    if(clOnLogoutItems) {
        clOnLogoutItems.forEach(_key => {
            localStorage.removeItem(_key)
        });
    }

    localStorage.removeItem(CLONLOGOUT);

    let _clOnTimeItems = JSON.parse(localStorage.getItem(CLONTIME));
    if(_clOnTimeItems) {
        _clOnTimeItems.forEach((obj) => {
            localStorage.removeItem(obj.key)
        });

        localStorage.removeItem(CLONTIME);
    }
}

export { useCacheData };