import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import store from 'store';
import { logout } from '../store/actions/index.js';
import { Ajax } from '../components/Ajax';
import { ApiUrl, AppPages, StoreKeys } from '../project/Defines';
import { Project } from '../project/Project.js';

import { useNavigate } from "react-router-dom";

/**
 * the Logout Component
 * @exports Logout
 */
function Logout(props) {
    let navigate = useNavigate();

    useEffect(() => {
        // let history = props.history;
        if (props.isLoggedIn) {
            if (props.userData.logoutUrl) {
                store.remove(StoreKeys.UserToken);
                props.dispatch(logout());
                window.location.href = props.userData.logoutUrl;
            } else {
                Ajax.post({
                    url: ApiUrl.Logout,
                    data: {},
                    success: function (response) {
                        store.remove(StoreKeys.UserToken);
                        props.dispatch(logout());

                        if (response.redirectUrl) {
                            window.location.href = response.redirectUrl;
                        } else {
                            navigate(Project.getPageUrl(AppPages.Login));
                            //history.push(Project.getPageUrl(AppPages.Login));
                        }
                    }
                })
            }
        } else {
            navigate(Project.getPageUrl(AppPages.Login));
            // history.push(Project.getPageUrl(AppPages.Login));
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return <div />;
}

export default connect(state => ({
    isLoggedIn: state.isLoggedIn,
    userData: state.userData
}))(Logout);
