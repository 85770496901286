import /*React,*/ { useCallback, useEffect } from 'react';

import { message } from 'antd';

import { useDispatch } from 'react-redux';
import { /*useHistory,*/ useNavigate } from 'react-router-dom';

import { Ajax } from '../components/Ajax';
import i18n from '../Shared/Translation/i18n';
import { PopupTypes } from '../components/Popup';

import { hideLoader, showLoader } from '../Shared/Loader';

import store from './../store';
import { openPopup } from '../store/actions';

import { Project } from './Project';


/**
 *the list of custom events occuring for specific actions
 */
export const EVENT_NAME = {
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    ADMINISTRATORS_LIST_UPDATE: "ADMINISTRATORS_LIST_UPDATE",
    UPDATE_PRODUCT_TRANSLATIONS: "UPDATE_PRODUCT_TRANSLATIONS",
    UPDATE_PRODUCT_DOCUMENT_TRANSLATIONS: "UPDATE_PRODUCT_DOCUMENT_TRANSLATIONS",
    UPDATE_PRODUCT_DOCUMENT_LIST: "UPDATE_PRODUCT_DOCUMENT_LIST",
    UPDATE_PRODUCT_EXTENDEDINFOS: "UPDATE_PRODUCT_EXTENDEDINFOS",

    PRODUCT_LIST_UPDATE: "PRODUCT_LIST_UPDATE",
    PRODUCT_SALES_NETWORK_LIST_UPDATE: "PRODUCT_SALES_NETWORK_LIST_UPDATE",
    SALES_NETWORK_LIST_UPDATE: "SALES_NETWORK_LIST_UPDATE",
    SALES_NETWORK_LANGUAGE_LIST_UPDATE: "SALES_NETWORK_LANGUAGE_LIST_UPDATE",
    SALES_NETWORK_APPLICATION_LIST_UPDATE: "SALES_NETWORK_APPLICATION_LIST_UPDATE",
    SALES_NETWORK_SYSTEM_LIST_UPDATE: "SALES_NETWORK_SYSTEM_LIST_UPDATE",
    TARIF_LIST_UPDATE: "TARIF_LIST_UPDATE",
    SALES_NETWORK_UI_TRANSLATIONS_LIST_UPDATE: "SALES_NETWORK_UI_TRANSLATIONS_LIST_UPDATE",
    UI_TRANSLATIONS_LIST_UPDATE: "UI_TRANSLATIONS_LIST_UPDATE",
    FAQ_LIST_UPDATE: "FAQ_LIST_UPDATE",
    VERSION_LIST_UPDATE: "VERSION_LIST_UPDATE"
    
}

/**
 *the method for custom event creation
 * @param {string} eventName
 * @param {Function} callback
 */
export function useCustomEvent(eventName, callback) {
    useEffect(
        () => {
            if (eventName && callback) {
                let events = eventName.split(" ");
                for (const element of events) {
                    document.addEventListener(element, handler);
                }
                return () => {
                    for (const element of events) {
                        document.removeEventListener(element, handler);
                    }
                }

                function handler(e) {
                    callback(e);
                }
            }
        },
        [eventName, callback]
    );
}

/**
 *the method to trigger custom event
 * @param {string} eventName
 * @param {any} data
 */
export function dispatchCustomEvent(eventName, data) {
    let events = eventName && eventName.split(" ");
    if (events) {
        for (const element of events) {
            const event = new CustomEvent(element, { bubbles: true, detail: data });
            document.dispatchEvent(event);
        }
    }
}

/**
 *custom hook to ensure(for front end) the login state on specific pages
 * @param {any} props
 */
export function useLoginCheck(props) {
    const navigate = useNavigate();
    useEffect(() => {
        if (!props.skipLogin && !store.getState().isLoggedIn) {
            //let navigate = props.history || historyHook;
            navigate("/login?redirectUrl=" + window.location.pathname);
        }
    }, [props.history, props.skipLogin, navigate]);

    // const historyHook = useHistory();
    // useEffect(() => {
    //     if (!props.skipLogin && !store.getState().isLoggedIn) {
    //         let history = props.history || historyHook;
    //         history.push("/login?redirectUrl=" + window.location.pathname);
    //     }
    // }, [props.history, props.skipLogin, historyHook]);
}

const deleteLoaderName = 'DELETE_LOADER';

/**
 *custom hook creating specific delete method for each list and form given props
 * @param {any} props { nameField, deleteUrl, backPage, deleteKeys, listUpdateEvent, endDelete, deleteMessage, textYes, textNo }
 */
export function useDelete(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { nameField, deleteUrl, backPage, keyField, deleteKeys, listUpdateEvent, startDelete, endDelete, deleteMessage, popupTitle, textYes, textNo, post } = props;
    const onDelete = useCallback((record, callback) => {// NOSONAR
        dispatch(openPopup({
            windowKey: 'wndConfirmDelete',
            type: PopupTypes.Confirm,
            disableOutsideClick: true,
            title: i18n(popupTitle || 'message.delete_title', [nameField ? record[nameField] : record.nom]), // <T args={[nameField ? record[nameField] : record.nom]}>{popupTitle || 'message.delete_title'}</T>,//`Delete ‘${record.nom}‘`,
            text: i18n(deleteMessage || "message.delete_confirm"), //'This action cannot be undone. Are you sure you want to continue?',
            buttonYesText: i18n(textYes || "text.delete"),//Defines.Messages.confirmDelete,
            buttonNoText: i18n(textNo || "text.cancel"),//Defines.Messages.cancelDelete,
            yesCallback: function () {
                showLoader(deleteLoaderName);
                startDelete && startDelete();
                if (deleteKeys) {
                    let data = [];
                    deleteKeys.map((key) => {
                        return data[key] = record[key]//NOSONAR
                    })
                    Ajax.post({
                        url: deleteUrl,
                        data: {
                            data
                        },
                        success: function (response) {
                            if (response) {
                                dispatchCustomEvent(listUpdateEvent, { action: "delete" });
                                (callback && callback(response)) || (backPage && navigate(Project.getPageUrl(backPage)));
                            }

                            endDelete && endDelete();
                            hideLoader(deleteLoaderName);
                        },
                        error: function (response) {
                            if (response.message) {
                                message.error(response.message);
                            }
                            hideLoader(deleteLoaderName);
                        }
                    })
                } else {
                    Ajax[post ? "post" : "get"]({
                        url: deleteUrl,
                        data: keyField ? { [keyField]: record[keyField] } : { id: record.ID },
                        success: function (response) {
                            if (response) {
                                dispatchCustomEvent(listUpdateEvent, { action: "delete" });
                                (callback && callback(response)) || (backPage && navigate(Project.getPageUrl(backPage)));
                            }
                            endDelete && endDelete();
                            hideLoader(deleteLoaderName);
                        },
                        error: function (response) {
                            if (response.message) {
                                message.error(response.message);
                            }
                            hideLoader(deleteLoaderName);
                        }
                    })
                }

            }
        }));
    }, [deleteUrl, nameField, listUpdateEvent, backPage, endDelete, startDelete, deleteKeys, deleteMessage, textNo, textYes, popupTitle,post, navigate, dispatch, keyField]);

    return onDelete;
}

export function cloneDataItem(dataItem) {
    return Object.assign({}, dataItem);
}

export function deepCopy(inObject) {
    let outObject, value, key

    if (typeof inObject !== "object" || inObject === null) {
        return inObject;
    }

    outObject = Array.isArray(inObject) ? [] : {}
    for (key in inObject) {
        value = inObject[key]
        outObject[key] = deepCopy(value)
    }

    return outObject
}

export const b64toBlob = (b64Data) => {
    let byteCharacters = atob(b64Data) // NOSONAR

    let byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        let slice = byteCharacters.slice(offset, offset + 512),
            byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }
        let byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }

    let blob = new Blob(byteArrays, { type: "application/json" })
    return blob
}

export function isSameObject(obj1, obj2) {// NOSONAR
    for (let p in obj1) {
        //Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

        if (obj1[p] === null && obj2[p] === null) {
            continue
        } else {
            if ((obj1[p] === null && obj2[p] !== null) || (obj1[p] !== null && obj2[p] === null)) {
                return false;
            }
        }

        switch (typeof (obj1[p])) {
            //Deep compare objects
            case 'object':
                if (!isSameObject(obj1[p], obj2[p])) return false;
                break;
            //Compare function code
            case 'function':
                if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
                break;
            //Compare values
            default:
                if (obj1[p] !== obj2[p]) return false;
        }
    }

    //Check object 2 for any extra properties
    for (let p in obj2) {
        if (typeof (obj1[p]) === 'undefined') return false;
    }
    return true;
}
