import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from '../../Shared/Translation/i18n';

import { ApiUrl, AppPages } from '../../project/Defines.js';
import { Project } from '../../project/Project';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import DetailView from '../../Shared/DetailView';
import InputCont from '../../Shared/Form/InputCont';
import { TabStrip } from '../../Shared/TabStrip';
import Translation from '../../Shared/Translation';
import useDetail from '../../Shared/useDetail';
import ProductSalesNetworkDocument from '../ProductSalesnetworkDoacument';

const listUpdateEvent = EVENT_NAME.PRODUCT_SALES_NETWORK_LIST_UPDATE;

export default function ProductSalesNetworkDetails(props) {
    const { id, type } = useParams();
    const { windowKey, data } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const [translationHasChanges, setTranslationHasChanges] = useState(false);
    const navigate = useNavigate();

    const { dataItem, setDataItem, updateData, hasChanges, /*closePopup,*/ onDetailDelete, detailRef, onChangeData, validation, /*isLoading, loadData*/ } = useDetail({
        data: data || { ID: id !== 'create' ? id : 0 },
        windowKey,
        updateUrl: ApiUrl.ProductSalesNetworkUpdate,
        // additionalData: { type },
        dataLoadUrl: ApiUrl.ProductSalesNetworkLoad,
        dataLoadParams: { id: id || 0, type },
        deleteUrl: ApiUrl.ProductSalesNetworkDelete,
        nameField: 'sku',
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.ProductSalesNetworkDetails, type),
        changingValidation: true,
        onRecordDeleted: () => {
            setTranslationHasChanges(false);
        }
    });
    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
            dispatchCustomEvent(EVENT_NAME.UPDATE_PRODUCT_TRANSLATIONS, { ID: response.productId });
        }
    }, [setDataItem, detailRef]);

    return (<>
        {dataItem && <DetailView
            hasChanges={hasChanges || translationHasChanges}
            backUrl={Project.getPageUrl(AppPages.ProductSalesNetwork, type)}
            title={dataItem.sku + ' - ' + dataItem.label}
        >
            <view>
                <form>
                    <TabStrip 
                        onChange={(selected) => setTabIndex(selected)} // NOSONAR
                        // skipSecondaryTabs={isNew} 
                        selectedIndex={tabIndex}
                    >
                        <div title='text.product'>
                            <div className='form_container'>
                                <div className='section_group'>
                                    <div className='sections '>
                                        <InputCont label='text.sales_reference' inputProps={{ validation, name: 'sku', value: dataItem.sku, disabled: true }} />
                                        <InputCont label='text.label' inputProps={{ validation, name: 'label', value: dataItem.label, disabled: true }} />
                                        <InputCont label='text.active' inputProps={{ validation, type: 'checkbox', name: 'active', value: dataItem.active, onChange: onChangeData }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div title='text.translations'>
                            <Translation
                                ID={dataItem.productId}
                                networkId={dataItem.salesnetworkId}
                                referenceTable={'products'}
                                fields={['label']}
                                showField
                                updateEvent={EVENT_NAME.UPDATE_PRODUCT_TRANSLATIONS}
                                onTranslationChanged={setTranslationHasChanges}
                            />
                        </div>
                        <div title='text.documents'>
                            <ProductSalesNetworkDocument/>
                        </div>
                    </TabStrip>
                    {tabIndex < 2 &&
                        <toolbar>
                            <wrap>
                                <action right=''>
                                    <div onClick={(e) => updateData(updateSuccessCallback)} className={hasChanges || translationHasChanges ? ' button primary' : 'button'}><icon>save</icon>{i18n('text.save')}</div>
                                    { dataItem.ID > 0 &&
                                        <div className='button' onClick={(e) => {
                                            onDetailDelete(dataItem, () => { navigate(Project.getPageUrl(AppPages.ProductSalesNetwork, type)) })
                                        }}>
                                            <icon>delete</icon>{i18n('text.delete')}
                                        </div>
                                    }
                                </action>
                            </wrap>
                        </toolbar>
                    }
                </form>
            </view>
        </DetailView>}
    </>);
}
