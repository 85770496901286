import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../Shared/Translation/i18n';

import { ApiUrl, AppPages, UserRoles } from '~/project/Defines.js';
import { Project } from '~/project/Project';
import { dispatchCustomEvent, EVENT_NAME } from '~/project/utilities';
import DetailView from '~/Shared/DetailView';
import useDetail from '~/Shared/useDetail';
import InputCont from '../../Shared/Form/InputCont';

const defaultValidationFields = [
    { name: "email", rules: [
        { type: "required" }, 
        { type: "email" }
    ] },
    "roles"
];

/**
 * ...
 * @exports AdministratorDetails
 */
export default function AdministratorDetails(props) {
    const [validationFields, setValidationFields] = useState(defaultValidationFields);
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.ADMINISTRATORS_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, hasChanges, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : 0 },
        windowKey,
        updateUrl: ApiUrl.AdministratorUpdate,
        dataLoadUrl: ApiUrl.AdministratorLoad,
        deleteUrl: ApiUrl.AdministratorDelete,
        nameField: 'email',
        backPage: AppPages.Administrator,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.AdministratorDetails),
        changingValidation: true,
        validationFields
    });

    useEffect(() => {
        let validationFieldsCopy = [...defaultValidationFields];
        
        if (dataItem && dataItem.role_names && dataItem.role_names.includes(UserRoles.ADMIN)) {
            validationFieldsCopy = [...validationFieldsCopy, "networks"];
        }

        setValidationFields(validationFieldsCopy);
    }, [dataItem && dataItem.role_names, isNew]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    const changeRole = (e, dataSource) => {
        const dataItemCopy = { ...dataItem };

        dataItemCopy.roles = e;
        dataItemCopy.role_names = dataItemCopy.roles.map(r => dataSource.find(d => d.value === r).text);
        if (!dataItemCopy.role_names.includes(UserRoles.ADMIN)) {
            dataItemCopy.networks = [];
        }
        setDataItem(dataItemCopy, true);
    }

    return (<>
        {dataItem && <DetailView
            hasChanges={hasChanges}
            backUrl={Project.getPageUrl(AppPages.Administrator)}
            title={isNew ? i18n('text.new_administrators') : dataItem.email}
        >
            <view >
                <form>
                    <div className='form_container'>
                        <div className='section_group'>
                            <div className='sections '>
                                <InputCont label="text.email"
                                    inputProps={{ validation, name: 'email', value: dataItem.email, onChange: onChangeData, disabled: !isNew }} />

                                <InputCont label="text.first_name"
                                    inputProps={{ validation, name: 'firstName', value: dataItem.firstName, onChange: onChangeData }} />

                                <InputCont label="text.last_name"
                                    inputProps={{ validation, name: 'lastName', value: dataItem.lastName, onChange: onChangeData }} />


                                {/*<InputCont label="text.password"*/}
                                {/*    inputProps={{ validation, name: 'password', type: 'password', value: dataItem.password, onChange: onChangeData, autoComplete: "new-password" }} />*/}

                                <InputCont label="text.roles"
                                    inputProps={{ validation, name: 'roles', type: 'multiselect', valuelist: 'admin_roles', value: dataItem.roles, onChange: changeRole, disableCach: true }} />

                                {
                                    dataItem.role_names && dataItem.role_names.includes(UserRoles.ADMIN) &&
                                    <InputCont label="text.networks"
                                        inputProps={{ validation, name: 'networks', type: 'multiselect', valuelist: 'salesnetworks', value: dataItem.networks, onChange: e => onChangeData({ target: { name: 'networks', value: e } }), disableCach: true }} />


                                }
                            </div>
                        </div>
                    </div>
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className={hasChanges ? ' button primary' : 'button'}><icon>save</icon>{i18n('text.save')}</div>
                                {dataItem.ID > 0 && !dataItem.IsCurrentUser &&
                                    <div className='button' onClick={(e) => {
                                        onDetailDelete(dataItem)
                                    }}>
                                        <icon>delete</icon>{i18n('text.delete')}
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>
                </form>
            </view>
        </DetailView>}
    </>);
}
