/**
 * the formats for different types of data
 */
export const Defines = {
    Format: {
        MomentDate: "YYYY/MM/DD",
        MomentDateTime: "YYYY/MM/DD HH:mm",
        MomentTime: "HH:mm",
        MomentFullTime: "HH:mm:ss",

        PrintDate: "DD/MM/YYYY", //"MM/dd/yyyy",
        PrintDateTime: "DD/MM/yyyy HH:mm",
        FileNameDate: "yyyyMMdd",
        FileNameDateTime: "yyyy_MM_dd_HH-mm",

        ExpirationDate: "MMddyy",

        InputDate: "MM/dd/yyyy",
        InputTime: "h:mm tt",

        Time: "HH:mm",

        MonthDate: "MMMM, yyyy",

        Number0: "n0",

        Week: "yyyy-ww"
    },
    Messages: {
        //confirmDelete: "Delete",
        //cancelDelete: "Cancel",
        Yes: "Yes",
        No: "No",
    }
};

/**
 * shorthands to get page urls
 */
export const UserRoles = {
    ADMIN: "admin",
    SUPERADMIN: "superadmin"
}

export const AppPages = {
    Home: "home",

    Login: "login",
    Logout: "logout",
    Register: "register",
    Forgot: "forgot",
    NotFound: "notfound",
    Template: "template",
    TemplateDetail: "templatedetail",
    Administrator: "administrator",
    AdministratorDetails: "administratordetails",

    Product: "product",
    ProductDetails: "productdetails",

    ProductSalesNetwork: "productsalesnetwork",
    ProductSalesNetworkDetails:"productsalesnetworkdetails",
    SalesNetwork: "salesnetwork",
    SalesNetworkDetails: "salesnetworkdetails",
    SalesNetworkConfig: "salesnetworkconfig",
    Statistics: "statistics",

    Tarif: "tarif",
    TarifDetails: "tarifdetails",

    Translations: "translations",
    UITranslations: "uitranslations",
    UITranslationDetails:"uitranslationdetails",

    Faq: "faq",
    FaqDetails: "faqdetails",

    Version: "version",
    VersionDetails: "versiondetails"
}

export const StoreKeys = {
    UserToken: "USER-TOKEN",
    UserData: "userData",
    LoggedIn: "loggedIn",
    ListStates: "listStates",
    Culture: "culture",
    Role: "role",
    Roles: "roles",
    Network: "network",
    Networks: "networks",
    ExpireTime: "exptime",
}

/**
 * the url endings for each action of api
 */
export const ApiUrl = {
    Login: "/account/login",
    LoginByCode: "/account/loginCode",
    Logout: "/account/logout",
    LogoutByCode: "/account/logoutCode",
    Check: "/account/check",
    //ConnectAs: "/account/ConnectAs",
    //Register: "/account/register",
    //Forgot: "/account/forgot",
    Connect: "/wso/SSOConnect",
    GetNetworks: "/account/getnetworks",

    ValueList: "/api/valuelist/getlist",

    GetLanguages: "/api/Translation/GetLanguages",
    UpdateTranslations: "/api/Translation/UpdateTranslations",
    TranslationList: "/api/Translation/GetlistData",

    AdministratorList: "/api/SystemUser/GetListData",
    AdministratorLoad: "/api/SystemUser/ResetFormData",
    AdministratorUpdate: "/api/SystemUser/UpdateFormData",
    AdministratorDelete: "/api/SystemUser/DeleteFormData",

    ProductList: "/api/Product/GetListData",
    ProductLoad: "/api/Product/ResetFormData",
    ProductUpdate: "/api/Product/UpdateFormData",
    ProductDelete: "/api/Product/DeleteFormData",

    ProductExport: "/api/Product/ExportData",
    ProductImport: "/api/Product/ImportData",

    ProductsExtendedInfoExport: "/api/ProductsExtendedInfo/ExportData",
    ProductsExtendedInfo: "/api/ProductsExtendedInfo/ImportData",

    ProductSalesNetworkExport: "/api/ProductSalesNetwork/ExportData",
    ProductSalesNetworkImport: "/api/ProductSalesNetwork/ImportData",

    MoteurExport: "/api/Product/ExportMoteur",
    DownloadMoteurJSON: "/api/Product/DownloadMoteurJSON",

    ProductSalesNetworkList: "/api/ProductSalesNetwork/GetListData",
    ProductSalesNetworkLoad: "/api/ProductSalesNetwork/ResetFormData",
    ProductSalesNetworkActiveUpdate: "/api/ProductSalesNetwork/UpdateProductSalesNetwork",
    ProductSalesNetworkUpdate: "/api/ProductSalesNetwork/UpdateFormData",
    ProductSalesNetworkDelete: "/api/ProductSalesNetwork/DeleteFormData",

    ProductSalesNetworkDocumentList: "/api/ProductSalesNetworkDocument/GetListData",
    ProductSalesNetworkDocumentLoad: "/api/ProductSalesNetworkDocument/ResetFormData",
    ProductSalesNetworkDocumentUpdate: "/api/ProductSalesNetworkDocument/UpdateFormData",

    // SalesNetworkList: "/api/ProductSalesNetwork/GetListData",
    TarifLoad: "/api/ProductSalesNetwork/ResetFormData",
    TarifUpdate: "/api/ProductSalesNetwork/UpdateFormData",
    TarifDelete: "/api/ProductSalesNetwork/DeleteFormData",

    TarifImport: "/api/ProductSalesNetwork/TarifImport",
    TarifExport: "/api/ProductSalesNetwork/ExportTarif",

    DatePriceFutureUpdate: "/api/ProductSalesNetwork/UpdateDatePriceFuture",
    DatePriceCurrentUpdate: "/api/ProductSalesNetwork/UpdateDatePriceCurrent",

    SalesNetworkList: "/api/SalesNetwork/GetListData",
    SalesNetworkLoad: "/api/SalesNetwork/ResetFormData",
    SalesNetworkUpdate: "/api/SalesNetwork/UpdateFormData",
    SalesNetworkDelete: "/api/SalesNetwork/DeleteFormData",
    SalesNetworkGetData: "/api/SalesNetwork/GetData",
    SalesNetworkCurrentUpdate: "/api/SalesNetwork/SalesNetworkCurrentUpdate",
    SalesNetworkLanguagesLoad: "/api/SalesNetwork/GetLanguages",
    SalesNetworkCurrentLangugesUpdate: "/api/SalesNetwork/SalesNetworkCurrentLangugesUpdate",
    SalesNetworkDefaultLangugeUpdate: "/api/SalesNetwork/SalesNetworkDefaultLangugeUpdate",
    SalesNetworkThemeUpdate: "/api/SalesNetwork/SalesNetworkThemeUpdate",
    SalesNetworkBrandUpdate: "/api/SalesNetwork/SalesNetworkBrandUpdate",

    SalesNetworkApplicationsLoad: "/api/SalesNetwork/GetApplications",
    SalesNetworkApplicationsUpdate: "/api/SalesNetwork/ApplicationsUpdate",

    SalesNetworkSystemsLoad: "/api/SalesNetwork/GetSystems",
    SalesNetworkSystemsUpdate: "/api/SalesNetwork/SystemsUpdate",

    ProductsExtendedInfoList: "/api/ProductsExtendedInfo/GetListData",
    ProductsExtendedInfoLoad: "/api/ProductsExtendedInfo/GetProductExtendedInfo",
    ProductsExtendedInfoDelete: "/api/ProductsExtendedInfo/DeleteFormData",
    ProductsExtendedInfoUpdate: "/api/ProductsExtendedInfo/UpdateProductExtendedInfo",

    UITranslationsList: "/api/UITranslations/GetListData",
    UITranslationsLoad: "/api/UITranslations/ResetFormData",
    UITranslationsDelete: "/api/UITranslations/DeleteFormData",
    UITranslationsUpdate: "/api/UITranslations/UpdateFormData",
    
    UITranslationsImport: "/api/UITranslations/ImportData",
    UITranslationsExport: "/api/UITranslations/ExportData",

    SalesNetworkUITranslationsList: "/api/SalesNetworkUITranslations/GetListData",
    SalesNetworkUITranslationsLoad: "/api/SalesNetworkUITranslations/ResetFormData",
    SalesNetworkUITranslationsDelete: "/api/SalesNetworkUITranslations/DeleteFormData",
    SalesNetworkUITranslationsUpdate: "/api/SalesNetworkUITranslations/UpdateFormData",

    SalesNetworkUITranslationsImport: "/api/SalesNetworkUITranslations/ImportData",
    SalesNetworkUITranslationsExport: "/api/SalesNetworkUITranslations/ExportData",

    FaqList: "/api/Faq/GetListData",
    FaqLoad: "/api/Faq/ResetFormData",
    FaqDelete: "/api/Faq/DeleteFormData",
    FaqUpdate: "/api/Faq/UpdateFormData",

    FaqImport: "/api/Faq/ImportData",
    FaqExport: "/api/Faq/ExportData",

    UpdateFaqItem: "/api/Faq/UpdateFaqItem",
    LoadFaq: "/api/Faq/LoadFaq",
    UpdateFaqItemPosition: "/api/Faq/UpdateFaqItemPosition",

    VersionList: "/api/Version/GetListData",
    VersionLoad: "/api/Version/ResetFormData",
    VersionDelete: "/api/Version/DeleteFormData",
    VersionUpdate: "/api/Version/UpdateFormData",

    StatisticsList: "/api/Statistics/GetListData",
    StatisticsLoad: "/api/Statistics/ResetFormData",
    StatisticsDelete: "/api/Statistics/DeleteFormData",
    StatisticsUpdate: "/api/Statistics/UpdateFormData",

    StatisticsExport: "/api/Statistics/ExportStatistics"
}

