import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EVENT_NAME, dispatchCustomEvent } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { Project } from '../../project/Project';

const listUpdateEvent = EVENT_NAME.PRODUCT_LIST_UPDATE;

export default function ImportDetail(props) {
    const { fileData } = props;
    const { type } = useParams();
    const columns = [
        {
            text: 'text.sales_reference',
            field: 'salesReference',
            type: COLUMN_TYPE.TEXT,
            width: 120,
            fixed: 'left',
        },
        {
            text: 'text.master_code',
            field: 'masterId',
            type: COLUMN_TYPE.TEXT,
            width: 220,
            fixed: 'left',
        },
        {
            text: 'text.product_type',
            field: 'productType',
            type: COLUMN_TYPE.TEXT,
            width: 120,
            fixed: 'left',
        },
        {
            text: 'text.brand',
            field: 'brand',
            type: COLUMN_TYPE.TEXT,
            width: 100,
            fixed: 'left',
        },
        {
            text: 'text.is_accessory',
            field: 'isAccessory',
            render: (text, record) => {
                if (record.isAccessory === 0) {
                    return;
                }
                
                if (record.isAccessory) {
                    if ((!isNaN(record.isAccessory) && parseFloat(record.isAccessory)) || typeof(record.isAccessory) === "boolean" || record.isAccessory === "√") {
                        return <wrap>
                            <item>
                                <icon>done</icon>
                            </item>
                        </wrap> 
                    }
                }
            },
            type: COLUMN_TYPE.TEXT,
            width: 100,
            fixed: 'left'
        },
        {
            text: 'text.obsolete',
            field: 'obsolete',
            render: (text, record) => {
                if (record.obsolete === 0) {
                    return;
                }
                
                if (record.obsolete) {
                    if ((!isNaN(record.obsolete) && parseFloat(record.obsolete)) || typeof(record.obsolete) === "boolean" || record.obsolete === "√") {
                        return <wrap>
                            <item>
                                <icon>done</icon>
                            </item>
                        </wrap> 
                    }
                }
            },
            type: COLUMN_TYPE.TEXT,
            width: 70,
            fixed: 'left',
        },
        {
            text: 'label',
            field: 'label',
            type: COLUMN_TYPE.TEXT,
            width: 150,
            fixed: 'left',
        },
        Project.getImportActionColumn(COLUMN_TYPE.TEXT),
        Project.getImportMessageColumn(COLUMN_TYPE.TEXT),
    ]

    useEffect(() => {
        dispatchCustomEvent(listUpdateEvent);
    }, [type]);

    return (
        <ListView
            dataSource={fileData || []}
            columnConfig={columns}
            rowClassName={Project.getRecordActionClass}
            skipEdit
            nameField='sku'
            skipNewButton
            className='scroll_fix'
        />
    );
}