import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ApiUrl, AppPages, Defines } from '../../project/Defines';
import { EVENT_NAME } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { Project } from '../../project/Project';

import ImportTarif from './importDetail';

import { openPopup } from '../../store/actions';
import { PopupTypes } from '../../components/Popup';
import { message, Upload } from 'antd';
import { Ajax } from '../../components/Ajax';

import { showLoader, hideLoader } from '../../Shared/Loader';
import InputCont from '../../Shared/Form/InputCont';
import useDetail from '../../Shared/useDetail';

import { TarifImport, TarifExport } from '../Tarif/action/tarifAction';

const dateLoader = 'DATE_LOADER';
const listUpdateEvent = EVENT_NAME.TARIF_LIST_UPDATE;

function Tarif(props) {
    const dispatch = useDispatch();

    const { dataItem, setDataItem } = useDetail({
        data: {},
        dataLoadUrl: ApiUrl.SalesNetworkGetData
    });

    const importData = (file, onSuccess) => {
        const windowKey = 'importWnd';

        TarifImport(file, listUpdateEvent, (response) => {
            if (response && response.Data) {
                dispatch(openPopup({
                    component: ImportTarif,
                    windowKey,
                    title: "Import",
                    type: PopupTypes.Popup,
                    //className: popupClassName,
                    fullScreen: false,
                    bodyProps: { windowKey, fileData: response.Data }
                }));
            }
        })
    }

    const updateDate = useCallback(() => {
        showLoader(dateLoader);
        Ajax.get({
            url: ApiUrl.DatePriceFutureUpdate,
            data: { id: dataItem.ID, date: dataItem.datePriceFuture ? dataItem.datePriceFuture : '' },
            success: (response) => {
                if (response.HasError) {
                    message.error(response.Message);
                    hideLoader(dateLoader);
                }
                else {
                    setDataItem(response);
                    hideLoader(dateLoader);
                }
            }
        });
    }, [dataItem, setDataItem]);

    const updateDateCurrent = useCallback(() => {
        showLoader(dateLoader);
        Ajax.get({
            url: ApiUrl.DatePriceCurrentUpdate,
            data: { id: dataItem.ID, date: dataItem.datePriceCurrent ?  dataItem.datePriceCurrent : ''},
            success: (response) => {
                if (response.HasError) {
                    message.error(response.Message);
                    hideLoader(dateLoader);
                }
                else {
                    
                    setDataItem(response);
                    hideLoader(dateLoader);
                }
            }
        });
    }, [dataItem, setDataItem]);


    const columns = [
        {
            text: 'text.sales_reference',
            field: 'sku',
            width: 200,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.active',
            field: 'active',
            width: 100,
            render: (text, record) => {
                // return record.active && <wrap>
                //                                 <item>
                //                                     <icon>done</icon>
                //                                 </item>
                //                             </wrap>
                // return (record.priceCurrent || (record.priceFuture && (curentDate > record.datePriceFuture))) && <wrap>
                return record.active && <wrap>
                    <item>
                        <icon>done</icon>
                    </item>
                </wrap>
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.label',
            field: 'default_label',
            width: 220,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.tarif_1',
            field: 'priceCurrent',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.tarif_2',
            field: 'priceFuture',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
            commands: [
                { command: 'edit', skipCommandForRecord: record => (record.ID <= 0) }
            ]
        }
    ];

    return (
        <>
            {1 === 2 &&
                <toolbar>
                    <InputCont
                        label='text.application_date_rate_2'
                        inputProps={{
                            // allowClear: false,
                            name: 'datePriceFuture',
                            type: 'datetime',
                            dataField: 'datePriceFuture',
                            format: Defines.Format.MomentDate,
                            placeholder: "",
                            dataItem: dataItem ? dataItem : null,
                            setDataItem
                            // min: new Date()
                        }}
                        icon='save'
                        iconClick={updateDate}
                    />

                    <action right="">
                        {/*<InputCont label={"Missing Tariffs"} inputProps={{ type: 'checkbox', value: missingTariffs, onChange: (e) => setMissingTariffs(e.target.checked), checked: missingTariffs }} />*/}
                        <div
                            className='button primary'
                            title="Export"
                            onClick={TarifExport}
                        >
                            <icon>download</icon><text>Export</text>
                        </div>

                        <Upload id="uploader"
                            customRequest={importData} //NOSONAR
                            showUploadList={false}>
                            <div className='button primary' title="Import"><icon>upload</icon><text>Import</text></div>
                        </Upload>
                    </action>

                </toolbar>
            }
            <ListView
                isSimple
                listName={'tarif_' + props.network}
                columnConfig={columns}
                filters={[
                    {
                        name: 'datePriceCurrent', type: 'control', component: <InputCont
                            label='text.application_date_rate_1'
                            inputProps={{
                                name: 'datePriceCurrent',
                                type: 'datetime',
                                dataField: 'datePriceCurrent',
                                format: Defines.Format.MomentDate,
                                placeholder: "",
                                dataItem: dataItem ? dataItem : null,
                                setDataItem
                            }}
                            icon='save'
                            iconClick={updateDateCurrent}
                        />, align: 'left'
                    },
                    {
                        name: 'datePriceFuture', type: 'control', component: <InputCont
                            label='text.application_date_rate_2'
                            inputProps={{
                                // allowClear: false,
                                name: 'datePriceFuture',
                                type: 'datetime',
                                dataField: 'datePriceFuture',
                                format: Defines.Format.MomentDate,
                                placeholder: "",
                                dataItem: dataItem ? dataItem : null,
                                setDataItem
                                // min: new Date()
                            }}
                            icon='save'
                            iconClick={updateDate}
                        />, align: 'left'
                    },
                    { type: 'separator', align: 'left' },
                    { name: 'search', type: 'search', align: 'left' },
                    { type: 'separator', align: 'left' },
                    { name: 'missingTariffs', type: 'checkbox', text: 'text.active_products_with_no_tariffs', align: 'left' },
                    { type: 'separator', align: 'left' },
                    { name: 'export', type: 'button', text: 'Export', onClick: TarifExport, icon: 'download', align: 'right' },
                    { name: 'import', type: 'uploader', text: 'Import', customRequest: importData, icon: 'upload', align: 'right' }
                ]}

                //dataSource={tarifData || []}
                apiUrl={ApiUrl.ProductSalesNetworkList}
                detailUrl={Project.getPageUrl(AppPages.TarifDetails)}
                listUpdateEvent={EVENT_NAME.TARIF_LIST_UPDATE}
                // staticFilter={{ isTarif: true }}
                nameField="sku"
                onBeforeEdit={(record, isNewRecord) => { //NOSONAR
                    return isNewRecord || record.ID > 0;
                }}
                rowClassName={(record) => { //NOSONAR
                    return (record.active && (!record.priceCurrent || !record.priceFuture)) && "highlighted";
                }}
                skipNewButton
            //keyField={dataItem && dataItem.ID !== "" ? "ID" : "product_id"}
            //search={{ field: 'search' }}
            // {...( missingTariffs ? { staticFilter: { missingTariffs } } : {})}
            />
        </>
    );
}

export default connect(state => ({ network: state.network }))(Tarif)