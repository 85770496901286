import React, { useEffect } from 'react';
//import { withRouter } from 'react-router-dom';
import { withRouter } from '../hook/withRouter';
import { connect } from "react-redux";
import store from 'store';
import { message } from 'antd';
import i18n from '../Shared/Translation/i18n';

import { Ajax } from '../components/Ajax';
import { ApiUrl, StoreKeys } from '../project/Defines';
import { Project } from '../project/Project';
import Popups from '../components/Popup';

import TopMenu from './Navigation/TopMenu';
import NavigationMenu from './Navigation/NavigationMenu';
import Loader from '../Shared/Loader';
import { refreshLogin } from '../store/actions/index.js';


/**
 * The layout component specifies the site layout, top menu based on login state and active states of top menu items
 * @param {object} props  { isLoggedIn, popups, culture, role, etc.}
 */
function Layout(props) {
    useEffect(() => {
        message.config({
            top: 100,
            duration: 4,
        });

        const check = function () {
            if (props.isLoggedIn) {
                let exptime = store.get(StoreKeys.ExpireTime);

                if (!exptime || exptime < new Date()) {
                    Ajax.post({
                        url: ApiUrl.Check,
                        data: {},
                        success: function (response) {
                            if (response) {
                                if (response.accessToken && response.userToken && response.logoutUrl) {
                                    // let user = store.get(StoreKeys.UserData);
                                    // user.accessToken = response.accessToken;
                                    // user.logoutUrl = response.logoutUrl;
                                    // store.set(StoreKeys.UserToken, response.userToken);
                                    // store.set(StoreKeys.UserData, user);

                                    props.dispatch(refreshLogin({
                                        userToken: response.userToken,
                                        accessToken: response.accessToken,
                                        logoutUrl: response.logoutUrl
                                    }
                                    ));
                                }
                                store.set(StoreKeys.ExpireTime, (new Date()).setMinutes((new Date()).getMinutes() + 1));
                            }
                        }
                    })
                }
            }
        }
        const timer = setInterval(check, 6e4);
        check();
        return () => {
            clearInterval(timer)
        }
    }, [props, props.isLoggedIn]);// eslint-disable-next-line react-hooks/exhaustive-deps


    let location = window.location.pathname.toLowerCase();
    return (
        <>
            <view key={i18n.language + props.network}>
                {(props.isLoggedIn && !Project.skipHeader(location) &&
                    <>
                        <TopMenu role={props.role} culture={props.culture} />
                        <Popups />
                        <view vertical="">
                            <Popups fullScreen />
                            <view mini="" navigation="">
                                <NavigationMenu role={props.role} culture={props.culture} />
                            </view>
                            <view >
                                {props.children}
                            </view>
                        </view>
                    </>
                ) || props.children}
                <Loader />
            </view>
        </>
    );
}


export default connect(state => ({
    isLoggedIn: state.isLoggedIn,
    popups: state.popups,
    culture: state.culture,
    role: state.role,
    network: state.network
}))(withRouter(Layout));