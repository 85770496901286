import React from 'react';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

/**
 * custom DataPicker outputing date with format YYYY/MM/DD
 * @param {object} props  dataItem, setDataItem, dataField
 */
export function DateRangePicker(props) {
    const { dataItem, setDataItem, dataField, min, max, showTime, placeholder, format, value, ...rest } = props;

    return <RangePicker
        {...rest}
        showTime={showTime}
        value={dataItem ? [dataItem.From, dataItem.To] : null}
        placeholder={placeholder}
    />
}