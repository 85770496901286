import React from 'react';

import { COLUMN_TYPE } from '../../Shared/ListView/helper';

const obsoleteDescriptionFields = [
    { field: 'options', width: '200px' },
    { field: 'range', width: '200px' },
    { field: 'obsolete', type: 'boolean' }
];

const commonFields = [
    ...obsoleteDescriptionFields,
    { field: 'stockage_capacite_commerciale', type: 'number', maxLength: '5', max: 99999 },
    { field: 'stockage_inox', type: 'boolean' },
    { field: 'stockage_jaquette', type: 'dropdown' },
    { field: 'stockage_capacite_utile', type: 'number', maxLength: '5', max: 99999 },
    { field: 'stockage_diametre_cuve_sans_ja', type: 'number', maxLength: '4', max: 9999 },
    { field: 'stockage_largeur_passage', type: 'number', maxLength: '4', max: 9999 },
    { field: 'stockage_hauteur_mini_local', type: 'number', maxLength: '4', max: 9999 },
    { field: 'stockage_pertes_thermiques', type: 'number', maxLength: '5', isDecimal: true, decimal: '3', max: 9.999 },
    { field: 'stockage_cote_basculement', type: 'number', maxLength: '4', max: 9999 },
    { field: 'stockage_diametre_hors_tout', type: 'number', maxLength: '4', max: 9999 },
    { field: 'stockage_poids_a_vide', type: 'number', maxLength: '4', max: 9999 },
    { field: 'stockage_poids_en_service', type: 'number', maxLength: '5', max: 99999 }
];

export const ProductFields = {
    exchange: {
        fields: [
            ...obsoleteDescriptionFields,
            { field: 'exchange_instant', type: 'boolean' },
            { field: 'exchange_nb_primaire_circ', type: 'number', maxLength: '1', max: 9 },
            { field: 'exchange_nb_secondaire_circ', type: 'number', maxLength: '1', max: 9 },
            { field: 'exchange_type_joint' },
            { field: 'exchange_ajout_plaque', type: 'boolean' },
            { field: 'exchange_puissancemax_ajoutplaque', type: 'number', maxLength: '4', max: 9999 },
            { field: 'exchange_height', type: 'number', maxLength: '4', max: 9999 },
            { field: 'exchange_width', type: 'number', maxLength: '4', max: 9999 },
            { field: 'exchange_depth', type: 'number', maxLength: '4', max: 9999 },
            { field: 'exchange_poids_a_vide', type: 'number', maxLength: '4', max: 9999 },
            { field: 'exchange_service_pressure_prim', type: 'number', maxLength: '10', max: 9999999999 },
            { field: 'exchange_service_pressure_sec', type: 'number', maxLength: '10', max: 9999999999 }
        ],
        text: 'plates_exchanger'
    },
    stockprim: {
        fields: [
            ...commonFields,
            { field: 'stockBallPrim_height', type: 'number', maxLength: '4', max: 9999 },
            { field: 'stockBallPrim_tb', type: 'boolean' },
            { field: 'stockBallPrim_pression_service', type: 'number', maxLength: '3', isDecimal: true, decimal: '1', max: 9.9 },
            { field: 'stockBallPrim_intermediate_connection', type: 'boolean' }
        ],
        text: 'primary_tank'
    },
    stockecs: {
        fields: [
            ...commonFields,
            { field: 'stockECS_type_bride_TH', type: 'dropdown' },
            { field: 'stockECS_volume_ECS_chauffe', type: 'number', maxLength: '4', max: 9999},
            { field: 'stockECS_volume_ECS_chauffe_coudee', type: 'number', maxLength: '4', max: 9999 },
            { field: 'stockECS_hauteur_hors_tout', type: 'number', maxLength: '4', max: 9999 },
            { field: 'stockECS_tb', type: 'boolean' },
            { field: 'stockECS_pression_service', type: 'number', maxLength: '4', isDecimal: true, decimal: '1', max: 99.9 },
            { field: 'stockECS_solution_elec', type: 'boolean' }
        ],
        text: 'dhw_tank'
    },
    stockprep: {
        fields: [
            ...commonFields,
            { field: 'stockBallPrep_type_bride_TH', type: 'dropdown' },
            { field: 'stockBallPrep_debit_primaire', type: 'number', maxLength: '4', isDecimal: true, decimal: '2', max: 9.99 },
            { field: 'stockBallPrep_pdc_primaire', type: 'number', maxLength: '5', isDecimal: true, decimal: '1', max: 999.9 },
            { field: 'stockBallPrep_hauteur_hors_tout', type: 'number', maxLength: '4', max: 9999 },
            { field: 'stockBallPrep_pression_service', type: 'number', maxLength: '4', isDecimal: true, decimal: '2', max: 9.99 },
            { field: 'stockBallPrep_coil_material', type: 'dropdown' }
        ],
        text: 'preparation_tank'
    },
    stocktint: {
        fields: [
            ...commonFields,
            { field: 'stockTinT_60_debit_pointe_10min', type: 'number', maxLength: '4', max: 9999 },
            { field: 'stockTinT_classe_nrj', maxLength: '4', max: 9999 },
            { field: 'stockTinT_pression_max_sanitaire', type: 'number', maxLength: '4', isDecimal: true, decimal: '1', max: 99.9 },
            { field: 'stockTinT_pression_max_primaire', type: 'number', maxLength: '3', isDecimal: true, decimal: '1', max: 9.9 },
            { field: 'stockTinT_debit_primaire', type: 'number', maxLength: '4', isDecimal: true, decimal: '2', max: 9.99 }, 
            { field: 'stockTinT_pdc_primaire', type: 'number', maxLength: '5', isDecimal: true, decimal: '1', max: 999.9 },
            { field: 'stockTinT_puissance_remise_regime', type: 'number', maxLength: '5', isDecimal: true, decimal: '1', max: 999.9 },
            { field: 'stockTinT_hauteur_hors_tout', type: 'number', maxLength: '4', max: 9999 },
            { field: 'stockTinT_pression_service', type: 'number', maxLength: '3', isDecimal: true, decimal: '1', max: 9.9 },
            { field: 'stockTinT_type_bride_TH', type: 'dropdown' }
        ],
        text: 'tank_in_tank'
    },
    accgaz: {
        fields: [
            ...obsoleteDescriptionFields,
            { field: 'accgaz_type_cuve', type: 'boolean' },
            { field: 'accgaz_type_gaz' },
            { field: 'accgaz_puissance_utile', type: 'number', maxLength: '5', isDecimal: true, decimal: '1', max: 999.9 },
            { field: 'accgaz_pression_service', type: 'number', maxLength: '4', isDecimal: true, decimal: '1', max: 99.9 },
            { field: 'accgaz_capacite_nominale', type: 'number', maxLength: '4', max: 9999 },
            { field: 'accgaz_hauteur_mini_local', type: 'number', maxLength: '4', max: 9999 },
            { field: 'accgaz_width', type: 'number', maxLength: '4', max: 9999 },
            { field: 'accgaz_diametre_hors_tout', type: 'number', maxLength: '4', max: 9999 },
            { field: 'accgaz_diametre_sans_jaquette', type: 'number', maxLength: '4', max: 9999 },
            { field: 'accgaz_hauteur_hors_tout', type: 'number', maxLength: '4', max: 9999 },
            { field: 'accgaz_poids_a_vide', type: 'number', maxLength: '4', max: 9999 },
            { field: 'accgaz_kit', width: '100px' },
            { field: 'accgaz_kitG31' }
        ],
        //text: 'heatmaster_watermaster'
        text: 'sanigaz'
    },
    hmwm: {
        fields: [
            ...obsoleteDescriptionFields,
            { field: 'WMHM_inox', type: 'boolean' },
            { field: 'WMHM_type_gaz' },
            { field: 'WMHM_puissance_utile', type: 'number', maxLength: '5', isDecimal: true, decimal: '1', max: 999.9},
            { field: 'WMHM_pression_max_sanitaire', type: 'number', maxLength: '4', isDecimal: true, decimal: '1', max: 99.9 }, 
            { field: 'WMHM_pression_max_primaire', type: 'number', maxLength: '4', isDecimal: true, decimal: '1', max: 99.9 },
            { field: 'WMHM_capacite_commerciale', type: 'number', maxLength: '4', max: 9999 },
            { field: 'WMHM_capacite_utile', type: 'number', maxLength: '4', max: 9999 },
            { field: 'WMHM_largeur_passage', type: 'number', maxLength: '4', max: 9999 },
            { field: 'WMHM_largeur_produit', type: 'number', maxLength: '4', max: 9999 },
            { field: 'WMHM_cote_basculement', type: 'number', maxLength: '4', max: 9999 },
            { field: 'WMHM_profondeur_produit', type: 'number', maxLength: '4', max: 9999 },
            { field: 'WMHM_classe_nrj_chauffage', maxLength: '4', max: 9999 },
            { field: 'WMHM_classe_nrj_ECS', maxLength: '4', max: 9999 },
            { field: 'WMHM_profil_soutirage', maxLength: '4', max: 9999 },
            { field: 'WMHM_hauteur_hors_tout', type: 'number', maxLength: '4', max: 9999 },
            { field: 'WMHM_poids_a_vide', type: 'number', maxLength: '4', max: 9999 },
            { field: 'WMHM_hauteur_mini_local', type: 'number', maxLength: '4', max: 9999 }
        ],
        //text: 'sanigaz'
        text: 'heatmaster_watermaster'
    },
    pompes: {
        fields: [
            ...obsoleteDescriptionFields,
            { field: 'pompe_simple', type: 'boolean'},
            { field: 'pompe_marque', width: '90px', maxLength: '20', max: 99999999999999999999 },
            { field: 'pompe_modele', maxLength: '35', max: 99999999999999999999999999999999999 },
            { field: 'pompe_debit_maxi', type: 'number', maxLength: '5', isDecimal: true, decimal: '2', max: 99.99 }
        ],
        text: 'pump'
    },
    resistance: {
        fields: [
            ...obsoleteDescriptionFields,
            { field: 'resistance_type' },
            { field: 'resistance_tension_nature', maxLength: '20', max: 99999999999999999999 },
            { field: 'resistance_puissance', type: 'number', maxLength: '7', isDecimal: true, decimal: '1', max: 99999.9},
            { field: 'resistance_montage' },
            { field: 'resistance_droite_courbe', maxLength: '50', max: 99999999999999999999999999999999999999999999999999 } 
        ],
        text: 'resistance'
    },
    rsb: {
        fields: [
            ...obsoleteDescriptionFields,
            { field: 'stockage_capacite_utile', type: 'number', maxLength: '5', max: 99999 },
            { field: 'RSB_puissance', type: 'number', maxLength: '4', max: 9999 },
            { field: 'RSB_height', type: 'number', maxLength: '4', max: 9999 },
            { field: 'RSB_width', type: 'number', maxLength: '4', max: 9999 },
            { field: 'RSB_depth', type: 'number', maxLength: '4', max: 9999 },
            { field: 'RSB_poids_a_vide', type: 'number', maxLength: '4', max: 9999 },
            { field: 'RSB_tension' },
            { field: 'RSB_revetement' },
            { field: 'RSB_type', maxLength: '20', max: 99999999999999999999 },
            { field: 'RSB_pression_service', type: 'number', maxLength: '4', max: 9999 },
            { field: 'RSB_pods_en_service', type: 'number', maxLength: '4', max: 9999 }
        ],
        text: 'rsb'
    }
}

export function getColumns(type, t) {
    return ProductFields[type] ? ProductFields[type].fields.map((item) => { return { ...item,
        text: `field.${item.field}`,
        render: (text, record) => { 
            return <wrap>
                        {item.type === 'boolean' && text &&
                            <wrap>
                                <item>
                                    <icon>done</icon>
                                </item>
                             </wrap>
                        }
                        <text>{item.isDecimal ? (text && text.toFixed(item.decimal)) : text }</text>
                    </wrap>
        }, 
        width: item.width || t(`field.${item.field}`).length * 7 + 20 || 100, 
        type: COLUMN_TYPE.TEXT } }) : [];
}


