import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../Shared/Translation/i18n';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { Project } from '~/project/Project';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import DetailView from '../../Shared/DetailView';
import useDetail from '../../Shared/useDetail';
import InputCont from '../../Shared/Form/InputCont';
import { message } from 'antd';

const defaultValidationFields = [];

/**
 * ...
 * @exports TarifDetails
 */
export default function TarifDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.TARIF_LIST_UPDATE;
    const [isTarif, setIsTarif] = useState(false);
    const [validationFields, setValidationFields] = useState(defaultValidationFields);

    const { isNew, dataItem, hasChanges, validation, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData } = useDetail({
        data: data || { ID: id !== "create" ? id : 0 },
        windowKey,
        updateUrl: ApiUrl.TarifUpdate,
        dataLoadUrl: ApiUrl.TarifLoad,
        deleteUrl: ApiUrl.TarifDelete,
        nameField: 'sku',
        backPage: AppPages.Tarif,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.TarifDetails),
        changingValidation: true,
        validationFields
    });

    useEffect(() => {
        if (dataItem && dataItem.active) {
            if (dataItem.type !== 'pompes') {
                setValidationFields([...defaultValidationFields,
                { name: "priceCurrent", rules: [{ type: "number" }, { type: 'required' }, { type: 'minValue', minValue: 1 }] },
                { name: "priceFuture", rules: [{ type: "number" }, { type: 'required' }, { type: 'minValue', minValue: 1 }] }
                ]);
            } else {
                setValidationFields([...defaultValidationFields,
                    {
                        name: "priceCurrent", rules: [{ type: "number" }, {
                            type: 'custom', validator: (data) => {
                                return !isNaN(data.priceCurrent) && parseInt(data.priceCurrent) >= 0;
                            }
                        }]
                    },
                    {
                        name: "priceFuture", rules: [{ type: "number" }, {
                            type: 'custom', validator: (data) => {
                                return !isNaN(data.priceFuture) && parseInt(data.priceFuture) >= 0;
                            }
                        }]
                    }
                ]);
            }
        } else {
            setValidationFields([...defaultValidationFields]);
        }
    }, [dataItem, dataItem && dataItem.active]);// eslint-disable-line react-hooks/exhaustive-deps

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    useEffect(() => {
        if (dataItem) {
            if ((dataItem.type === 'pompes' && (dataItem.priceCurrent < 0 || dataItem.priceFuture < 0)) || 
                (dataItem.type !== 'pompes' && (dataItem.priceCurrent < 0 || dataItem.priceCurrent === 0 || dataItem.priceFuture < 0 || dataItem.priceFuture === 0))) {
                message.error("Please fill positive number value.");
                setIsTarif(true) 
            } else{
                setIsTarif(false);
            }
        }
    }, [dataItem]);

    return (<>
        {dataItem && <DetailView
            hasChanges={hasChanges}
            backUrl={Project.getPageUrl(AppPages.Tarif)}
            title={isNew ? i18n('text.tarif') : dataItem.sku}
        >
            <view >
                <form>
                    <div className='form_container'>
                        <div className='section_group'>
                            <div className='sections '>
                                <InputCont label="text.sales_reference"
                                    inputProps={{ name: 'sku', value: dataItem.sku, disabled: true }} />

                                <InputCont label="text.tarif_1"
                                    inputProps={{ 
                                        validation: validation,
                                        name: 'priceCurrent',
                                        type: 'number', 
                                        // min: '0.0000000000001',
                                        value: dataItem.priceCurrent,
                                        maxLength: '15',
                                        onChange: e => console.log(e) || onChangeData({ target: { name: 'priceCurrent', value: e } }) 
                                    }} />

                                <InputCont label="text.tarif_2"
                                    inputProps={{ 
                                        validation: validation,
                                        name: 'priceFuture', 
                                        type: 'number', 
                                        // min: '0.0000000000001', 
                                        value: dataItem.priceFuture,
                                        maxLength: '15',
                                        onChange: e => onChangeData({ target: { name: 'priceFuture', value: e } }) 
                                    }} />
                            </div>
                        </div>
                    </div>
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div 
                                    onClick={(e) => updateData(updateSuccessCallback)} 
                                    className={hasChanges ? ' button primary' : 'button'} 
                                    disabled={isTarif}>
                                    <icon>save</icon>{i18n('text.save')}
                                </div>
                                {dataItem.ID > 0 && 
                                    <div className='button' onClick={(e) => {
                                        onDetailDelete(dataItem)
                                    }}>
                                        <icon>delete</icon>{i18n('text.delete')}
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>
                </form>
            </view>
        </DetailView>}
    </>);
}
