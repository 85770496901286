export const ACTION = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    REFRESH_LOGIN: 'REFRESH_LOGIN',
    CHANGE_CULTURE: 'CHANGE_CULTURE',
    CHANGE_ROLE: 'CHANGE_ROLE',
    CHANGE_NETWORK: 'CHANGE_NETWORK',
    SET_NETWORKS: 'SET_NETWORKS',
    OPEN_POPUP: 'OPEN_POPUP',
    CLOSE_POPUP: 'CLOSE_POPUP',
    UPDATE_POPUP_TITLE: 'UPDATE_POPUP_TITLE',
    CLOSE_ALL_POPUPS: 'CLOSE_ALL_POPUPS',
    SET_LIST_STATE: 'SET_LIST_STATE',
    CLEAR_LIST_STATES: 'CLEAR_LIST_STATES',
    SET_PENDING_LIST_STATE: 'SET_PENDING_LIST_STATE',
    STORAGE_CHANGE: 'STORAGE_CHANGE',
    SET_LOADERS: 'SET_LOADERS'
}

export const login = (payload) => ({
    type: ACTION.LOGIN,
    payload
});

export const logout = () => ({
    type: ACTION.LOGOUT,
});

export const refreshLogin = (payload) => ({
    type: ACTION.REFRESH_LOGIN,
    payload
});

export const changeRole = role => ({
    type: ACTION.CHANGE_ROLE,
    role
});

export const changeNetwork = network => ({
    type: ACTION.CHANGE_NETWORK,
    network
});

export const setNetworks = networks => ({
    type: ACTION.SET_NETWORKS,
    networks
});

export const changeCulture = payload => ({
    type: ACTION.CHANGE_CULTURE,
    data: payload
});

export const openPopup = payload => ({
    type: ACTION.OPEN_POPUP,
    data: payload
});
export const closePopup = payload => ({
    type: ACTION.CLOSE_POPUP,
    windowKey: payload
});

export const updatePopupTitle = payload => ({
    type: ACTION.UPDATE_POPUP_TITLE,
    title: payload.title,
    windowKey: payload.windowKey
});

export const closeAllPopups = () => ({
    type: ACTION.CLOSE_ALL_POPUPS
});

export const setListState = payload => ({
    type: ACTION.SET_LIST_STATE,
    payload
});

export const clearListStates = () => ({
    type: ACTION.CLEAR_LIST_STATES
});

export const storageChange = () => ({
    type: ACTION.STORAGE_CHANGE
});

export const setLoaders = payload => ({
    type: ACTION.SET_LOADERS,
    payload
});

