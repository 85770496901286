import React from 'react';
import { connect } from "react-redux";
import { Link, /*withRouter*/ } from 'react-router-dom';
import { withRouter } from '../../hook/withRouter';

import i18n from '../../Shared/Translation/i18n';
import { ProductFields } from '../../pages/Products/CommonMethods';
import { Project } from '../../project/Project';
import { AppPages } from '../../project/Defines';
import { closeAllPopups } from '../../store/actions';


export const menuItems = [
	{ to: AppPages.Administrator, text: i18n('text.administrators') },
	{ to: AppPages.SalesNetwork, text: i18n('text.sales_networks') },
	{ to: AppPages.SalesNetworkConfig, text: i18n('text.sales_networks') },
	{ to: AppPages.Translations, text: i18n('text.translations') },
	{ to: AppPages.UITranslations, text: i18n('text.translations') },
	{
		text: i18n('text.products'),
		subMenu: Object.keys(ProductFields).map(k => ({ to: AppPages.Product, routeParams: k, text: i18n('text.' + ProductFields[k].text), detail: AppPages.ProductDetails })),
		expandDefault: true
	},
	{
		text: i18n('text.products'),
		subMenu: Object.keys(ProductFields).map(k => ({ to: AppPages.ProductSalesNetwork, routeParams: k, text: i18n('text.' + ProductFields[k].text), detail: AppPages.ProductSalesNetworkDetails })),
		expandDefault: true
	},
	{ to: AppPages.Tarif, text: i18n('text.tarif') },
	{ to: AppPages.Faq, text: i18n('text.faq') },
	{ to: AppPages.Version, text: i18n('text.version') },
	{ to: AppPages.Statistics, text: i18n('text.statistics') }


];

/**
 * The layout component specifies the site layout, top menu based on login state and active states of top menu items
 * @param {object} props  { isLoggedIn, popups, culture, role, etc.}
 */
function NavigationMenu(props) {
	const { role, closeAllPopups, culture } = props;
	let location = window.location.pathname.toLowerCase();
	return <>
		<nav launcher="" id="launcher">
			<ul>
				{menuItems.map((item, i) => {// NOSONAR
					const url = Project.getPageUrl((item.to && Project.hasRoleAccess(role, item.to)) ? item.to : item.subMenu && [...item.subMenu].sort(sm => sm.isDefault ? -1 : 1).find(sub => Project.hasRoleAccess(role, sub.to))?.to) || "";
					const selected = (url && url.toLowerCase() === location) || (item.detail && location.startsWith(Project.getPageUrl(item.detail, item.routeParams) + "/"))
						|| (item.subMenu && item.subMenu.some(sub => location.startsWith(Project.getPageUrl(sub.to, sub.routeParams)) || (sub.detail && location.startsWith(Project.getPageUrl(sub.detail, sub.routeParams)))))
						|| (item.pages && item.pages.map(p => Project.getPageUrl(p)).indexOf(location) !== -1);
					if (selected) {
						document.onkeydown = (e) => {
							const keyF1 = 'F1';

							if (e.key === keyF1) {
								if (!item.help.default) {
									e.preventDefault();
									const urlPDF = `/help/${(item.help.fr && culture === "fr") ? item.help.fr : item.help.en}`;
									window.open(urlPDF, "_blank");
								}

								return;//NOSONAR
							}
						};
					}
					if (!url) {
						return <div
							key={i} // NOSONAR
							style={{ display: 'none' }}></div >;
					}
					return <React.Fragment
						key={i} // NOSONAR
					>
						<li className={selected ? "selected" : ""} {... (item.expandDefault && { expanddefault: '' })}>
							{
								!item.expandDefault ? <Link to={url} onClick={closeAllPopups} >
									<text>{item.text}</text>
								</Link> :
									<>
										<ul>
											<li>
												<text >{item.text}</text>
											</li>
											<li className="separator"></li>
										</ul>
								</>
							}
						</li>
						{(item.expandDefault || selected) && item.subMenu && item.subMenu.length > 0 && < ul >
							{
								item.subMenu.map((subItem, i) => {
									const url = (Project.hasRoleAccess(role, subItem.to) && Project.getPageUrl(subItem.to, subItem.routeParams)) || "";
									return url ? <li
										key={i} // NOSONAR
										className={url.toLowerCase() === location || (subItem.detail && location.startsWith(Project.getPageUrl(subItem.detail, subItem.routeParams) + "/")) ? "selected" : ""}>
										<Link to={url} onClick={closeAllPopups} ><text>{subItem.text}</text></Link>
									</li> : <div
											key={i} // NOSONAR
											style={{ display: 'none' }}></div >
								})
							}
						</ul>
						}
					</React.Fragment>

				})}
			</ul>
		</nav>

	</>
}
export default connect(state => ({
	role: state.role,
	culture: state.culture,
	userData: state.userData,
	isLoggedIn: state.isLoggedIn
}),
	dispatch => ({ closeAllPopups: () => dispatch(closeAllPopups()) }))(withRouter(NavigationMenu));