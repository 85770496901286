import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { connect, useDispatch } from "react-redux";
import { TemplateDetails, TemplateServiceCallDetails } from '../pages/Template';
import { closePopup, openPopup } from './../store/actions';
import i18n from '../Shared/Translation/i18n';
import ProductSalesNetworDocumentkDetails from '../pages/ProductSalesnetworkDoacument/detail'
import { useCustomEvent } from '../project/utilities';

/**
 * specifies all the popups without navigation
 */
export const PopupTypes = {
    Confirm: "Confirm",
    Prompt: "Prompt",
    TemplateDetails: "TemplateDetails",
    TemplateServiceCallDetails: "TemplateServiceCallDetails",
    Document: "Document"
}

export const PopupClasses = {
    Small: "small",
    Medium: "medium",
    Large: "large",
    Popup: "popup",
}

/**
 * the main function generating all opened popups
 * @param {object} props  contains props for fulscreen, class, all opened popups info
 */
function Popups(props) {
    let { popups, fullScreen } = props;
    popups = (popups && popups.filter(item => (item.fullScreen || false) === (fullScreen || false))) || [];
    let window_container = (popups.length > 0) ? "window_container open" : "window_container";
    return <div className={window_container}>
        {
            popups.map((item, i) => {
                let closeOutside = false;
                let ComponentVar;
                const { type, bodyProps, className, disableOutsideClick } = item;
                let open_class = type + (item.fullScreen ? " open fullscreen" : " open");
                const onClose = (e) => props.closePopup(item.windowKey);
                let skipCloseClassNames = '';
                switch (type) {
                    case PopupTypes.Confirm:
                        return <InnerPopup
                            key={type + i} // NOSONAR
                            className="open"
                            onClose={onClose} // NOSONAR
                            closeOnOutsideClick={disableOutsideClick ? false : true}>
                            <Confirm {...item}
                                onClose={onClose} // NOSONAR
                            />
                        </InnerPopup>;

                    case PopupTypes.Prompt:
                        return <InnerPopup
                            key={type + i} // NOSONAR
                            className="open"
                            onClose={onClose} closeOnOutsideClick={false} // NOSONAR
                        >
                            <Prompt {...item} onClose={onClose} // NOSONAR
                            />
                        </InnerPopup>;

                    case PopupTypes.TemplateDetails:
                        ComponentVar = TemplateDetails;
                        break;
                    case PopupTypes.TemplateServiceCallDetails:
                        ComponentVar = TemplateServiceCallDetails;
                        break;
                    case PopupTypes.Document:
                        ComponentVar = ProductSalesNetworDocumentkDetails;
                        break;
                    case PopupTypes.Popup:
                        ComponentVar = item.component;
                        break;
                    default:
                        return <div key={i} // NOSONAR
                        ></div>;
                }
                return <InnerPopup
                    key={type + i} // NOSONAR
                    className={`${open_class} ${className || ''}`}
                    onClose={onClose} // NOSONAR
                    skipCloseClassNames={skipCloseClassNames}
                    closeOnOutsideClick={i === popups.length - 1 && closeOutside}>
                    <PupupWrapper {...item} onClose={onClose} // NOSONAR
                    >
                        <ComponentVar {...bodyProps} onClose={onClose} // NOSONAR
                        />
                    </PupupWrapper>
                </InnerPopup>
            })
        }
    </div>
}
export default connect(state => ({
    popups: state.popups
}),
    dispatch => ({
        closePopup: (key) => dispatch(closePopup(key))
    })
)(Popups);

/**
 *custom hook for running logic on outer click of specified element with given ref
 * @param {func} onOuterClick
 * @param {object} innerRef the ref of element
 * @param {skipCloseClassNames} skipCloseClassNames
 * @param {bool} enable
 */
function useOuterClickNotifier(onOuterClick, innerRef, skipCloseClassNames, enable) {
    useEffect(
        () => {
            if (innerRef.current && enable) {
                document.addEventListener("click", handleClick);
            }

            return () => document.removeEventListener("click", handleClick);

            function handleClick(e) {
                innerRef.current &&
                    (innerRef.current === e.target || !innerRef.current.contains(e.target)) && !(document.getElementsByClassName(skipCloseClassNames)[0] && document.getElementsByClassName(skipCloseClassNames)[0].contains(e.target)) &&
                    onOuterClick(e);
            }
        },
        [enable, innerRef, skipCloseClassNames, onOuterClick] // invoke again, if inputs have changed
    );
}

/**
 * the outer warpper of all popups (for each one)
 * @param {any} props className, children, onClose, skipCloseClassNames, closeOnOutsideClick
 */
function InnerPopup(props) {
    const { className, children, onClose, skipCloseClassNames, closeOnOutsideClick } = props;
    const innerRef = useRef(null);

    useOuterClickNotifier(
        e => { onClose() },
        innerRef,
        skipCloseClassNames,
        closeOnOutsideClick
    );

    return (
        <div ref={innerRef} className={className}>
            {children}
        </div>
    );
}

/**
 *custom confirm popup
 * @param {object} props contains title, text, yesCallback, noCallback, buttonYesText, buttonNoText, onClose
 */
function Confirm(props) {
    const { title, text, yesCallback, noCallback, buttonYesText, buttonNoText, onClose } = props;
    const [, setUpdating] = useState(false);

    let onYesButtonClick = useCallback(function (e) {
        setUpdating(true);
        yesCallback && yesCallback(e);
        onClose();
    }, [yesCallback, onClose]);

    let onNoButtonClick = useCallback(function (e) {
        noCallback && noCallback();
        onClose();
    }, [noCallback, onClose]);

    return <div>
        <div>
            <div className="message_box">
                <div className="message_container">
                    <icon large="">question</icon>
                    <h3>{title}</h3>
                    <p>{text}</p>
                </div>
                <panel>
                    <button type="button" effect="material" command="yes" className="button primary" onClick={onYesButtonClick}>
                        <text>{buttonYesText}</text>
                    </button>
                    <separator vertical=""></separator>
                    <button type="button" effect="material" command="no" className="button accent" onClick={onNoButtonClick}>
                        <text>{buttonNoText}</text>
                    </button>
                </panel>
            </div>
        </div>
    </div>;
}

Confirm.defaultProps = {
    title: "",
    text: "",
    htmlText: false,
    buttonYesText: "OK",
    buttonNoText: "Cancel"
}

/**
 *custom prompt popup
 * @param {object} props contains title, text, yesCallback, noCallback, buttonYesText, buttonNoText, onClose
 */
function Prompt(props) {
    const { title, isPassword, placeholder, yesCallback, noCallback, buttonYesText, buttonNoText, onClose } = props;
    const [, setUpdating] = useState(false);
    const [value, setValue] = useState('');

    let onYesButtonClick = useCallback(function (e) {
        setUpdating(true);
        yesCallback && yesCallback(e, value);
        onClose();
    }, [yesCallback, onClose, value]);

    let onNoButtonClick = useCallback(function (e) {
        noCallback && noCallback();
        onClose();
    }, [noCallback, onClose]);

    return <div>
        <div>
            <div className="message_box">
                <div className="message_container">
                    <h3>{title}</h3>

                    <form>
                        <div className="form_container">
                            <div className="section_group">
                                <div className="sections ">
                                    <div className="form_fields">
                                        <input type={isPassword ? "password" : "text"} value={value} placeholder={placeholder} onChange={(e) => setValue(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <panel>
                    <button type="button" effect="material" command="yes" className="button primary" onClick={onYesButtonClick}>
                        <text>{buttonYesText}</text>
                    </button>
                    <separator vertical=""></separator>
                    <button type="button" effect="material" command="no" className="button accent" onClick={onNoButtonClick}>
                        <text>{buttonNoText}</text>
                    </button>
                </panel>
            </div>
        </div>
    </div>;
}

Prompt.defaultProps = {
    title: "",
    isPassword: false,
    placeholder: '',
    buttonYesText: "OK",
    buttonNoText: "Cancel"
}

/**
 * default carcas for all popups except confirm
 * @param {any} props
 */
function PupupWrapper(props) {
    const dispatch = useDispatch();
    const { title, fullScreen, showHeader, children, cancelCallback, onClose } = props;
    const [hasChange, setHasChange] = useState(false);

    const detailChanges = useCallback((data) => {
        setHasChange(data.detail.hasChange);
    }, []);

    useCustomEvent(props.windowKey, detailChanges);

    let onCancel = useCallback(function (e) {
        if (hasChange) {

            dispatch(openPopup({
                windowKey: 'wndConfirmToClose',
                type: PopupTypes.Confirm,
                title: i18n('message.close_confirm_title'), //'You have unsaved changes.',
                text: i18n('message.close_confirm'), //'Do you want to continue? All data will be lost.',
                buttonYesText: i18n('text.yes'),
                buttonNoText: i18n('text.no'),
                yesCallback: function () {
                    cancelCallback && cancelCallback(e);
                    onClose();
                }
            }));

            return;
        }

        cancelCallback && cancelCallback(e);
        onClose();
    }, [onClose, cancelCallback, hasChange, dispatch]);

    let innerContent =
        <div className="box no-cursor">
            {showHeader &&
                <header>
                    {
                        fullScreen &&
                        <>
                            <div effect='material' className='button white mini back' command='close' onClick={onCancel}>
                                <icon>left</icon>
                            </div>
                            <separator vertical=""></separator>
                        </>
                    }

                    <div style={{ width: 'calc(100% - 20px)' }}>
                        <strong className="cursor"><div><p>{i18n(title)}</p></div></strong>

                    </div>

                    {
                        !fullScreen &&
                        <div effect="material" className="button white mini close" command="close" onClick={onCancel}>
                            <icon>close</icon>
                        </div>
                    }
                </header>
            }
            <div>
                <div className="window_cont">
                    {children}
                </div>
            </div>
        </div>

    if (!fullScreen) {

        innerContent = <Draggable handle="strong">
            {innerContent}
        </Draggable>
    }
    return innerContent;
}

PupupWrapper.defaultProps = {
    showHeader: true,
    fullScreen: true,
}