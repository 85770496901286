import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../Shared/Translation/i18n';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import DetailView from '../../Shared/DetailView';
import useDetail from '../../Shared/useDetail';
import InputCont from '../../Shared/Form/InputCont';


const validationFields = ['code', 'description'];

/**
 * ...
 * @exports UITranslationDetails
 */
export default function UITranslationDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.UI_TRANSLATIONS_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, hasChanges, closePopup, /*onDetailDelete,*/ detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : 0 },
        windowKey,
        updateUrl: ApiUrl.UITranslationsUpdate,
        dataLoadUrl: ApiUrl.UITranslationsLoad,
        deleteUrl: ApiUrl.UITranslationsDelete,
        nameField: 'code',
        backPage: AppPages.UITranslations,
        listUpdateEvent,
        changingValidation: true,
        validationFields: validationFields
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);


    return (<>
        {dataItem && <DetailView
            hasChanges={hasChanges}
        >
            <view >
                {/* <form> */}
                <div className="form_container">

                    <div className="section_group">
                        <div className="sections">

                            <InputCont label="text.code"
                                inputProps={{ validation, name: 'code', value: dataItem.code, onChange: onChangeData }} />

                            <InputCont label="text.description"
                                inputProps={{ validation, name: 'description', value: dataItem.description, onChange: onChangeData, disabled: !isNew }} />

                        </div>

                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div
                                onClick={(e) => updateData(updateSuccessCallback)}
                                className={hasChanges ? ' button primary' : 'button'}
                            ><icon>save</icon>{i18n('text.save')}</div>
                        </action>
                    </wrap>
                </toolbar>
                {/* </form> */}
            </view>
        </DetailView>}
    </>);
}
