import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18n from '../../Shared/Translation/i18n';
import { ApiUrl } from '../../project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import InputCont from '../../Shared/Form/InputCont';
import DetailView from '../../Shared/DetailView';
import useDetail from '../../Shared/useDetail';

function ProductSalesNetworDocumentkDetails(props) {
    const { id } = useParams();
    const { windowKey, data /*, network*/ } = props;

    const listUpdateEvent = EVENT_NAME.UPDATE_PRODUCT_DOCUMENT_LIST;

    const { /*isNew,*/ dataItem, setDataItem, updateData, hasChanges, closePopup, /*onDetailDelete,*/ detailRef, onChangeData, validation /*, isLoading, loadData*/ } = useDetail({
        data: data || { ID: id || 0 },
        windowKey,
        updateUrl: ApiUrl.ProductSalesNetworkDocumentUpdate,
        dataLoadUrl: ApiUrl.ProductSalesNetworkDocumentLoad,
        changingValidation: true,
        validationFields: ['nameDoc',]
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem &&
            <DetailView
                hasChanges={hasChanges}
                showPopup
            >
            <view>
                <form>
                    {/* <TabStrip onChange={(selected) => setTabIndex(selected)} skipSecondaryTabs={isNew} selectedIndex={tabIndex}>
                        <div title='text.translations'>
                            <Translation
                                ID={dataItem.ID}
                                networkId={network}
                                referenceTable={'document'}
                                fields={['nameDoc']}
                                showField
                                updateEvent={EVENT_NAME.UPDATE_PRODUCT_DOCUMENT_TRANSLATIONS}
                                updateCallback={updateSuccessCallback}
                            />
                        </div>
                    </TabStrip> */}

                    <div className='form_container'>
                        <div className='section_group'>
                            <div className='sections '>
                                <InputCont label="text.language"
                                    inputProps={{ name: 'language', value: dataItem.language, disabled: true }} />

                                <InputCont label="text.url"
                                    inputProps={{ name: 'url', value: dataItem.url, disabled: true }} />

                                <InputCont label="text.name_doc"
                                    inputProps={{ 
                                        validation,
                                        name: 'nameDoc', 
                                        value: dataItem.nameDoc, 
                                        onChange: onChangeData 
                                    }} />

                                <InputCont 
                                    label='text.type_doc' 
                                    inputProps={{
                                        // searchOptions: {
                                        //     noAccent: true,
                                        //     contains: true
                                        // },
                                        name: 'typeDoc',
                                        type: 'select',
                                        value: dataItem.typeDoc,
                                        valuelist: 'typeDoc',
                                        onChange: e => onChangeData({target: {name: 'typeDoc', value: e}})
                                    }} 
                                />
                            </div>
                        </div>
                    </div>

                    <toolbar>
                        <wrap>
                            <action right=''>
                                    <div onClick={(e) => updateData(updateSuccessCallback)} className={hasChanges ? ' button primary' : 'button'}><icon>save</icon>{i18n('text.save')}</div>
                            </action>
                        </wrap>
                    </toolbar>
                </form>
                </view>
            </DetailView>
        }
    </>);
}
export default connect(state => {
    return {
        network: state.network,
    }
})(ProductSalesNetworDocumentkDetails);