import React from 'react';

import { AppPages, Defines, UserRoles } from './Defines.js';
import moment from 'moment';


/**
 *a global helper object with utility functions
 */
export const Project = {
    getMonday: function (d) {
        d = new Date(d);
        let day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    },
    getNextSunday: function (d) {
        d = new Date(d);
        let day = d.getDay(),
            diff = d.getDate() - day + (day > 0 ? 7 : 0); // adjust when day is sunday
        return new Date(d.setDate(diff));
    },

    getFullAddress: function (json) {
        return ("")
            .addItem(json.address1, ", ", true)
            .addItem(json.address2, ", ", true)
            .addItem(json.address3, ", ", true)
            .addItem(json.city, json.addressDelimiter || ", ", true)
            .addItem(json.state, ", ", true)
            .addItem(json.zipPostal, " ", true)
            .addItem(json.country, json.countryDelimiter || ", ", true);
    },

    getLocationAddress: function (data) {
        return Project.getFullAddress({
            address1: data.Address1,
            address2: data.Address2,
            city: data.City,
            state: data.State,
            zipPostal: data.ZipPostal,
            country: data.CountryName
        });
    },

    getContactInformation: function (json) {
        let s = ("")
            .addItem(json.firstName, ", ", true)
            .addItem(json.lastName, " ", true);

        if (!String.isNullOrEmpty(json.title)) {
            s += " (" + json.title + ")";
        }

        return s.addItem([json.phone, json.email], ', ', true);
    },

    formatName: function (firstName, lastName) {
        let name = firstName;
        if (lastName) {
            name += " " + lastName;
        }

        return name;
    },

    formatDuration: function (milliseconds, skipEmpty) {
        if (skipEmpty && !milliseconds) {
            return "";
        }

        if (!milliseconds) {
            return "0:00";
        }

        let seconds = Math.round(milliseconds / 1000),
            hr = Math.floor(seconds / 3600),
            min = Math.floor((seconds - (hr * 3600)) / 60),
            sec = seconds - (hr * 3600) - (min * 60);

        return (hr > 0 ? hr + ":" : "") + (hr > 0 && min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
    },

    formatFullDate: function (dt) {
        return moment((dt ? new Date(dt) : new Date()), Defines.Format.MomentDate);
    },

    formatFullTime: function (dt) {
        return moment((dt ? new Date(dt) : new Date()), Defines.Format.MomentFullTime);
    },

    formatFullDateTime: function (dt) {
        return dt ? moment(new Date(dt), Defines.Format.MomentDateTime) : null;
    },

    formatFullDateString: function (dt) {
        return dt ? moment(new Date(dt), Defines.Format.MomentDate).format(Defines.Format.MomentDate) : "";
    },

    formatFullTimeString: function (dt) {
        return dt ? moment(new Date(dt), Defines.Format.MomentFullTime).format(Defines.Format.MomentFullTime) : "";
    },

    formatFullDateTimeString: function (dt) {
        return dt ? moment(dt, Defines.Format.MomentDateTime).format(Defines.Format.MomentDateTime) : "";
    },

    formatTime: function (dt) {
        return dt ? moment(dt, Defines.Format.MomentDateTime).format(Defines.Format.MomentTime) : "";
    },

    formatToPrice(number) {
        return number.toLocaleString('fr-FR', { style: "currency", currency: "EUR" });
    },

    round2Decimals(number) {
        return Math.round(number * 100) / 100;
    },

    encode: str => {
        let buf = [];

        for (let i = str.length - 1; i >= 0; i--) {
            buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
        }

        return buf.join('');
    },

    decode: str => {
        return str.replace(/&#(\d+);/g, function (match, dec) {
            return String.fromCharCode(dec);
        });
    },

    getPageUrl: function (page, routeParams, quaryParams) {
        if (!page) {
            return null;
        }

        let url = "";
        switch (page.toLowerCase()) {
            case AppPages.Home:
                url = "/";
                break;
            case AppPages.Login:
            case AppPages.Logout:
            case AppPages.Template:
            case AppPages.TemplateDetail:
            case AppPages.Administrator:
            case AppPages.AdministratorDetails:
            case AppPages.Product:
            case AppPages.ProductDetails:
            case AppPages.ProductSalesNetwork:
            case AppPages.ProductSalesNetworkDetails:
            case AppPages.Tarif:
            case AppPages.TarifDetails:
            case AppPages.Translations:
            case AppPages.UITranslations:
            case AppPages.UITranslationDetails:
            case AppPages.SalesNetwork:
            case AppPages.SalesNetworkDetails:
            case AppPages.SalesNetworkConfig:
            case AppPages.Faq:
            case AppPages.FaqDetails:
            case AppPages.Version:
            case AppPages.VersionDetails:
            case AppPages.Statistics:
                url = `/${page.toLowerCase()}`;
                break;
            default:
                url = "/";
                break;
        }

        if (routeParams) {
            url += "/" + (Array.isArray(routeParams) ? routeParams.join('/') : routeParams);
        }

        if (quaryParams) {
            let p = [];
            for (let key in quaryParams) { p.push(key + "=" + quaryParams[key]) }
            url += "?" + p.join("&");
        }

        return url;
    },

    getPages: function () {
        return ["Login", "Logout"];
    },
    hasRoleAccess: function (userRole, appPage) {
        const checkingRoles = Project.getPageRoles(appPage);

        if (!checkingRoles) {
            return true;
        }
        switch (userRole) {
            case UserRoles.ADMIN:
                return checkingRoles.includes(UserRoles.ADMIN);
            case UserRoles.SUPERADMIN:
                return checkingRoles.includes(UserRoles.SUPERADMIN);
            default:
                return false;
        }
    },
    getPageRoles: function (appPage) {
        switch (appPage) {
            case AppPages.NotFound:
            case AppPages.AccessDenied:
            case AppPages.Home:
            case AppPages.Login:
            case AppPages.Logout:
            case AppPages.Register:
            case AppPages.Forgot:
            case AppPages.Template:
            case AppPages.TemplateDetail:
            case AppPages.Statistics:
                return null;
            case AppPages.SalesNetworkConfig:
            case AppPages.ProductSalesNetwork:
            case AppPages.ProductSalesNetworkDetails:
            case AppPages.Tarif:
            case AppPages.TarifDetails:
            case AppPages.Translations:
            case AppPages.TranslationsDetails:
            case AppPages.Faq:
            case AppPages.FaqDetails:
                return [UserRoles.ADMIN];
            default:
                return [UserRoles.SUPERADMIN];
        }
    },
    skipHeader: function (location) {
        const skipping_pages = [AppPages.Login, AppPages.Logout, AppPages.ExternalCard];
        return skipping_pages.some(sp => location.includes(sp));
    },

    restrictBrowser: function (location) {
        const skipping_pages = [AppPages.ExternalCard];

        return !skipping_pages.some(sp => location.includes(sp));
    },

    getColorAction: function (text) {
        switch(text) {
            case "Add":
                return <badge new="">{text}</badge>;
            case "Update":
                return <badge private="">{text}</badge>;
            case "Error":
                return <badge error="">{text}</badge>;
            case "Warning":
                return <badge error="">{text}</badge>;
            default:
                return <></>
        }
    },
    getRecordActionClass: function (record) {
        switch (record.Action) {
            case "Add":
                return "blue";
            case "Update":
                return "green";
            case "Error":
                return "red";
            case "Warning":
                return "yellow";
            default:
                return "";
        }
    },
    getImportActionColumn: (columnType, fixed) => {
        return {
            text: 'text.action',
            field: 'Action',
            render: (text, record) => {
                return (
                    <wrap>
                        <group compact="">
                            {Project.getColorAction(text)}
                        </group>
                    </wrap>
                );
            },
            type: columnType,
            width: 100,
            fixed: fixed
        };
    },
    getImportMessageColumn: (columnType, fixed) => {
        return {
            text: 'text.message',
            field: 'Message',
            type: columnType,
            width: 400,
            // fixed: 'right'
            fixed: fixed
        };
    }

}