import { Dropdown, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Ajax } from '../../components/Ajax';
import { Input } from '../../components/Translations';
import { ApiUrl } from '../../project/Defines.js';
import { useCustomEvent } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { useSWCache } from '../../Shared/SWCache';

const { Option } = Select;

/**
 * specific case of DropDownValueList with 'multiple' mode
 * @param {any} props valuelist, items, parentValue, additionalData, reloadEventName, ...restprops
 */
export function MultiSelectValueList(props) {
    return <DropDownValueList {...props} mode='multiple' />
}

export function DoLoadValueList(ref, valuelist, parentValue, additionalData, disableCach, setCacheData, setDataSource, manageValue, setLoaded) {// NOSONAR
    if (valuelist) {
        if (typeof valuelist === 'string') {
            Ajax.post({
                url: ApiUrl.ValueList,
                data: {
                    valueList: valuelist,
                    parentValue: parentValue || "",
                    additionalData: additionalData || ""
                },
                success: function (response) {
                    if (response && ref.current) {
                        const data = response;
                        for (const element of data) {
                            element.key = element.uuid;
                        }
                        setDataSource(data);
                        setLoaded(true);
                        manageValue && manageValue(data);
                        !disableCach && setCacheData(data);
                    }
                }
            });
        } else {
            setDataSource(valuelist);
            setLoaded(true);
            manageValue && manageValue(valuelist);
        }
    }
}


/**
 * custom DropDownValueList working with backend value lists and custom given items
 * @param {object} props   valuelist, mode, items, parentValue, additionalData, reloadEventName, ...restprops
 */
export function DropDownValueList(props) {
    const [loaded, setLoaded] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const { value, restrictCustomValue, allowClear, valuelist, mode, size, items, parentValue, additionalData, reloadEventName, disableCach, culture, autoSelectFirst, dispatch, searchOptions, textAsValue, disableScroll, ...restprops } = props;
    const ref = useRef(false);

    const { getCacheData, setCacheData } = useSWCache({ key: `${culture}_${valuelist}`, isClearOnLogout: true });
    const manageValue = useCallback((data) => {
        if ((restrictCustomValue && !mode) && value && !data.some(item => item[textAsValue ? 'text' : 'value'] === value)) {
            props.onChange && props.onChange(autoSelectFirst ? data[0].value : '');
        } else if (autoSelectFirst && !value) {
            props.onChange && props.onChange(data[0].value);
        }

    }, [value, autoSelectFirst, textAsValue, restrictCustomValue, mode, props])

    const loadData = useCallback(() => {// NOSONAR
        DoLoadValueList(ref, valuelist, parentValue, additionalData, disableCach, setCacheData, setDataSource, manageValue, setLoaded);
    }, [valuelist, parentValue, additionalData, autoSelectFirst, disableCach, setCacheData, manageValue]);// eslint-disable-line react-hooks/exhaustive-deps

    useCustomEvent(reloadEventName || 'reload_valuelist_' + valuelist, loadData);

    useEffect(() => {
        ref.current = true;
        if (items) {
            setDataSource([...items]);
            setLoaded(true);
            manageValue(items);
        }
        else {
            if (disableCach) {
                loadData();
            } else {
                getCacheData().then((data) => {
                    if (ref.current) {
                        if (data) {
                            setDataSource(data);
                            setLoaded(true);
                            manageValue(data);
                        } else {
                            loadData();
                        }
                    }
                });
            }
        }
        return () => { ref.current = false }
    }, [additionalData, valuelist, parentValue]);// eslint-disable-line react-hooks/exhaustive-deps

    const filterOption = (input, option) => {
        let text = searchOptions.caseSensitive ? option.text : option.text && option.text.toLowerCase();
        let searchInput = searchOptions.caseSensitive ? input : input && input.toLowerCase();
        if (searchOptions.noAccent) {
            text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            searchInput = searchInput.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        return !searchInput || (text && (searchOptions.contains ? text.includes(searchInput) : text.startsWith(searchInput)));
    };


    return <>
        {loaded && <Select
            allowClear={allowClear}
            size={size || "default"}
            mode={mode}
            virtual={true}
            value={value}
            {...restprops}
            onChange={(e) => props.onChange(e, dataSource)} // NOSONAR
            filterOption={searchOptions && filterOption}
            showSearch={!!searchOptions}
            {...(!disableScroll ? { getPopupContainer: (trigger) => trigger.parentElement } : null)}
        >
            {
                dataSource.map((item, i) =>
                    <Option
                        key={i} // NOSONAR
                        value={textAsValue ? item.text : item.value} text={item.text}>
                        {item.text}
                    </Option>
                )
            }
        </Select>}
    </>
}

/**
 * custom MultiColumnDropDown working with backend value lists and custom given items
 * @param {object} props   name, valuelist, searchOptions, parentValue, label, required, disabled, columns, listLoadEvent, fieldsToShow, additionalData, disableScroll, value, ...restprops
 */
export const MultiColumnDropDown = (props) => {
    const { name, valuelist, searchOptions, parentValue, label, required, disabled, columns, listLoadEvent, fieldsToShow, additionalData, disableScroll, value, onChange, renderText, valueAsText, showHeader, textField, valueField, noPaging, skipNewButton, ...restprops } = props;
    const [inputVal, setInputVal] = useState("");
    const [data, setData] = useState([]);
    const [focusStyle, setFocusStyle] = useState(false);
    const [visible, setVisible] = useState(false);
    const [placeholder, setPlaceholder] = useState("");
    const ref = useRef(false);
    const inputRef = useRef();

    useEffect(() => {
        loadData();
    }, [valuelist, parentValue]);// eslint-disable-line react-hooks/exhaustive-deps

    const filterOption = useCallback((input, data) => {// NOSONAR
        let searchInput = searchOptions.caseSensitive ? input : input && input.toLowerCase();
        let includes = false;
        let filteredData = [];
        let text = "";

        if (searchOptions.noAccent) {
            searchInput = searchInput.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }

        filteredData = data.filter((d) => {
            for (let [key, value] of Object.entries(d)) {
                if (key !== "ID") {
                    text = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                    includes = searchOptions.contains ? text.includes(searchInput) : text.startsWith(searchInput);

                    if (includes) {
                        break;
                    }
                }
            }

            return includes;
        });

        return filteredData;
    }, [searchOptions]);

    const loadData = useCallback((input) => {
        Ajax.post({
            url: ApiUrl.ValueList,
            data: {
                valueList: valuelist,
                parentValue: parentValue || "",
                additionalData: additionalData || ""
            },
            success: function (response) {
                if (response.length > 0) {
                    let _data = input ? filterOption(input, response) : response;

                    _data = _data.map(item => {
                        item.key = item.ID;

                        return item;
                    });

                    setData(_data);
                } else {
                    setData([]);
                }
            }
        });
    }, [additionalData, valuelist, parentValue, filterOption]);

    const onSelect = useCallback((record) => {
        let text = '';

        if (renderText) {
            text = renderText(record);
        } else if (textField) {
            text = record.textField;
        } else if (valueField) {
            text = record.valueField;
        }

        setInputVal(text);
        setPlaceholder(text);

        onChange && onChange(record);

        setVisible(false);
    }, [onChange, renderText, textField, valueField]);

    const onOpen = () => {
        ref.current = true;
        setVisible(true);
        setInputVal("");
    };

    const handleVisibleChange = (isVisible) => {
        inputRef.current.focus();
        setVisible(isVisible);

        if (isVisible) {
            setInputVal("");
        } else {
            setInputVal(placeholder);
        }
    };

    const overlay = (
        <view className="ant-dropdown-menu multi-column-dropdown" >
            <ListView
                dataSource={data}
                columnConfig={columns}
                noPaging={noPaging}
                apiUrl={ApiUrl.ValueList}
                listUpdateEvent={listLoadEvent}
                data={{ ...additionalData }}
                onClick={(record) => { // NOSONAR
                    onSelect(record);
                }}
                skipNewButton={skipNewButton}
                showHeader={showHeader}
                className={"list"}
                rowClassName={(record) => { // NOSONAR
                    return (value && value === record.ID ? "select" : "");
                }}
            />
        </view>
    );

    return <Dropdown
        disabled={disabled}
        className={focusStyle && "ant-select-focused "}
        trigger={['click']}
        overlay={overlay}
        overlayStyle={{ width: 500, maxHeight: 300, overflow: "auto" }}
        open={visible && !disabled}
        onFocus={() => setFocusStyle(true)} // NOSONAR
        onBlur={() => setFocusStyle(false)} // NOSONAR
        onOpenChange={handleVisibleChange} // NOSONAR
        {...(!disableScroll ? { getPopupContainer: (trigger) => trigger.parentElement } : null)}
        {...restprops}
    >
        <div label={label} className="ant-select ant-select-selector " onClick={onOpen}>
            <span className="ant-select-selection-item">
                <Input autoComplete="off"
                    ref={inputRef}
                    style={{ width: "100%" }}
                    disabled={disabled}
                    value={inputVal}
                    onChange={(e) => { // NOSONAR
                        loadData(e.target.value);
                        setInputVal(e.target.value);
                    }}
                    name={name}
                    required={required}
                    placeholder={placeholder}
                />
            </span>
            <span className="ant-select-arrow">
                <span role="img" aria-label="down" className={"anticon anticon-" + (visible ? "search" : "down") + " ant-select-suffix"}>
                </span>
            </span>
        </div>
    </Dropdown>
}

MultiColumnDropDown.propTypes = {
    additionalData: PropTypes.object,
    serverSideFiltering: PropTypes.bool,
    hasSearch: PropTypes.bool

};
MultiColumnDropDown.defaultProps = {
    additionalData: {},
    serverSideFiltering: true,
    hasSearch: true
};

export default connect(state => ({ culture: state.culture }))(DropDownValueList);