import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from '../../Shared/Translation/i18n';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { Project } from '../../project/Project';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import DetailView from '../../Shared/DetailView';
import InputCont from '../../Shared/Form/InputCont';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { TabStrip } from '../../Shared/TabStrip';
import useDetail from '../../Shared/useDetail';
import { ProductFields } from './CommonMethods';


export default function ProductDetails(props) {// NOSONAR
    const navigate = useNavigate();
    const { id, type } = useParams();
    const { windowKey, data } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const listUpdateEvent = EVENT_NAME.PRODUCT_LIST_UPDATE;
    const { isNew, dataItem, setDataItem, updateData, hasChanges, /*closePopup,*/ onDetailDelete, detailRef, onChangeData, validation /*, isLoading, loadData*/ } = useDetail({
        data: data || { ID: id !== 'create' ? id : 0 },
        windowKey,
        updateUrl: ApiUrl.ProductUpdate,
        additionalData: { type },
        dataLoadUrl: ApiUrl.ProductLoad,
        dataLoadParams: { id: id !== 'create' ? id : 0, type },
        deleteUrl: ApiUrl.ProductDelete,
        nameField: 'sku',
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.ProductDetails, type),
        // changingValidation: true,
        validationFields: ['brandId', 'sku']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
            dispatchCustomEvent(EVENT_NAME.UPDATE_PRODUCT_TRANSLATIONS, response);
        }
    }, [setDataItem, detailRef]);
    return (<>
        {dataItem && <DetailView
            hasChanges={hasChanges}
            backUrl={Project.getPageUrl(AppPages.Product, type)}
            title={ i18n('text.' + ProductFields[type].text)}
        >
            <view>
                <form>
                    <TabStrip onChange={(selected) => setTabIndex(selected)} // NOSONAR
                        skipSecondaryTabs={isNew} selectedIndex={tabIndex}>
                        <div title='text.product' primary>
                            <div className='form_container'>
                                <div className='section_group'>
                                    <div className='sections '>
                                        <InputCont label='text.sales_reference' inputProps={{ validation, name: 'sku', maxLength: "8", value: dataItem.sku, onChange: onChangeData, disabled: !isNew }} />
                                        {
                                            !isNew && <InputCont
                                                label='text.master_code'
                                                inputProps={{ name: 'masterCode', maxLength: "20", value: dataItem.masterCode ? dataItem.masterCode + "/" + dataItem.masterBrand : "", disabled: !isNew, }}
                                            />
                                        }
                                        {
                                            isNew &&
                                            <InputCont
                                                label='text.master_code'
                                                inputProps={{
                                                    searchOptions: {
                                                        noAccent: true,
                                                        contains: true
                                                    },
                                                    type: 'selectCol',
                                                    name: 'masterId',
                                                    value: dataItem.masterId === 0 ? '' : dataItem.masterId,
                                                    valueField: '',
                                                    textField: '',
                                                    valuelist: 'product_name',
                                                    onChange: e => onChangeData({ target: { name: 'masterId', value: e.ID } }),
                                                    disabled: !isNew,
                                                    columns: [
                                                        {
                                                            text: 'text.sales_reference',
                                                            field: 'sku',
                                                            type: COLUMN_TYPE.TEXT
                                                        },
                                                        {
                                                            text: 'text.label',
                                                            field: 'label',
                                                            type: COLUMN_TYPE.TEXT
                                                        },
                                                        {
                                                            text: 'text.brand',
                                                            field: 'name',
                                                            type: COLUMN_TYPE.TEXT
                                                        }
                                                    ],
                                                    renderText: (record) => {
                                                        return record.sku + "/" + record.name + "/" + record.type;
                                                    },
                                                    showHeader: true,
                                                    skipNewButton: true,
                                                    noPaging: true
                                                }}
                                            />
                                        }
                                        <InputCont label="text.brand"
                                            inputProps={{
                                                validation,
                                                name: 'brandId',
                                                type: 'select',
                                                value: dataItem.brandId === 0 ? '' : dataItem.brandId,
                                                valuelist: 'brands',
                                                onChange: e => onChangeData({ target: { name: 'brandId', value: e } }),
                                                disabled: !isNew
                                            }} />
                                        <InputCont label='text.preview_url' inputProps={{ name: 'previewUrl', maxLength: "1500", value: dataItem.previewUrl, onChange: onChangeData }} />
                                    </div>
                                    <div className='sections '>
                                        <InputCont label='text.label' inputProps={{ validation, name: 'label', maxLength: "100", value: dataItem.label, onChange: onChangeData }} />
                                        <InputCont label='text.options' inputProps={{ validation, name: 'options', value: dataItem.options, onChange: onChangeData }} />
                                        <InputCont label='text.range' inputProps={{ validation, name: 'range', maxLength: "25", value: dataItem.range, onChange: onChangeData }} />
                                        <cont>
                                            <InputCont label='text.is_accessory' inputProps={{ type: 'checkbox', name: 'isAccessory', value: dataItem.isAccessory, onChange: onChangeData }} />
                                        </cont>
                                        <cont>
                                            <InputCont label='text.obsolete' inputProps={{ type: 'checkbox', name: 'obsolete', value: dataItem.obsolete, onChange: onChangeData }} />
                                        </cont>
                                    </div>
                                </div>
                                <div className='section_group'>
                                    {
                                        !isNew && <>
                                            <div className='sections'>

                                                {
                                                    ProductFields[type] && ProductFields[type].fields.map((item, index) => {// NOSONAR
                                                        if (item.type === 'dropdown') {
                                                            return Math.round((ProductFields[type].fields.length - 1) / 2) <= index &&
                                                                <InputCont label={`field.${item.field}`}
                                                                    key={item.field}
                                                                    inputProps={{
                                                                        name: item.field,
                                                                        type: 'select',
                                                                        value: dataItem[item.field],
                                                                        valuelist: `${item.field}`,
                                                                        onChange: e => onChangeData({ target: { name: `${item.field}`, value: e } }),
                                                                        disabled: dataItem.masterId ? true : false
                                                                    }} />
                                                        }

                                                        if (item.type === 'boolean') {
                                                            return Math.round((ProductFields[type].fields.length - 1) / 2) <= index &&
                                                                <cont key={item.field}>
                                                                    <InputCont
                                                                        label={`field.${item.field}`}
                                                                        inputProps={{
                                                                            type: 'checkbox',
                                                                            name: item.field,
                                                                            value: dataItem[item.field],
                                                                            onChange: onChangeData,
                                                                            disabled: dataItem.masterId ? true : false
                                                                        }}
                                                                    />
                                                                </cont>

                                                        }
                                                        return Math.round((ProductFields[type].fields.length - 1) / 2) <= index &&
                                                            <InputCont
                                                                key={item.field}
                                                                label={`field.${item.field}`}
                                                                inputProps={{
                                                                    validation,
                                                                    name: item.field,
                                                                    value: dataItem[item.field],
                                                                    type: item.type === 'number' ? 'number' : 'text',
                                                                    min: '1',
                                                                    max: item.max || Number.MAX_SAFE_INTEGER,
                                                                    maxLength: item.maxLength ? item.maxLength : null,
                                                                    precision: item.isDecimal && item.decimal,
                                                                    title: item.title,
                                                                    onChange: item.type === 'number' ? (e) => onChangeData({ target: { name: item.field, value: e } }) : onChangeData,
                                                                    disabled: dataItem.masterId ? true : false
                                                                }}
                                                            />
                                                    })
                                                }
                                            </div>
                                            <div className='sections'>
                                                {
                                                    ProductFields[type] && ProductFields[type].fields.map((item, index) => {// NOSONAR
                                                        if (item.field === 'options' || item.field === 'range') return null;

                                                        if (item.type === 'dropdown') {
                                                            return Math.round((ProductFields[type].fields.length - 1) / 2) > index &&
                                                                <InputCont label={`field.${item.field}`}
                                                                    key={item.field}
                                                                    inputProps={{
                                                                        name: item.field,
                                                                        type: 'select',
                                                                        value: dataItem[item.field],
                                                                        valuelist: `${item.field}`,
                                                                        onChange: e => onChangeData({ target: { name: `${item.field}`, value: e } }),
                                                                        disabled: dataItem.masterId ? true : false
                                                                    }} />
                                                        }
                                                        if (item.type === 'boolean') {
                                                            if (item.field === 'obsolete') {
                                                                return null;
                                                            }
                                                            return Math.round((ProductFields[type].fields.length - 1) / 2) > index &&
                                                                <cont key={item.field}>
                                                                    <InputCont
                                                                        label={`field.${item.field}`}
                                                                        inputProps={{
                                                                            type: 'checkbox',
                                                                            name: item.field,
                                                                            value: dataItem[item.field],
                                                                            onChange: onChangeData,
                                                                            disabled: dataItem.masterId ? true : false
                                                                        }}
                                                                    />
                                                                </cont>

                                                        }
                                                        return Math.round((ProductFields[type].fields.length - 1) / 2) > index &&
                                                            <InputCont
                                                                key={item.field}
                                                                label={`field.${item.field}`}
                                                                inputProps={{
                                                                    validation,
                                                                    name: item.field,
                                                                    value: dataItem[item.field],
                                                                    type: item.type === 'number' ? 'number' : 'text',
                                                                    min: '1', // if we have number value
                                                                    max: item.max || Number.MAX_SAFE_INTEGER,
                                                                    maxLength: item.maxLength ? item.maxLength : null,
                                                                    precision: item.isDecimal && item.decimal,
                                                                    title: item.title,
                                                                    onChange: item.type === 'number' ? (e) => onChangeData({ target: { name: item.field, value: e } }) : onChangeData,
                                                                    disabled: dataItem.masterId ? true : false
                                                                }} />
                                                    })
                                                }
                                            </div>
                                        </>
                                    }
                                </div>

                            </div>
                        </div>
                    </TabStrip>
                    {tabIndex !== 2 && <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className={hasChanges ? ' button primary' : 'button'}><icon>save</icon>{i18n('text.save')}</div>
                                {dataItem.ID > 0 &&
                                    <div className='button' onClick={(e) => {
                                        onDetailDelete(dataItem, () => { navigate(Project.getPageUrl(AppPages.Product, type)) })
                                    }}>
                                        <icon>delete</icon>{i18n('text.delete')}
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>}
                </form>
            </view>
        </DetailView>}
    </>);
}
