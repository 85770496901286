import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../Shared/Translation/i18n';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { Project } from '~/project/Project';
import { EVENT_NAME } from '../../project/utilities';
import DetailView from '../../Shared/DetailView';
import useDetail from '../../Shared/useDetail';
import InputCont from '../../Shared/Form/InputCont';
import { Ajax } from '../../components/Ajax';
import { message } from 'antd';
import { hideLoader, showLoader } from '../../Shared/Loader';

const listUpdateEvent = EVENT_NAME.FAQ_LIST_UPDATE;
const loaderName = 'UPDATE_LOADER';
/**
 * ...
 * @exports FaqDetails
 */

export default function FaqDetails(props) {
    const { windowKey, data } = props;
    const [salesNetworkLanguage, setSalesNetworkLanguage] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [faq, setFaq] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);
    const { id } = useParams();

    const { isNew, dataItem, updateData, /*hasChanges, setDataItem, closePopup, onChangeData, detailRef,*/ onDetailDelete, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : 0 },
        windowKey,
        updateUrl: ApiUrl.FaqUpdate,
        dataLoadUrl: ApiUrl.FaqLoad,
        deleteUrl: ApiUrl.FaqDelete,
        nameField: 'order',
        backPage: AppPages.Faq,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.FaqDetails),
        changingValidation: true,
        onRecordDeleted: () => {
            setHasChanges(false);
        }
    });

    useEffect(() => {
        if (dataItem) {
            Ajax.get({
                url: ApiUrl.SalesNetworkLanguagesLoad,
                data: { networkID: dataItem.salesnetworkId },
                success: function (response) {
                    if (response && !response.HasError) {
                        setSalesNetworkLanguage(response.data);
                    } else {
                        message.error();
                    }
                },
            })
        }
    }, [dataItem && dataItem.salesnetworkId]);// eslint-disable-line react-hooks/exhaustive-deps

    const loadData = useCallback((dataItem_ID) => {// NOSONAR
        Ajax.post({
            url: ApiUrl.LoadFaq,
            data: { id: dataItem_ID },
            success: function (response) {
                hideLoader(loaderName);

                if (response) {
                    const _response = [...response];

                    if (salesNetworkLanguage) {
                        for (const element of salesNetworkLanguage) {
                            if (element.isSelected && !response.some(t => t.language.trim() === element.Code.trim())) {
                                _response.push({
                                    HasError: false,
                                    ID: dataItem.ID ? dataItem.ID : -1,
                                    Message: null,
                                    answer: "",
                                    language: element.Code.trim(),
                                    languageId: element.ID,
                                    order: dataItem.order,
                                    question: "",
                                    salesnetworkId: dataItem.salesnetworkId,
                                    salesNetwork: ""
                                });
                            }
                        }
                    }
                    setFaq(_response);
                    setHasChanges(false);
                } else {
                    message.error();
                }
            },
        });
    }, [setFaq, setHasChanges, salesNetworkLanguage, dataItem]);

    useEffect(() => {
        if (dataItem && !isDisabled && salesNetworkLanguage) {
            loadData(dataItem.ID);
        }
    }, [salesNetworkLanguage]);// eslint-disable-line react-hooks/exhaustive-deps

    const onChangeFaq = useCallback((e, language) => {
        const newFaq = [...faq];
        const index = newFaq.findIndex(f => f.language.trim() === language.trim());

        if (index >= 0) {
            setHasChanges(hasChanges || newFaq[index][e.target.name] !== e.target.value);
            newFaq[index][e.target.name] = e.target.value;
        }

        setFaq(newFaq);
    }, [faq, hasChanges]);

    const updateFaqItems = useCallback((_response) => {
        showLoader(loaderName);

        for (const element of faq) {
            element.ID = _response.ID;
            element.salesnetworkId = _response.salesnetworkId;
        }

        Ajax.post({
            url: ApiUrl.UpdateFaqItem,
            data: { data: faq },
            success: function (response) {

                setIsDisabled(false);
                loadData(_response.ID);
            },
            error: function () {
                hideLoader(loaderName);

                setIsDisabled(false);
            }
        })
    }, [loadData, faq]);

    const isEmptyItems = useCallback(() => {
        let hasQuestion = false;
        let hasAnswer = false;

        for (const element of faq) {
            if (element.question !== "") {
                hasQuestion = true;
            } else if (element.answer !== "") {
                hasAnswer = true;
            }
        }

        return !hasQuestion && !hasAnswer;
    }, [faq]);

    const validate = useCallback(() => {
        let questionEmpty = false;
        let answerEmpty = false;

        for (const element of faq) {
            if (element.question !== "" && element.answer === "") {
                answerEmpty = true;
            } else if (element.question === "" && element.answer !== "") {
                questionEmpty = true;
            }
        }

        if (answerEmpty && questionEmpty) {
            message.error("Please check answer and question.");
        } else if (answerEmpty) {
            message.error("Please check answer.");
        } else if (questionEmpty) {
            message.error("Please check question.");
        } else if (isNew && isEmptyItems()) {
            message.error("Please check answer and question.");
            return false;
        }

        return !answerEmpty && !questionEmpty;
    }, [faq, isNew, isEmptyItems]);

    const update = useCallback(() => {
        if (validate()) {
            setIsDisabled(true);
            setHasChanges(false);

            let canDelete = isEmptyItems();

            if (canDelete) {
                onDetailDelete(dataItem);
            } else {
                updateData(updateFaqItems);
            }
        }
    }, [updateData, updateFaqItems, onDetailDelete, dataItem, isEmptyItems, validate]);

    return (<>
        {dataItem && <DetailView
            hasChanges={hasChanges}
            backUrl={Project.getPageUrl(AppPages.Faq)}
            title={isNew ? i18n('text.faq') : dataItem.order}
        >
            <view >
                {/* <form> */}
                <div className="form_container">
                    <div className="section_group">
                        <div className="sections">
                            <div className="form_fields">
                                <div className="section_title"><text>question</text></div>
                                <div className="form_separator"></div>
                            </div>
                            {faq && faq.map((f, i) => {
                                return <InputCont label={f.language} key={i} // NOSONAR
                                    inputProps={{ validation, name: "question", value: f.question, onChange: e => onChangeFaq(e, f.language) }} />
                            })}
                        </div>

                        <div className="sections">
                            <div className="form_fields">
                                <div className="section_title"><text>answer</text></div>
                                <div className="form_separator"></div>
                            </div>
                            {faq && faq.map((f, i) => {
                                return <InputCont label={f.language} key={i} // NOSONAR
                                    inputProps={{ validation, name: "answer", value: f.answer, onChange: e => onChangeFaq(e, f.language) }} />
                            })}
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div
                                onClick={update}
                                disabled={isDisabled}
                                className={hasChanges ? ' button primary' : 'button'}><icon>save</icon>{i18n('text.save')}
                            </div>
                            {dataItem.ID > 0 &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                    <icon>delete</icon>{i18n('text.delete')}
                                </div>}
                        </action>
                    </wrap>
                </toolbar>
                {/* </form> */}
            </view>
        </DetailView>}
    </>);
}

