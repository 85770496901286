import { showLoader, hideLoader } from '../../../Shared/Loader';
import { Ajax } from '../../../components/Ajax';
import { ApiUrl } from '../../../project/Defines';
import { message } from 'antd';
import { dispatchCustomEvent } from '../../../project/utilities';
import FileSaver from 'file-saver';

const loaderName = 'PRODUCT_FILE_UPLOAD_LOADER';
const exportLoaderName = 'EXPORT_LOADER';

export function ProductImport(data, listUpdateEvent, successCallback) {
    showLoader(loaderName);

    Ajax.post({
        url: ApiUrl.ProductSalesNetworkImport,
        data: {
            file: data && data.file
        },
        success: (response) => {
            if (!response.HasError) {
                successCallback && successCallback(response);
                dispatchCustomEvent(listUpdateEvent);
                hideLoader(loaderName);
            } else {
                message.error(response.message);
                hideLoader(loaderName);
            }
        }
    });
}

export function ProductExport() {
    showLoader(exportLoaderName);

    Ajax.download({
        url: ApiUrl.ProductSalesNetworkExport,
        data: {},
        //autoDownload: false,
        success: (blob) => {
            FileSaver.saveAs(blob, "ProductSalesNetwork.xlsx");
            hideLoader(exportLoaderName);
        },
        error: () => {
            hideLoader(exportLoaderName);
        }
    });
}